import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Field, Form, Formik } from 'formik'
import { AlertCircle } from 'react-feather'
import * as yup from 'yup'
import $ from 'jquery'

export function AddMultipleProductsModal (modalParams) {
  const initialValues = { productIds: [''] }

  useEffect(() => {
    setFormValues(initialValues)
  }, [modalParams.data])

  const [formValues, setFormValues] = useState(initialValues)
  const handleOnChangeProductId = (event) => {
    const m = event.target.value.match('.*/go/([a-f0-9]{24})?')
    const formValuesCopy = JSON.parse(JSON.stringify(formValues))
    const mi = event.target.getAttribute('name').match('^productIds\\[([0-9]+)\\]$')
    const i = mi[1]
    if (m !== null) {
      formValuesCopy.productIds[i] = m[1]
      if (formValuesCopy.productIds[formValuesCopy.productIds.length - 1].trim() !== '') {
        formValuesCopy.productIds.push('')
      }
      setFormValues(formValuesCopy)
    } else {
      formValuesCopy.productIds[i] = event.target.value
      if (formValuesCopy.productIds[formValuesCopy.productIds.length - 1].trim() !== '') {
        formValuesCopy.productIds.push('')
      }
      setFormValues(formValuesCopy)
    }
  }

  const onSaveModal = modalParams.onSaveModal
  const handleSave = (params) => {
    $('#addMultipleProductsModal').modal('hide')
    onSaveModal(params)
  }

  const validateFormSchema = yup.object().shape({})

  return (
    <>
      <div
        className="modal fade" id="addMultipleProductsModal" tabIndex="-1" role="dialog"
        aria-labelledby="removeConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={validateFormSchema}
                onSubmit={handleSave}>
                {({ status, values }) => (<Form>
                  {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
                    <AlertCircle className="col-sm mg-r-10"/> {status}</div>}
                  <div className="row row-xs">
                    <div className="col-lg-12">
                      <div className="row row-xs">
                        <div className="col-12">
                          {values.productIds.map((productId, index) => (
                            <Field
                              key={index} name={`productIds[${index}]`} type="text" className="form-control mt-2"
                              placeholder="ID товара"
                              onKeyUp={handleOnChangeProductId}/>
                          ))}
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-brand-02 btn-block mg-t-20">
                      Сохранить
                    </button>
                  </div>
                </Form>)}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AddMultipleProductsModal.propTypes = {
  data: PropTypes.any
}
