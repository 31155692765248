import axios from 'axios'

export function getOauthToken (oauthUrl, clientId, clientSecret, redirectUri, code) {
  return new Promise(resolve => {
    const data = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: clientId,
      client_secret: clientSecret,
      code: code,
      redirect_uri: redirectUri
    }).toString()
    axios.post(oauthUrl + '/oauth/token', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(function (response) {
        resolve(response)
      })
  })
}
