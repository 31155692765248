import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { signout } from '../../services/auth.service'

const Logout = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      signout(() => {
        navigate('/login')
      })
    }, 100)
  }, [navigate])

  return ('')
}

export default Logout
