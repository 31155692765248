import React, { useState } from 'react'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import { Helmet } from 'react-helmet-async'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import slugify from 'slugify'
import decode from 'unescape'

export default function UtilAliasGenerator () {
  const [data, setData] = useState({ text: '', alias: '' })

  const handleTextChange = (event) => {
    const dataClone = JSON.parse(JSON.stringify(data))

    const text = event.target.value
    let alias = slugify(text.toLowerCase())
    alias = decode(alias)
    alias = alias.replace(/[^a-z0-9-]+/, '-')
    alias = alias.replace(/-{2,}/, '-')
    alias = alias.replace(/^[-]+/, '')
    alias = alias.replace(/[-]+$/, '')

    dataClone.text = event.target.value
    dataClone.alias = alias
    setData(dataClone)
  }

  const handleSubmit = (event) => {
  }

  return (<>
    <Helmet>
      <title>{'Генератор алиасов | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/utils': 'Утилиты' }, { '/utils/alias-generator': 'Генератор алиасов' }]}>
      <>
        <h1 className="df-title">{'Генератор алиасов'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={data}
          onSubmit={handleSubmit}
        >
          {() => (<Form>
            <div className="row row-xs">
              <div className="col-lg-12">
                <div className="row row-xs">
                  <div className="col-12">
                    <fieldset className="form-fieldset">
                      <div className="form-group">
                        <label className="d-block" htmlFor="text">Текст</label>
                        <Field
                          name="text" type="text" className="form-control"
                          placeholder="Текст" onChange={handleTextChange}/>
                        <ErrorMessage name="text" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="alias">Алиас</label>
                        <Field name="alias" type="text" className="form-control" placeholder="Алиас"/>
                        <ErrorMessage name="alias" component={FormFieldError}/>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
