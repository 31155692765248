import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import { styleImage } from '../ClothifyApi'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function ImportFeedSettings () {
  const navigate = useNavigate()
  const { feedId } = useParams()
  const [feed, setFeed] = useState([])
  const [imageStyles, setImageStyles] = useState([])
  const initialMessage = { type: 'success', text: '' }
  const [message, setMessage] = useState(initialMessage)

  const imgHost = 'https://cdn1.justbutik.ru/'
  const logoSize = { width: 128, height: 128 }
  const aspect = 1
  const scale = 1
  const rotate = 0
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(undefined)
  const [imgSrc, setImgSrc] = useState('')
  const [file, setFile] = useState(undefined)
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const formRef = useRef()
  const cashbackLogoSize = { width: 258, height: 80 }
  const cashbackAspect = cashbackLogoSize.width / cashbackLogoSize.height
  const cashbackScale = 1
  const cashbackRotate = 0
  const cashbackImgRef = useRef(null)
  const [cashbackCrop, setCashbackCrop] = useState(undefined)
  const [cashbackImgSrc, setCashbackImgSrc] = useState('')
  const [cashbackFile, setCashbackFile] = useState(undefined)
  const [cashbackUploadDisabled, setCashbackUploadDisabled] = useState(true)

  useEffect(() => {
    if (!token) {
      return
    }
    if (feedId !== undefined && feedId !== 'create') {
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/feeds/' + feedId)
        .then(response => {
          setFeed(response.data)
        })
        .catch(error => {
          console.log(error.response)
        })
    }
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/images/styles')
      .then(response => {
        setImageStyles(response.data)
      })
      .catch(error => {
        console.log(error.response)
      })
  }, [feedId])

  useEffect(() => {
    setUploadDisabled(crop === undefined)
  }, [crop])

  useEffect(() => {
    setCashbackUploadDisabled(cashbackCrop === undefined)
  }, [cashbackCrop])

  const validateFormSchema = yup.object().shape({
    name: yup.string()
      .min(2, 'Слишком короткое.')
      .max(128, 'Слишком длинное.')
      .required('Это поле обязательно к заполнению.'),
    alias: yup.string()
      .min(2, 'Слишком короткое.')
      .max(64, 'Слишком длинное.')
      .required('Это поле обязательно к заполнению.'),
    url: yup.string()
      .required('Это поле обязательно к заполнению.'),
    meta: yup.object().shape({
      offers_difference_down_limit: yup.number()
        .required('Это поле обязательно к заполнению.')
        .min(0, 'Не может быть меньше нуля.')
        .max(100, 'Не может быть больше 100.'),
      offers_difference_up_limit: yup.number()
        .required('Это поле обязательно к заполнению.')
        .min(0, 'Не может быть меньше нуля.')
        .max(100, 'Не может быть больше 100.'),
      categories_difference_down_limit: yup.number()
        .required('Это поле обязательно к заполнению.')
        .min(0, 'Не может быть меньше нуля.')
        .max(100, 'Не может быть больше 100.'),
      categories_difference_up_limit: yup.number()
        .required('Это поле обязательно к заполнению.')
        .min(0, 'Не может быть меньше нуля.')
        .max(100, 'Не может быть больше 100.'),
      unique_key_field: yup.string()
        .required('Это поле обязательно к заполнению.'),
      weight: yup.number()
        .required('Это поле обязательно к заполнению.')
        .integer('Должно быть целым числом.')
    })
  })

  const initialFormValues = {
    url: feed.url || '',
    name: feed.name || '',
    alias: feed.alias || '',
    enabled: feed.enabled || false,
    meta: {
      offers_difference_down_limit: (feed.meta && feed.meta.offers_difference_down_limit ? feed.meta.offers_difference_down_limit : 25),
      offers_difference_up_limit: (feed.meta && feed.meta.offers_difference_up_limit ? feed.meta.offers_difference_up_limit : 25),
      categories_difference_down_limit: (feed.meta && feed.meta.categories_difference_down_limit ? feed.meta.categories_difference_down_limit : 25),
      categories_difference_up_limit: (feed.meta && feed.meta.categories_difference_up_limit ? feed.meta.categories_difference_up_limit : 25),
      unique_key_field: (feed.meta && feed.meta.unique_key_field ? feed.meta.unique_key_field : ''),
      catalog_image_style: (feed.meta && feed.meta.catalog_image_style ? feed.meta.catalog_image_style : ''),
      categories_add_search: (feed.meta && feed.meta.categories_add_search ? feed.meta.categories_add_search : false),
      calc_weight: (feed.meta && feed.meta.calc_weight ? feed.meta.calc_weight : false),
      skip_validation: (feed.meta && feed.meta.skip_validation ? feed.meta.skip_validation : false),
      goods_exclude: (feed.meta && feed.meta.goods_exclude ? feed.meta.goods_exclude : false),
      xml_import_corrector_add_category: (feed.meta && feed.meta.xml_import_corrector_add_category ? feed.meta.xml_import_corrector_add_category : false),
      weight: (feed.meta && feed.meta.weight ? feed.meta.weight : 0)
    },
    straight_import: feed.straight_import || false,
    logo: feed.logo || '',
    show_cashback: typeof feed.show_cashback === 'undefined' ? true : feed.show_cashback,
    cashback_levels: feed.cashback_levels || '',
    cashback_rules: feed.cashback_rules || '',
    cashback_logo: feed.cashback_logo || '',
    cashback_redirect_url: feed.cashback_redirect_url || '',
    advcampaign_id: feed.advcampaign_id || ''
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    setMessage(initialMessage)
    setSubmitting(true)
    setTimeout(() => {
      if (feedId === undefined) {
        console.log('Feed id is undefined')
      } else if (feedId === 'create') {
        axios.create({
          headers: { Authorization: 'Bearer ' + token }
        }).post('/feeds', Object.assign({}, feed, values))
          .then(response => {
            navigate(`/import/feeds/${response.data._id}`)
          })
          .catch(error => {
            const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
            setMessage({ type: 'alert', text: errMsg })
            setSubmitting(false)
          })
      } else {
        axios.create({
          headers: { Authorization: 'Bearer ' + token }
        }).put(`/feeds/${feedId}`, Object.assign({}, feed, values))
          .then(response => {
            setMessage({ type: 'success', text: 'Фид успешно сохранен' })
            window.scrollTo(0, 0)
            setFeed(response.data)
            setSubmitting(false)
          })
          .catch(error => {
            const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
            setMessage({ type: 'alert', text: errMsg })
            setSubmitting(false)
          })
      }
    }, 500)
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(makeAspectCrop(
        {
          unit: '%',
          width: 100
        },
        width / height,
        width,
        height
      ))
    }
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setFile(e.target.files[0])
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadImages = () => {
    setUploadDisabled(true)
    const parts = imgSrc.split(';base64,')
    const contentType = parts.shift().split('data:').pop().toLowerCase()
    let ext = '.jpg'
    switch (contentType) {
      case 'image/jpeg':
      case 'image/jpg':
        ext = '.jpg'
        break
      case 'image/png':
        ext = '.png'
        break
      case 'image/gif':
        ext = '.gif'
        break
      case 'image/webp':
        ext = '.webp'
        break
    }

    const baseFileName = Date.now().toString()
    const fileName = baseFileName + ext
    const sourceData = {
      path: 'feeds/source',
      baseFileName: fileName,
      file: file
    }
    const logoData = {
      path: 'feeds/logo',
      baseFileName: baseFileName,
      actions: [
        {
          type: 'crop',
          params: crop
        },
        {
          type: 'scale',
          params: logoSize
        }
      ],
      file: file
    }
    Promise.all([
      styleImage(sourceData),
      styleImage(logoData)
    ])
      .then(results => {
        const feedData = {}
        Object.assign(feedData, feed, formRef.current.values)
        feedData.logo = results[1].data[0]
        setFeed(feedData)
        setMessage({ type: 'success', text: 'Изображения успешно загружены' })
        window.scrollTo(0, 0)
      })
      .catch(err => {
        console.log(err)
        setMessage({ type: 'alert', text: 'При стилизации изображений произошла ошибка' })
        window.scrollTo(0, 0)
      })
  }

  const onCashbackImageLoad = (e) => {
    if (cashbackAspect) {
      const { width, height } = e.currentTarget
      setCashbackCrop(centerCrop(makeAspectCrop(
        {
          unit: '%',
          width: 100
        },
        cashbackAspect,
        width,
        height
      ), width, height))
    }
  }

  const onCashbackSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCashbackCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setCashbackFile(e.target.files[0])
        setCashbackImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadCashbackImages = () => {
    setCashbackUploadDisabled(true)
    const parts = cashbackImgSrc.split(';base64,')
    const contentType = parts.shift().split('data:').pop().toLowerCase()
    let ext = '.jpg'
    switch (contentType) {
      case 'image/jpeg':
      case 'image/jpg':
        ext = '.jpg'
        break
      case 'image/png':
        ext = '.png'
        break
      case 'image/gif':
        ext = '.gif'
        break
      case 'image/webp':
        ext = '.webp'
        break
    }

    const baseFileName = Date.now().toString()
    const fileName = baseFileName + ext
    const sourceData = {
      path: 'feeds/source',
      baseFileName: fileName,
      file: cashbackFile
    }
    const logoData = {
      path: 'feeds/cashback_logo',
      baseFileName: baseFileName,
      actions: [
        {
          type: 'crop',
          params: cashbackCrop
        },
        {
          type: 'scale',
          params: cashbackLogoSize
        }
      ],
      file: cashbackFile
    }
    Promise.all([
      styleImage(sourceData),
      styleImage(logoData)
    ])
      .then(results => {
        const feedData = {}
        Object.assign(feedData, feed, formRef.current.values)
        feedData.cashback_logo = results[1].data[0]
        setFeed(feedData)
        setMessage({ type: 'success', text: 'Изображения успешно загружены' })
        window.scrollTo(0, 0)
      })
      .catch(err => {
        console.log(err)
        setMessage({ type: 'alert', text: 'При стилизации изображений произошла ошибка' })
        window.scrollTo(0, 0)
      })
  }

  return (<>
    <Helmet>
      <title>{'Управление фидом "' + feed.name + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/import': 'Импорт' }, { '/import/feeds': 'Источники данных' }, { ['/import/feeds/' + feed._id]: feed.name }]}>
      <>
        <h1 className="df-title">{'Управление источником "' + feed.name + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting }) => (<Form>
            {message.text.length > 0 && <div
              className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
              <AlertCircle className="mg-r-10 "/> {message.text}</div>}
            <div className="row row-xs">
              <div className="col-lg-8">
                <div className="row row-xs">
                  <div className="col-12">
                    <fieldset className="form-fieldset">
                      <legend>Настройки фида</legend>
                      <div className="custom-control custom-switch mg-b-20">
                        <Field
                          name="enabled" type="checkbox"
                          className="custom-control-input" id="enabled"/>
                        <label
                          className="d-block custom-control-label"
                          htmlFor="enabled">
                          Импорт фида включен
                        </label>
                        <ErrorMessage name="enabled" component={FormFieldError}/>
                      </div>
                      <div className="custom-control custom-switch mg-b-20">
                        <Field
                          name="meta[skip_validation]" type="checkbox"
                          className="custom-control-input" id="meta[skip_validation]"/>
                        <label
                          className="d-block custom-control-label"
                          htmlFor="meta[skip_validation]">
                          Пропустить валидацию
                        </label>
                        <ErrorMessage name="meta[skip_validation]" component={FormFieldError}/>
                      </div>
                      <div className="custom-control custom-switch mg-b-20">
                        <Field
                          name="straight_import" type="checkbox"
                          className="custom-control-input" id="straight_import"/>
                        <label
                          className="d-block custom-control-label"
                          htmlFor="straight_import">
                          Импортировать товары без корректировщика
                        </label>
                        <ErrorMessage name="straight_import" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="url">
                          URL
                        </label>
                        <Field
                          name="url" component="textarea" className="form-control" disabled={feedId !== 'create'}
                          placeholder="Укажите ссылки для скачивания файла фида. По одной ссылке в строку."/>
                        <ErrorMessage name="url" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="name">
                          Наименование фида (магазина)
                        </label>
                        <Field
                          name="name" type="text" className="form-control" disabled={feedId !== 'create'}
                          placeholder="Укажите имя фида"/>
                        <ErrorMessage name="name" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="alias">
                          Алиас
                        </label>
                        <Field
                          name="alias" type="text" className="form-control" disabled={feedId !== 'create'}
                          placeholder="Укажите псевдоним для фида"/>
                        <ErrorMessage name="alias" component={FormFieldError}/>
                      </div>
                      <div className="form-group align-top">
                        <label className="d-block" htmlFor="meta[unique_key_field]">
                          Поле для расчета UKEY
                        </label>
                        <Field
                          className="custom-select" name="meta[unique_key_field]" component="select" type="text"
                          disabled={feedId !== 'create'}>
                          <option> Выберите подходящее поле для ключа уникальности</option>
                          <option value="id">ID</option>
                          <option value="url">URL</option>
                          <option value="picture">PICTURE</option>
                        </Field>
                        <ErrorMessage name="meta[unique_key_field]" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch mg-b-20">
                          <Field
                            name="meta[xml_import_corrector_add_category]" type="checkbox"
                            className="custom-control-input" id="meta[xml_import_corrector_add_category]"/>
                          <label
                            className="d-block custom-control-label"
                            htmlFor="meta[xml_import_corrector_add_category]">
                            Добавлять категорию к заголовку при корректировке категорий
                          </label>
                          <ErrorMessage name="meta[xml_import_corrector_add_category]" component={FormFieldError}/>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="row row-xs mg-t-20">
                  <div className="col-12 form-group">
                    <fieldset className="form-fieldset">
                      <legend>Дополнительные настройки</legend>
                      <div className="form-group">
                        <div className="custom-control custom-switch mg-b-20">
                          <Field
                            name="meta[calc_weight]" type="checkbox"
                            className="custom-control-input" id="meta[calc_weight]"/>
                          <label
                            className="d-block custom-control-label"
                            htmlFor="meta[calc_weight]">
                            Учитывать магазин в распределении веса
                          </label>
                          <ErrorMessage name="meta[calc_weight]" component={FormFieldError}/>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="meta[weight]">
                          Вес магазина
                        </label>
                        <Field
                          name="meta[weight]" type="number" className="form-control mb-3"
                          placeholder="Укажите значение веса"/>
                        <ErrorMessage name="meta[weight]" component={FormFieldError}/>
                        <Field
                          name="meta[categories_add_search]" type="checkbox" className="mr-1"/>
                        <label htmlFor="meta[categories_add_search]" className="align-top">
                          <span>Добавлять категорию из оффера в поисковый индекс</span>
                          <p className="font-weight-light">
                            При включенной опции навание категории из файла импорта будет добавляться в поле для
                            полнотекстового поиска
                          </p>
                        </label>
                        <Field
                          name="meta[goods_exclude]" type="checkbox" className="mr-1"/>
                        <label htmlFor="meta[goods_exclude]">
                          <span>Не показывать товары в образах и подборках </span>
                        </label>
                        <label className="d-block" htmlFor="meta[catalog_image_style]">
                          Стиль изображений для каталога
                        </label>
                        <Field
                          className="custom-select" name="meta[catalog_image_style]" component="select" type="text">
                          <option value="">По умолчанию</option>
                          {imageStyles.map((imageStyle, index) => {
                            return (
                              <option
                                key={imageStyle._id} value={imageStyle._id}>{imageStyle.name}
                              </option>
                            )
                          })}
                        </Field>
                        <ErrorMessage name="meta[catalog_image_style]" component={FormFieldError}/>
                      </div>
                      <div className="row">
                        <div className="col-3 p-2">
                          <img
                            alt="Лого фида" src={imgHost + feed.logo}
                            style={{ maxHeight: 128 }}/>
                        </div>
                        <div className="form-group col-9">
                          <label className="d-block" htmlFor="logo">
                            Лого фида
                          </label>
                          <Field
                            name="logo" type="text" className="form-control"
                            placeholder="Лого фида" disabled={true}/>
                          <ErrorMessage name="logo" component={FormFieldError}/>
                        </div>
                      </div>
                      <div className="form-group row pt-3 pb-3">
                        <div className="col-6">
                          {!!imgSrc && (
                            <ReactCrop
                              crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)}>
                              <img
                                ref={imgRef} alt="Оригинальное изображение" src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad}/>
                            </ReactCrop>
                          )}
                        </div>
                        <div className="col-6">
                          <input type="file" accept="image/*" onChange={onSelectFile}/>
                          <button
                            type="button" className="btn btn-primary" onClick={uploadImages}
                            disabled={uploadDisabled}>Загрузить
                          </button>
                        </div>
                      </div>
                      <div className="custom-control custom-switch mg-b-20">
                        <Field
                          name="show_cashback" type="checkbox"
                          className="custom-control-input" id="show_cashback"/>
                        <label
                          className="d-block custom-control-label"
                          htmlFor="show_cashback">
                          Отображать магазин на странице Кэшбэк
                        </label>
                        <ErrorMessage name="show_cashback" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="cashback_levels">
                          Уровни кэшбэка
                        </label>
                        <Field
                          name="cashback_levels" component="textarea" className="form-control"
                          placeholder="Уровни кэшбэка. По одному в строку. Пример: '2.5: Для новых пользователей'"/>
                        <ErrorMessage name="cashback_levels" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="cashback_rules">
                          Условия получения бонусов
                        </label>
                        <Field
                          name="cashback_rules" component="textarea" className="form-control"
                          placeholder="Условия получения бонусов. По одному в строку."/>
                        <ErrorMessage name="cashback_rules" component={FormFieldError}/>
                      </div>
                      <div className="row">
                        <div className="col-3 p-2">
                          <img
                            alt="Лого фида для страницы кэшбэка" src={imgHost + feed.cashback_logo}
                            style={{ maxWidth: 180 }}/>
                        </div>
                        <div className="form-group col-9">
                          <label className="d-block" htmlFor="cashback_logo">
                            Лого фида для страницы кэшбэка
                          </label>
                          <Field
                            name="cashback_logo" type="text" className="form-control"
                            placeholder="Лого фида для страницы кэшбэка" disabled={true}/>
                          <ErrorMessage name="cashback_logo" component={FormFieldError}/>
                        </div>
                      </div>
                      <div className="form-group row pt-3 pb-3">
                        <div className="col-6">
                          {!!cashbackImgSrc && (
                            <ReactCrop
                              crop={cashbackCrop} onChange={(_, percentCrop) => setCashbackCrop(percentCrop)}>
                              <img
                                ref={cashbackImgRef} alt="Оригинальное изображение" src={cashbackImgSrc}
                                style={{ transform: `scale(${cashbackScale}) rotate(${cashbackRotate}deg)` }}
                                onLoad={onCashbackImageLoad}/>
                            </ReactCrop>
                          )}
                        </div>
                        <div className="col-6">
                          <input type="file" accept="image/*" onChange={onCashbackSelectFile}/>
                          <button
                            type="button" className="btn btn-primary" onClick={uploadCashbackImages}
                            disabled={cashbackUploadDisabled}>Загрузить
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="cashback_redirect_url">
                          URL редиректа при активации кэшбэка
                        </label>
                        <Field
                          name="cashback_redirect_url" type="text" className="form-control"
                          placeholder="URL редиректа при активации кэшбэка"/>
                        <ErrorMessage name="cashback_redirect_url" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="advcampaign_id">
                          Admitad advcampaign ID
                        </label>
                        <Field
                          name="advcampaign_id" type="text" className="form-control"
                          placeholder="Admitad advcampaign ID "/>
                        <ErrorMessage name="advcampaign_id" component={FormFieldError}/>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row row-xs">
                  <div className="col-12">
                    <fieldset className="form-fieldset">
                      <legend>Валидация файла</legend>
                      <div className="form-group">
                        <label
                          className="d-block"
                          htmlFor="meta[offers_difference_down_limit]">
                          Максимальный процент изменений количества предложений в меньшую сторону
                        </label>
                        <Field
                          name="meta[offers_difference_down_limit]" type="number"
                          className="form-control" placeholder="Укажите значение в %"/>
                        <ErrorMessage name="meta[offers_difference_down_limit]" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label
                          className="d-block"
                          htmlFor="meta[offers_difference_up_limit]">
                          Максимальный процент изменений количества предложений в большую сторону
                        </label>
                        <Field
                          name="meta[offers_difference_up_limit]" type="number"
                          className="form-control"
                          placeholder="Укажите значение в %"/>
                        <ErrorMessage name="meta[offers_difference_up_limit]" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label
                          className="d-block"
                          htmlFor="meta[categories_difference_down_limit]">
                          Максимальный процент изменений количества категорий в меньшую сторону
                        </label>
                        <Field
                          name="meta[categories_difference_down_limit]" type="number" className="form-control"
                          placeholder="Укажите значение в %"/>
                        <ErrorMessage
                          name="meta[categories_difference_down_limit]" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label
                          className="d-block"
                          htmlFor="meta[categories_difference_up_limit]">
                          Максимальный процент изменений количества категорий в большую сторону
                        </label>
                        <Field
                          name="meta[categories_difference_up_limit]" type="number"
                          className="form-control"
                          placeholder="Укажите значение в %"/>
                        <ErrorMessage
                          name="meta[categories_difference_up_limit]" component={FormFieldError}/>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
