import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import Select from 'react-select'

export default function ReactSelect ({ changeHandler, ...params }) {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(params)

  const { setValue } = helpers

  const onChange = (values) => {
    if (Array.isArray(values)) {
      if (values.length === 0) {
        setValue([])
      } else {
        const vals = []
        values.forEach(value => {
          vals.push(value.value)
        })
        setValue(vals)
      }
    } else {
      if (values === null) {
        setValue('')
      } else {
        setValue(values.value)
      }
    }
    if (typeof changeHandler === 'function') {
      changeHandler(values)
    }
  }
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      {...params}
      onChange={onChange}
      value={params.options.filter(option => Array.isArray(field.value) ? field.value.includes(option.value) : option.value === field.value)}
    />
  )
}

ReactSelect.propTypes = {
  changeHandler: PropTypes.func
}
