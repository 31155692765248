import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet-async'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function ImageStyles () {
  const navigate = useNavigate()
  const [imageStyles, setImageStyles] = useState([])

  useEffect(() => {
    if (!token) {
      return
    }
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/images/styles')
      .then(response => {
        setImageStyles(response.data)
      })
      .catch(error => {
        console.log(error.response)
      })
  }, [])

  function handleRowClick (data) {
    navigate('/multimedia/image-styles/' + String(data._id))
  }

  function handleCreateImageStyle (data) {
    navigate('/multimedia/image-styles/create')
  }

  function handleEditImageStyle (data) {
    navigate('/multimedia/image-styles/' + data.target.id)
  }

  return (
    <>
      <Helmet>
        <title>Управление стилями изображений | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/multimedia': 'Мультимедиа' }, { '/multimedia/image-styles': 'Стили изображений' }]}>
        <>
          <h1 className="df-title">Управление стилями изображений</h1>
          <button type="button" className="btn btn-primary" onClick={handleCreateImageStyle}>
            Создать стиль
          </button>
          <DataTable
            keyField="_id"
            defaultSortField="name"
            defaultSortAsc={true}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            columns={[
              {
                name: 'Название',
                selector: (row, i) => row.name,
                sortable: true
              },
              {
                name: 'Псевдоним',
                selector: (row, i) => row.machineName,
                sortable: true
              },
              {
                name: '',
                cell: function getCell (row) {
                  return <button className="btn btn-link" onClick={handleEditImageStyle} id={row._id}>Редактировать</button>
                },
                right: true
              }
            ]}
            data={imageStyles}
            onRowClicked={handleRowClick}
          />
        </>
      </SimpleLayout>
    </>
  )
}
