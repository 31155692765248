import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet-async'
import { getBlocks, getSolution, getSolutionOAuthToken } from '../ClothifyApi'
import $ from 'jquery'

export default function SolutionBlocks () {
  const navigate = useNavigate()
  const { solutionId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [solutionName, setSolutionName] = useState('')
  const [blocks, setBlocks] = useState([])

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    getBlocks(solutionId)
      .then(data => {
        setBlocks(data)
      })
  }, [])

  function handleRowClick (data) {
    //   navigate('/multimedia/image-styles/' + String(data._id))
  }

  function handleCreateBlock (data) {
    const blockType = $('#blockType').val()
    navigate(`/solution/${solutionId}/blocks/create?type=${blockType}`)
  }

  function handleEditBlock (data) {
    navigate(`/solution/${solutionId}/blocks/${data.target.id}`)
  }

  const conditionalRowStyles = [
    {
      when: row => !row.status,
      style: {
        color: 'silver'
      }
    }
  ]

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }
  return (
    <>
      <Helmet>
        <title>Блоки | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/blocks`]: 'Блоки' }]}>
        <>
          <h1 className="df-title">Блоки {solutionName}</h1>
          <select className="form-control select2 w-25 mt-3 mr-3 d-inline" id="blockType">
            <option value="frontBanner">Баннер на главной</option>
            <option value="saleBanner">Баннер &quot;Распродажа&quot;</option>
            <option value="productCarousel">Карусель товаров</option>
            <option value="popularCategories">Популярные категории</option>
            <option value="newLooks">Новые образы</option>
            <option value="ajaxWatchedProducts">Вы смотрели/Рекомендуем</option>
            <option value="topBrandsBattle">Битва брендов</option>
            <option value="newSelections">Новые подборки</option>
            <option value="newArticles">Новые статьи</option>
            <option value="topBrands">Бренды дня</option>
          </select>
          <button type="button" className="btn btn-primary" onClick={handleCreateBlock}>
            Создать блок
          </button>
          <DataTable
            keyField="_id"
            defaultSortField="name"
            defaultSortAsc={true}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            conditionalRowStyles={conditionalRowStyles}
            columns={[
              {
                name: 'Тип',
                selector: (row, i) => row.type,
                sortable: true
              },
              {
                name: 'Название',
                selector: (row, i) => row.name,
                sortable: true
              },
              {
                name: 'Пол',
                selector: (row, i) => row.gender,
                sortable: true
              },
              {
                name: '',
                cell: function getCell (row) {
                  return <div className="w-100 h-100 d-flex"><button className="btn btn-link" onClick={handleEditBlock} id={row._id}>Редактировать</button></div>
                },
                right: true
              }
            ]}
            data={blocks}
            onRowClicked={handleRowClick}
          />
        </>
      </SimpleLayout>
    </>
  )
}
