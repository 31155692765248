import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import {
  emptyBonus,
  bonusGenderOptions,
  getCatalogBonusById,
  putCatalogBonusUpdates,
  styleImage
} from '../ClothifyApi'
import ReactSelect from '../ReactSelect'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import md5 from 'md5'
import { DndBootstrapTable } from '../DndBootstrapTable'
import { useCookies } from 'react-cookie'

export default function CatalogBonusEdit () {
  const navigate = useNavigate()
  const getParams = new URLSearchParams(location.search)
  const destination = getParams.get('destination')
  const { bonusId } = useParams()
  const [bonus, setBonus] = useState(emptyBonus)
  const formRef = useRef()
  const [updateImagesTable, setUpdateImagesTable] = useState(0)
  const [cookies, setCookie, removeCookie] = useCookies(['message'])
  let initMessage
  try {
    initMessage = JSON.parse(cookies.message)
    removeCookie('message')
  } catch (err) {
    initMessage = { text: '', type: 'success' }
  }
  const [message, setMessage] = useState(initMessage)

  const imgHost = 'https://cdn1.justbutik.ru/'
  const imageSize = { width: 420, height: 560 }
  const aspect = imageSize.width / imageSize.height
  const scale = 1
  const rotate = 0
  const imgRefs = useRef([null, null, null, null, null, null, null, null, null, null])
  const [crops, setCrops] = useState([])
  const [imgSrcs, setImgSrcs] = useState([])
  const [files, setFiles] = useState([])

  useEffect(() => {
    if (!token) {
      return
    }
    if (typeof bonusId === 'undefined' || bonusId === 'create') {
      const bonusData = JSON.parse(JSON.stringify(emptyBonus))
      setBonus(bonusData)
      setUpdateImagesTable(1)
    } else {
      getCatalogBonusById(bonusId)
        .then(response => {
          response.data.imagesData = []
          let rowId = 0
          response.data.images.forEach(image => {
            rowId++
            response.data.imagesData.push({ id: rowId, img: imgHost + image })
          })
          setBonus(response.data)
          forceUpdateImagesTable()
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }, [bonusId])

  const validateFormSchema = yup.object().shape({
    // tag: yup.string()
    //   .required('Это поле обязательно к заполнению.'),
    // machineName: yup.string()
    //   .required('Это поле обязательно к заполнению.')
  })

  const initialFormValues = {
    gender: bonus.gender || emptyBonus.gender,
    name: bonus.name || emptyBonus.name,
    category: bonus.category || '',
    brand: bonus.brand || '',
    images: bonus.images || [],
    imagesData: [],
    price: bonus.price || emptyBonus.price,
    qty: bonus.qty || emptyBonus.qty,
    params: bonus.params || emptyBonus.params,
    description: bonus.description || emptyBonus.description,
    status: bonus.status || emptyBonus.status
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      const bonusCopy = JSON.parse(JSON.stringify(bonus))
      const params = Object.assign({}, bonusCopy, values)
      putCatalogBonusUpdates(bonusId, params)
        .then(response => {
          setBonus(response.data)
          setCookie('message', JSON.stringify({ text: 'Бонусный товар успешно сохранен!', type: 'success' }))
          window.scrollTo(0, 0)
          setSubmitting(false)
          if (destination !== null) {
            setTimeout(navigate(destination), 1000)
          } else if (bonusId === 'create') {
            navigate(`/catalog/bonus/${response.data.id}`)
          }
        })
        .catch(error => {
          const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
          setStatus(errMsg)
          setSubmitting(false)
        })
    }, 500)
  }

  const setCrop = (index, crop) => {
    const cropsCopy = JSON.parse(JSON.stringify(crops))
    cropsCopy[index] = crop
    setCrops(cropsCopy)
  }

  const onImageLoad = (e, index) => {
    const { width, height } = e.currentTarget
    setCrop(index, centerCrop(makeAspectCrop(
      {
        unit: '%',
        width: 100
      },
      aspect,
      width,
      height
    ), width, height))
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrops([]) // Makes crop preview update between images.
      const imgFiles = []
      const srcs = []
      const imgCrops = []
      for (let i = 0; i < e.target.files.length; i++) {
        imgCrops.push({ unit: '%', width: 100, height: 100 })
      }
      setCrops(imgCrops)
      for (let i = 0; i < e.target.files.length; i++) {
        readFile(e.target.files[i], imgFiles, srcs, e.target.files.length)
      }
    }
  }

  const readFile = (file, imgFiles, srcs, length) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      imgFiles.push(file)
      srcs.push(reader.result?.toString() || '')
      if (length === imgFiles.length) {
        setFiles(imgFiles)
        setImgSrcs(srcs)
      }
    })
    reader.readAsDataURL(file)
  }

  const uploadImages = () => {
    console.log(crops)
    const promises = []
    for (let i = 0; i < imgSrcs.length; i++) {
      const baseFileName = md5(imgSrcs[i])
      const imageData = {
        path: 'bonus',
        baseFileName: baseFileName,
        actions: [
          {
            type: 'crop',
            params: crops[i]
          },
          {
            type: 'scale',
            params: imageSize
          }
        ],
        file: files[i]
      }
      promises.push(styleImage(imageData))
    }
    Promise.all(promises).then(results => {
      const bonusData = JSON.parse(JSON.stringify(formRef.current.values))
      results.forEach(result => {
        bonusData.images.push(result.data[0])
      })
      let rowId = 0
      bonusData.images.forEach(image => {
        rowId++
        bonusData.imagesData.push({ id: rowId, img: imgHost + image })
      })
      setBonus(bonusData)
      forceUpdateImagesTable()
      setMessage({ type: 'success', text: 'Картинки успешно загружены' })
    }).catch(err => {
      console.log(err)
      setMessage({ type: 'alert', text: 'Ошибка при загрузке картинок' })
      window.scrollTo(0, 0)
    })
  }

  const imgCell = (tableProps) => (
    <img src={tableProps.cell.value} alt="Preview" width={100}/>
  )

  const dndColumns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'img',
        Cell: imgCell
      }
    ],
    []
  )

  const onDeleteImage = (imagesData) => {
    const bonusData = JSON.parse(JSON.stringify(formRef.current.values))
    bonusData.imagesData = imagesData
    const images = []
    imagesData.forEach(image => {
      images.push(image.img.replace(imgHost, ''))
    })
    bonusData.images = images
    setBonus(bonusData)
  }

  const onMoveImage = (imagesData) => {
    const bonusData = JSON.parse(JSON.stringify(formRef.current.values))
    const images = []
    if (typeof imagesData !== 'undefined' && imagesData.length > 0) {
      let newId = 0
      imagesData.forEach(item => {
        newId++
        item.id = newId
        images.push(item.img.replace(imgHost, ''))
      })
    }
    bonusData.imagesData = imagesData
    bonusData.images = images
    setBonus(bonusData)
  }

  const forceUpdateImagesTable = () => {
    const newUpdateTable = updateImagesTable + 1
    setUpdateImagesTable(newUpdateTable)
  }

  return (<>
    <Helmet>
      <title>{'Редактирование бонусного товара "' + bonus.name + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог' }, { '/catalog/bonuses': 'Бонусные товары' }, { ['/catalog/bonus/' + bonus._id]: bonus.name }]}>
      <>
        <h1 className="df-title">{'Редактирование бонусного товара "' + bonus.name + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting, setFieldValue, values }) => (<Form>
            {message.text.length > 0 && <div
              className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
              <AlertCircle className="mg-r-10 "/> {message.text}</div>}
            <div className="row row-xs">
              <div className="col-12">
                <fieldset className="form-fieldset">
                  <div className="form-group">
                    <label className="d-block" htmlFor="name">
                      Название
                    </label>
                    <Field
                      name="name" type="text" className="form-control" placeholder="Укажите название бонусного товара"/>
                    <ErrorMessage name="name" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="gender">
                      Пол
                    </label>
                    <ReactSelect
                      name="gender" placeholder="Пол"
                      isMulti
                      value={bonusGenderOptions.filter(option => values.gender.includes(option.value))}
                      options={bonusGenderOptions} isClearable={false}/>
                    <ErrorMessage name="gender" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="price">
                      Цена
                    </label>
                    <Field
                      name="price" type="text" className="form-control"
                      placeholder="Цена товара"/>
                    <ErrorMessage name="price" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="category">
                      Категория
                    </label>
                    <Field
                      name="category" type="text" className="form-control" placeholder="Категория товара"/>
                    <ErrorMessage name="category" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="parentTag">
                      Бренд
                    </label>
                    <Field
                      name="brand" type="text" className="form-control"
                      placeholder="Бренд"/>
                    <ErrorMessage name="brand" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="description">
                      Описание товара
                    </label>
                    <Field
                      name="description" component="textarea" className="form-control" placeholder="Описание товара"/>
                    <ErrorMessage name="description" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="params">
                      Параметры товара
                    </label>
                    <Field
                      name="params" component="textarea" className="form-control" placeholder="Параметры товара"/>
                    <ErrorMessage name="params" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="qty">
                      Остаток на складе
                    </label>
                    <Field
                      name="qty" type="text" className="form-control"
                      placeholder="Статус бонусного товара"/>
                    <ErrorMessage name="qty" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="images">
                      Картинки
                    </label>
                    <div className="form-group row pt-3 pb-3">
                      <div className="col-12">
                        <DndBootstrapTable
                          key={updateImagesTable} columns={dndColumns} data={bonus.imagesData}
                          onDeleteItem={onDeleteImage} onMoveItem={onMoveImage}/>
                      </div>
                      <div className="col-6">
                        {
                          imgSrcs.map((imgSrc, index) => {
                            return (
                              <ReactCrop
                                key={index} crop={crops[index]}
                                onChange={(_, percentCrop) => setCrop(index, percentCrop)}>
                                <img
                                  ref={imgRefs[index]} alt="Картинка товара" src={imgSrc}
                                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: '200px' }}
                                  onLoad={() => onImageLoad(event, index)}/>
                              </ReactCrop>
                            )
                          })
                        }
                      </div>
                      <div className="col-6">
                        <input type="file" accept="image/*" onChange={onSelectFile} multiple/>
                        <button
                          type="button" className="btn btn-primary" onClick={uploadImages}>Загрузить
                        </button>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
