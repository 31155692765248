import config from '../config'
import axios from 'axios'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/vnd.api+json'

export const signup = (email, password, remember) => {
  return axios
    .post('/signup', { email, password, remember })
    .then(response => {
      authService.authToken = response.data
      return authService.authToken
    })
    .catch(error => {
      if (!error.response || [401, 403].indexOf(error.response.status) !== -1) {
        // auto logout if Network error or 401 Unauthorized or 403 Forbidden response returned from api
        localStorage.removeItem('authToken')
        localStorage.removeItem('userData')
      }
      const err = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
      return Promise.reject(err)
    })
}

export const signout = (callback) => {
  localStorage.removeItem('authToken')
  localStorage.removeItem('userData')
  setTimeout(callback, 100)
}

export const authService = {
  login: signup,
  get authToken () {
    const authToken = JSON.parse(localStorage.getItem('authToken'))
    if (!authToken) {
      return null
    } else {
      const exp = authToken.exp
      if (!exp || exp <= ~~(Date.now() / 1000)) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authToken')
        return null
      } else {
        return authToken
      }
    }
  },
  set authToken (tokenData) { localStorage.setItem('authToken', JSON.stringify(tokenData)) },
  get userData () {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (!userData) {
      const authToken = JSON.parse(localStorage.getItem('authToken'))
      if (!authToken || !authToken.token) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authToken')
        return null
      }
      const axiosInstance = axios.create({
        headers: { Authorization: 'Bearer ' + authToken.token }
      })
      return axiosInstance
        .get('/users/me')
        .then(response => {
          localStorage.setItem('userData', JSON.stringify(response.data))
          return response.data
        })
        .catch(error => {
          if (!error.response || [401, 403].indexOf(error.response.status) !== -1) {
            // auto logout if Network error or 401 Unauthorized or 403 Forbidden response returned from api
            localStorage.removeItem('userData')
            localStorage.removeItem('authToken')
            return null
          }
        })
    } else {
      return userData
    }
  },
  get isAuthenticated () { return !!this.authToken }
}

export const token = (authService.authToken && authService.authToken.token) ? authService.authToken.token : null
