import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Layers as IconLayers } from 'react-feather'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'

function Catalog () {
  return (
    <>
      <Helmet>
        <title>Управление каталогом товаров | Clothify Manager</title>
      </Helmet>
      <SimpleLayout contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог товаров' }]}
      >
        <>
          <h1 className="df-title">Управление каталогом товаров</h1>
          <p className="df-lead">На этой странице собраны все компоненты для управления каталогом товаров.</p>
          <div className="row tx-14">
            <div className="col-sm-6">
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Основное дерево категорий</h5>
                <p className="mg-b-20">Управление основным деревом категорий.</p>
                <Link to="/catalog/categories" className="tx-medium">
                  Перейти к управлению деревом категорий <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Теги каталога</h5>
                <p className="mg-b-20">Управление тегами каталога.</p>
                <Link to="/catalog/tags" className="tx-medium">
                  Перейти к управлению тегами каталога <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Бренды</h5>
                <p className="mg-b-20">Управление брендами каталога.</p>
                <Link to="/catalog/brands" className="tx-medium">
                  Перейти к управлению брендами каталога <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
            </div>
          </div>
        </>
      </SimpleLayout>
    </>
  )
}

export default Catalog
