import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from '../../hooks/useLocalStorage'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import SortableTree, { walk as SortableTreeWalk } from '@nosferatu500/react-sortable-tree'
import '@nosferatu500/react-sortable-tree/style.css'
import { AlertCircle, Plus as IconPlus, Edit as IconEdit } from 'react-feather'
import {
  categoryGenderOptions,
  getCategoriesTree,
  getPseudoCategories,
  updateCategoryWeights,
  updateTagWeights
} from '../ClothifyApi'
import Select from 'react-select'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function CatalogCategories () {
  const defaultPageSettings = {
    gender: 'any'
  }
  const navigate = useNavigate()
  const [categoryTree, setCategoryTree] = useState([])
  let categoriesPageSettings = localStorage.getItem('categoriesPageSettings')
  try {
    if (categoriesPageSettings === null) {
      categoriesPageSettings = defaultPageSettings
    } else {
      categoriesPageSettings = JSON.parse(categoriesPageSettings)
    }
  } catch (e) {
    categoriesPageSettings = defaultPageSettings
  }

  const [gender, setGender] = useState(categoriesPageSettings.gender)
  const [status, setStatus] = useState([])
  const [isCategoryClicked, setIsCategoryClicked] = useState(false)
  const [expandedTreeCategories, setExpandedTreeCategories] = useLocalStorage('expandedTreeCategories', [])
  const wrapperRef = useRef(null)

  const patchCategory = (node) => {
    return axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).patch(`/categories/${node._id}`, node)
  }

  useEffect(() => {
    if (!token) {
      return
    }

    localStorage.setItem('categoriesPageSettings', JSON.stringify({
      gender: gender
    }))

    const promises = [
      getCategoriesTree(gender),
      getPseudoCategories(gender)
    ]
    Promise.all(promises)
      .then(responses => {
        const treeData = responses[0]
        const pseudoCategories = responses[1]
        mergeCategoriesTree(treeData, pseudoCategories, gender)
        SortableTreeWalk({
          treeData,
          callback: ({ node }) => {
            if (expandedTreeCategories.includes(node._id)) {
              node.expanded = true
            }
          },
          getNodeKey: ({ node }) => node._id,
          ignoreCollapsed: false
        })
        setCategoryTree(treeData)
      })
      .catch(err => {
        console.log(err)
      })

    function handleClickOutside (event) {
      if (wrapperRef.current && !wrapperRef.current.handlerConnector.dragPreviewNode.contains(event.target)) {
        setIsCategoryClicked(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside, { capture: true })
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true })
    }
  }, [expandedTreeCategories, wrapperRef, gender])

  const mergeCategoriesTree = (treeData, pseudoCategories, gender) => {
    treeData.forEach(treeItem => {
      treeItem.sort_weight = treeItem.gender_weight[gender]
      if (typeof treeItem.children !== 'undefined' && treeItem.children.length > 0) {
        mergeCategoriesTree(treeItem.children, pseudoCategories, gender)
      }
      if (typeof pseudoCategories[treeItem._id.toString()] !== 'undefined') {
        pseudoCategories[treeItem._id.toString()].forEach(pseudoCategory => {
          pseudoCategory.title = pseudoCategory.tagPageTitle !== '' ? pseudoCategory.tagPageTitle : pseudoCategory.tag
          pseudoCategory.subtitle = pseudoCategory.tagUrl
          pseudoCategory.parent = pseudoCategory.category
          pseudoCategory.path = treeItem.path + '#' + pseudoCategory._id.toString()
          pseudoCategory.enabled = pseudoCategory.status === 1
          pseudoCategory.sort_weight = pseudoCategory.weight
          treeItem.children.push(pseudoCategory)
        })
        treeItem.children.sort((a, b) => a.sort_weight > b.sort_weight ? 1 : -1)
      }
    })
  }

  const updateTreeWeights = (treeData, weightData, result) => {
    treeData.forEach(treeItem => {
      if (treeItem.sort_weight !== weightData.currentWeight) {
        treeItem.sort_weight = weightData.currentWeight
        if (typeof treeItem.tag !== 'undefined') {
          result.tags.push(treeItem)
        } else {
          treeItem.gender_weight[gender] = treeItem.sort_weight
          result.categories.push(treeItem)
        }
      }
      weightData.currentWeight += 1
      if (typeof treeItem.children !== 'undefined' && treeItem.children.length > 0) {
        updateTreeWeights(treeItem.children, weightData, result)
      }
    })
  }

  const handleOnChange = (treeData) => {
    const weightData = { currentWeight: 0 }
    const result = { categories: [], tags: [] }
    updateTreeWeights(treeData, weightData, result)
    if (result.categories.length > 0) {
      updateCategoryWeights(result.categories)
    }
    if (result.tags.length > 0) {
      updateTagWeights(result.tags)
      console.log(result.tags)
    }
    setCategoryTree(treeData)
    SortableTreeWalk({
      treeData,
      callback: ({ node }) => {
        // save expanded tree nodes
        if (node.expanded && !expandedTreeCategories.includes(node._id)) {
          expandedTreeCategories.push(node._id)
        }
        // remove collapsed tree nodes
        if (!node.expanded && expandedTreeCategories.includes(node._id)) {
          const index = expandedTreeCategories.indexOf(node._id)
          if (index > -1) {
            expandedTreeCategories.splice(index, 1)
          }
        }
        setExpandedTreeCategories(expandedTreeCategories)
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true
    })
  }

  const handleOnMoveNode = ({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex }) => {
    // node.sort_weight = nextTreeIndex
    let updateNode = false
    if (node.path !== nextPath.join('#')) {
      node.path = nextPath.join('#')
      updateNode = true
    }
    if (nextParentNode === null) {
      delete node.parent
      updateNode = true
    } else if (nextParentNode && node.parent !== nextParentNode._id) {
      node.parent = nextParentNode._id
      updateNode = true
    }
    if (updateNode) {
      patchCategory(node)
        .catch(error => {
          const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
          setStatus(errMsg)
        })
    }
  }

  const canDrag = (node) => {
    return true
  }

  const handleEditCategory = (node) => {
    navigate('/catalog/categories/' + node._id + '?destination=/catalog/categories')
  }

  const handleCreateCategory = (node) => {
    navigate('/catalog/categories/create?parentCategory=' + node._id + '&destination=/catalog/categories')
  }

  const handleCreateTag = (node) => {
    navigate('/catalog/tags/create?pseudoCategory=1&categoryId=' + node._id + '&destination=/catalog/categories')
  }

  const handleEditTag = (node) => {
    navigate('/catalog/tags/' + node._id + '?destination=/catalog/categories')
  }

  function handleCreateClick () {
    navigate('/catalog/categories/create?destination=/catalog/categories')
  }

  return (
    <>
      <Helmet>
        <title>Управление основным деревом категорий для предоставляемых товаров. | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог товаров' }, { '/catalog/categories': 'Основное дерево категорий товаров' }]}
      >
        <>
          <h1 className="df-title">Управление основным деревом категорий товаров</h1>
          {status.length > 0 && <div className="alert alert-danger d-flex align-items-center" role="alert">
            <AlertCircle className="mg-r-10 "/> {status}</div>}
          <div className="d-flex flex-row">
            <Select
              className="basic-single w-25 px-2"
              classNamePrefix="select"
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              placeholder="Любой пол"
              value={categoryGenderOptions.filter(option => option.value === gender)[0]}
              options={categoryGenderOptions} isClearable={false} isDisabled={false}
              onChange={option => setGender(option.value)}/>
            <button className="btn btn-primary tx-13 mg-b-10 px-2" onClick={handleCreateClick}>Создать категорию
            </button>
          </div>
          <div style={{ height: '400px' }}>
            <SortableTree
              canDrag={canDrag}
              isVirtualized={false}
              treeData={categoryTree}
              onChange={handleOnChange}
              getNodeKey={({ node }) => node._id}
              onMoveNode={handleOnMoveNode}
              generateNodeProps={({ node, path }) => ({
                ref: isCategoryClicked && isCategoryClicked.node && isCategoryClicked.node._id === node._id ? wrapperRef : null,
                style: (!node.enabled || !node.gender.includes(gender)) && (!isCategoryClicked || !isCategoryClicked.node || isCategoryClicked.node._id !== node._id) ? { opacity: 0.3 } : {},
                onDoubleClick: () => {
                  setIsCategoryClicked({ node, path })
                },
                title:
                  <span className={node.tag ? 'text-success' : ''}>
                    {node.title}
                  </span>,
                subtitle: node.subtitle,
                buttons: isCategoryClicked && isCategoryClicked.node && isCategoryClicked.node._id === node._id && !isCategoryClicked.node.tag
                  ? [
                    <button
                      type="button" className="btn btn-xs btn-success btn-icon"
                      title="Редактировать категорию"
                      key={node._id + '_edit'}
                      role="button"
                      onClick={() => {
                        handleEditCategory(node)
                      }}
                    >
                      <IconEdit role="button"/>
                    </button>,
                    <button
                      type="button" className="btn btn-xs btn-primary btn-icon mg-l-3"
                      title="Добавить дочернюю категорию"
                      key={node._id + '_add'}
                      onClick={() => {
                        handleCreateCategory(node)
                      }}
                    >
                      <IconPlus/>
                    </button>,
                    <button
                      type="button" className="btn btn-xs btn-primary btn-icon mg-l-3"
                      title="Добавить тег"
                      key={node._id + '_add'}
                      onClick={() => {
                        handleCreateTag(node)
                      }}
                    >
                      #
                    </button>
                  ] : isCategoryClicked && isCategoryClicked.node && isCategoryClicked.node._id === node._id && isCategoryClicked.node.tag
                    ? [
                      <button
                        type="button" className="btn btn-xs btn-success btn-icon"
                        title="Редактировать тег"
                        key={node._id + '_edit'}
                        role="button"
                        onClick={() => {
                          handleEditTag(node)
                        }}
                      >
                        <IconEdit role="button"/>
                      </button>
                    ]
                    : []
              })}
            />
          </div>
        </>
      </SimpleLayout>
    </>
  )
}
