import React, { useEffect, useState, useRef } from 'react'
import * as yup from 'yup'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '../../scss/pages/default.scss'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import { useCookies } from 'react-cookie'
import { DndBootstrapTable } from '../DndBootstrapTable'
import { EditProductModal } from '../modals/EditProductModal'
import { AddMultipleProductsModal } from '../AddMultipleProductsModal'
import {
  menuGenderOptions,
  getOffersImages,
  getLook,
  postLook,
  getSolution,
  getSolutionOAuthToken,
  styleImage
} from '../ClothifyApi'
import $ from 'jquery'
import ReactSelect from '../ReactSelect'
import ReactCrop, {
  centerCrop,
  makeAspectCrop
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default function SolutionLooksEdit () {
  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }

  const imgHost = 'https://cdn1.justbutik.ru/'
  const defaultLookData = {
    title: '',
    description: '',
    gender: 'women',
    originalImage: '',
    teaserImage: '',
    percent: getRandomInt(5, 20),
    products: [],
    status: true,
    productsData: []
  }
  const originalSize = { width: 366 }
  const teaserSize = { width: 335, height: 500 }
  const aspect = teaserSize.width / teaserSize.height
  const scale = 1
  const rotate = 0
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(undefined)
  const [imgSrc, setImgSrc] = useState('')
  const [file, setFile] = useState(undefined)

  const navigate = useNavigate()
  const formRef = useRef()
  const { solutionId, lookId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [solutionName, setSolutionName] = useState('')
  const [look, setLook] = useState(defaultLookData)
  const [updateProductsTable, setUpdateProductsTable] = useState(0)
  const [modalData, setModalData] = useState({})
  const [offerImages, setOfferImages] = useState({})
  const [multipleModalData, setMultipleModalData] = useState([{}])
  const [cookies, setCookie, removeCookie] = useCookies(['message'])
  let initMessage
  try {
    initMessage = JSON.parse(cookies.message)
    removeCookie('message')
  } catch (err) {
    initMessage = { text: '', type: 'success' }
  }
  const [message, setMessage] = useState(initMessage)

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    if (lookId === 'create') {
      setLook(defaultLookData)
    } else {
      getLook(solutionId, lookId)
        .then(data => {
          data.productsData = getOffers(data.products)
          setLook(data)
        })
    }
  }, [lookId])

  useEffect(() => {
    const ids = []
    let update = false
    let id = 0
    const uniqueIds = []
    look.productsData.forEach(productData => {
      if (ids.includes(productData.productId)) {
        update = true
      } else {
        if (productData.img !== offerImages[productData.productId]) {
          update = true
        }
        id++
        uniqueIds.push({ id: id, productId: productData.productId, img: offerImages[productData.productId] })
        ids.push(productData.productId)
      }
    })
    if (update) {
      const lookData = JSON.parse(JSON.stringify(look))
      lookData.productsData = uniqueIds
      setLook(lookData)
    } else {
      const unknownIds = []
      ids.forEach(id => {
        if (typeof offerImages[id] === 'undefined') {
          unknownIds.push(id)
        }
      })
      if (unknownIds.length > 0) {
        const images = JSON.parse(JSON.stringify(offerImages))
        getOffersImages(unknownIds)
          .then(response => {
            unknownIds.forEach(id => {
              if (typeof response.data[id] === 'undefined') {
                images[id] = ''
              } else {
                images[id] = imgHost + response.data[id]
              }
            })
            setOfferImages(images)
          })
      }
      forceUpdateProductsTable()
    }
  }, [look.productsData, offerImages])

  useEffect(() => {
    setUploadDisabled(crop === undefined)
  }, [crop])

  const validateFormSchema = yup.object().shape({
    title: yup.string().required('Это поле обязательно к заполнению.')
  })

  const handleAddProduct = () => {
    const productsData = ['']
    setMultipleModalData(productsData)
    $('#addMultipleProductsModal').modal('show')
  }

  const onSaveProduct = (params) => {
    const lookData = JSON.parse(JSON.stringify(formRef.current.values))
    if (params.id !== '') {
      if (typeof look.productsData !== 'undefined' && look.productsData.length > 0) {
        look.productsData.forEach((item, i) => {
          if (params.id === item.id) {
            lookData.productsData[i] = params
          }
        })
      }
    } else {
      params.id = 1
      if (typeof look.productsData !== 'undefined' && look.productsData.length > 0) {
        look.productsData.forEach(item => {
          if (params.id <= item.id) {
            params.id = item.id + 1
          }
        })
      }
      lookData.productsData.push(params)
    }
    setLook(lookData)
    setModalData({})
  }

  const onSaveMultipleProduct = (params) => {
    let maxId = 0
    if (typeof look.productsData !== 'undefined' && look.productsData.length > 0) {
      look.productsData.forEach(item => {
        if (maxId <= item.id) {
          maxId = item.id
        }
      })
    }
    const lookData = JSON.parse(JSON.stringify(formRef.current.values))
    params.productIds.forEach(productId => {
      productId = productId.trim()
      if (productId !== '') {
        maxId++
        lookData.productsData.push({ id: maxId, productId: productId, img: '' })
      }
    })
    setLook(lookData)
    setModalData({})
  }

  const onDeleteProduct = (productsData) => {
    const lookData = JSON.parse(JSON.stringify(formRef.current.values))
    lookData.productsData = productsData
    setLook(lookData)
  }

  const onMoveProduct = (productsData) => {
    const lookData = JSON.parse(JSON.stringify(formRef.current.values))
    if (typeof productsData !== 'undefined' && productsData.length > 0) {
      let newId = 0
      productsData.forEach(item => {
        newId++
        item.id = newId
      })
    }
    lookData.productsData = productsData
    setLook(lookData)
  }

  const onEditProduct = (productData) => {
    setModalData(productData)
    $('#editProductModal').modal('show')
  }

  const forceUpdateProductsTable = () => {
    const newUpdateTable = updateProductsTable + 1
    setUpdateProductsTable(newUpdateTable)
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      const lookData = JSON.parse(JSON.stringify(values))
      lookData.products = []
      look.productsData.forEach(item => {
        lookData.products.push(item.productId)
      })
      delete lookData.productsData
      if (lookId === undefined) {
        console.log('Look Id is undefined')
      } else {
        postLook(solutionId, lookId, lookData)
          .then((res) => {
            if (lookId === 'create') {
              setCookie('message', JSON.stringify({ text: 'Образ успешно создан', type: 'success' }))
              navigate(`/solution/${solutionId}/looks/${res._id}`)
            } else {
              setMessage({ type: 'success', text: 'Образ успешно сохранен' })
              window.scrollTo(0, 0)
              setSubmitting(false)
            }
          })
          .catch(err => {
            console.log(err)
            setMessage({ type: 'alert', text: 'При сохранении образа произошла ошибка' })
            window.scrollTo(0, 0)
            setSubmitting(false)
          })
      }
    }, 500)
  }

  const imgCell = (tableProps) => (
    <img src={tableProps.cell.value} alt="Preview" width={100}/>
  )

  const dndColumns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'id'
      },
      {
        Header: '',
        accessor: 'img',
        Cell: imgCell
      },
      {
        Header: 'Товары',
        accessor: 'productId'
      }
    ],
    []
  )

  const getOffers = (ids) => {
    // TODO
    const offers = []
    let rowId = 0
    ids.forEach(id => {
      rowId++
      offers.push({ id: rowId, productId: id, img: '' })
    })
    return offers
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setFile(e.target.files[0])
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadImages = () => {
    setUploadDisabled(true)
    const parts = imgSrc.split(';base64,')
    const contentType = parts.shift().split('data:').pop().toLowerCase()
    let ext = '.jpg'
    switch (contentType) {
      case 'image/jpeg':
      case 'image/jpg':
        ext = '.jpg'
        break
      case 'image/png':
        ext = '.png'
        break
      case 'image/gif':
        ext = '.gif'
        break
      case 'image/webp':
        ext = '.webp'
        break
    }

    const baseFileName = Date.now().toString()
    const fileName = baseFileName + ext
    const sourceData = {
      path: 'looks/source',
      baseFileName: fileName,
      file: file
    }
    const originalData = {
      path: 'looks/large',
      baseFileName: baseFileName,
      actions: [
        {
          type: 'crop',
          params: crop
        },
        {
          type: 'scale',
          params: originalSize
        }
      ],
      file: file
    }
    const teaserData = {
      path: 'looks/teaser',
      baseFileName: baseFileName,
      actions: [
        {
          type: 'crop',
          params: crop
        },
        {
          type: 'scale',
          params: teaserSize
        }
      ],
      file: file
    }
    Promise.all([
      styleImage(sourceData),
      styleImage(originalData),
      styleImage(teaserData)
    ])
      .then(results => {
        const lookData = JSON.parse(JSON.stringify(formRef.current.values))
        lookData.originalImage = results[1].data[0]
        lookData.teaserImage = results[2].data[0]
        setLook(lookData)
        setMessage({ type: 'success', text: 'Изображения успешно загружены' })
        window.scrollTo(0, 0)
      })
      .catch(err => {
        console.log(err)
        setMessage({ type: 'alert', text: 'При стилизации изображений произошла ошибка' })
        window.scrollTo(0, 0)
      })
  }

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }
  return (<>
    <Helmet>
      <title>{'Редактирование образа "' + look.title + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/looks`]: 'Образы' }, { [`/solution/${solutionId}/looks/${lookId}`]: 'Образ ' + look.title }]}>
      <>
        <h1 className="df-title">{'Редактирование образа "' + look.title + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={look}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting, values }) => (<Form>
            {message.text.length > 0 && <div
              className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
              <AlertCircle className="mg-r-10 "/> {message.text}</div>}
            <div className="row row-xs">
              <div className="col-lg-12">
                <div className="row row-xs">
                  <div className="col-12">
                    <fieldset className="form-fieldset">
                      <legend>Редактирование образа</legend>
                      <div className="font-weight-bold mb-3">Товаров в образе: {look.productsData.length}</div>
                      <div className="custom-control custom-switch mg-b-20">
                        <Field
                          name="status" type="checkbox"
                          className="custom-control-input" id="status"/>
                        <label
                          className="d-block custom-control-label"
                          htmlFor="status">
                          Образ опубликован
                        </label>
                        <ErrorMessage name="status" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="gender">
                          Пол
                        </label>
                        <ReactSelect
                          name="gender" placeholder="Пол"
                          value={menuGenderOptions.filter(option => option.value === values.gender)[0]}
                          options={menuGenderOptions} isClearable={false} isDisabled={false}/>
                        <ErrorMessage name="gender" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="title">Название</label>
                        <Field
                          name="title" type="text" className="form-control"
                          placeholder="Название образа"/>
                        <ErrorMessage name="title" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="description">
                          Описание
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={values.description}
                          onChange={(event, editor) => {
                            values.description = editor.getData()
                          }}
                        />
                        <ErrorMessage name="description" component={FormFieldError}/>
                      </div>
                      <div className="row">
                        <div className="col-3 p-2">
                          <img
                            alt="Основная картинка" src={imgHost + look.originalImage}
                            style={{ maxHeight: 100 }}/>
                        </div>
                        <div className="form-group col-9">
                          <label className="d-block" htmlFor="originalImage">
                            Оригинальная картинка
                          </label>
                          <Field
                            name="originalImage" type="text" className="form-control"
                            placeholder="Оригинальная картинка" disabled={true}/>
                          <ErrorMessage name="originalImage" component={FormFieldError}/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 p-2">
                          <img
                            alt="Основная картинка" src={imgHost + look.teaserImage}
                            style={{ maxHeight: 100 }}/>
                        </div>
                        <div className="form-group col-9">
                          <label className="d-block" htmlFor="teaserImage">
                            Тизер
                          </label>
                          <Field
                            name="teaserImage" type="text" className="form-control"
                            placeholder="Обрезанная картинка" disabled={true}/>
                          <ErrorMessage name="teaserImage" component={FormFieldError}/>
                        </div>
                      </div>
                      <div className="form-group row pt-3 pb-3">
                        <div className="col-6">
                          {!!imgSrc && (
                            <ReactCrop
                              crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)}
                              aspect={aspect}>
                              <img
                                ref={imgRef} alt="Original image" src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad}/>
                            </ReactCrop>
                          )}
                        </div>
                        <div className="col-6">
                          <input type="file" accept="image/*" onChange={onSelectFile}/>
                          <button
                            type="button" className="btn btn-primary" onClick={uploadImages}
                            disabled={uploadDisabled}>Загрузить
                          </button>
                        </div>
                      </div>
                    </fieldset>
                    <button type="button" className="btn btn-primary mt-3 mb-3" onClick={handleAddProduct}>
                      Добавить товары
                    </button>
                    <DndBootstrapTable
                      key={updateProductsTable} columns={dndColumns} data={look.productsData}
                      onDeleteItem={onDeleteProduct} onEditItem={onEditProduct} onMoveItem={onMoveProduct}/>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
          </Form>)}
        </Formik>
        <EditProductModal data={modalData} onSaveModal={onSaveProduct}/>
        <AddMultipleProductsModal data={multipleModalData} onSaveModal={onSaveMultipleProduct}/>
      </>
    </SimpleLayout>
  </>)
}
