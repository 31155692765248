import React from 'react'
import { Link } from 'react-router-dom'
import NavbarMenu from './navigation/NavbarMenu'
import NavbarRight from './navigation/NavbarRight'
import { Menu as IconMenu } from 'react-feather'

const Header = (props) => {
  const handleMainMenuOpenClick = (event) => {
    event.preventDefault()
    document.body.classList.add('navbar-nav-show')
  }

  return (
    <header className="navbar navbar-header navbar-header-fixed">
      <Link to="#" id="mainMenuOpen" onClick={handleMainMenuOpenClick} className="burger-menu">
        <IconMenu/>
      </Link>
      <div className="navbar-brand">
        <Link to="/" className="df-logo">Clothify</Link>
      </div>
      <NavbarMenu/>
      <NavbarRight/>
    </header>
  )
}

export default Header
