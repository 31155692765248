import React from 'react'
import PropTypes from 'prop-types'
import Header from './components/Header'
import Breadcrumbs from './components/navigation/Breadcrumbs'
import Footer from './components/Footer'
import Content from './components/Content'

function SimpleLayout (props) {
  return (
    <>
      <Header/>
      <Content contentClassName={props.contentClassName} containerClassName={props.containerClassName}>
        <>
          <Breadcrumbs path={props.breadcrumbs}/>
          {props.children}
        </>
      </Content>
      <Footer/>
      <div className="backdrop"></div>
    </>
  )
}

SimpleLayout.propTypes = {
  children: PropTypes.element.isRequired,
  contentClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  breadcrumbs: PropTypes.array
}

SimpleLayout.defaultProps = {
  contentClassName: 'content',
  containerClassName: 'container',
  breadcrumbs: [{ '/': 'Главная' }]
}

export default SimpleLayout
