import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { Check as IconCheck, X as IconX } from 'react-feather'
import $ from 'jquery'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function CategoryRelationsSettings () {
  const { feedId } = useParams()
  const [filterFeedCategoriesValue, setFilterFeedCategoriesValue] = useState('')
  const [filteredFeedCategories, setFilteredFeedCategories] = useState([])
  const [feedCategories, setFeedCategories] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [filterCatalogCategoriesValue, setFilterCatalogCategoriesValue] = useState('')
  const [filteredCatalogCategories, setFilteredCatalogCategories] = useState([])
  const [catalogCategories, setCatalogCategories] = useState([])
  const [selectedFeedCategories, setSelectedFeedCategories] = useState([])
  const [selectedCatalogCategory, setSelectedCatalogCategory] = useState('')
  const [deleteRelationsCategoryId, setDeleteRelationsCategoryId] = useState('')
  const [feed, setFeed] = useState({})
  const categories = {}

  useEffect(() => {
    if (!token) {
      return
    }
    if (feedId !== undefined) {
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/feeds/' + feedId)
        .then(response => {
          setFeed(response.data)
        })
        .catch(error => {
          console.log(error.response)
        })
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/feeds/categories/' + feedId)
        .then(response => {
          setFeedCategories(response.data)
          filterFeedCategories(showAll, response.data, filterFeedCategoriesValue)
        })
        .catch(error => {
          console.log(error.response)
        })
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/categories')
        .then(response => {
          if (response.data.length > 0) {
            response.data.forEach(item => {
              categories[item._id] = item
            })
            response.data.forEach(item => {
              item.namePath = getNamePath(item)
            })
            response.data.sort(function (a, b) {
              return a.namePath > b.namePath ? 1 : -1
            })
            response.data.unshift({ _id: '-1', enabled: true, name: 'Все категории', namePath: 'Все категории' })
          }
          setCatalogCategories(response.data)
          filterCatalogCategories(response.data, filterCatalogCategoriesValue)
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }, [feedId])

  const getNamePath = (category) => {
    if (typeof category.name !== 'string') {
      category.name = category.name.any
    }
    let path = ''
    if (category.parent !== undefined) {
      path += getNamePath(categories[category.parent]) + '/'
    }
    path += category.name
    return path
  }

  const changeFilterFeedCategoriesValue = (event) => {
    setFilterFeedCategoriesValue(event.target.value)
    filterFeedCategories(showAll, feedCategories, event.target.value)
  }

  const filterFeedCategories = (showAll, feedCategories, searchText) => {
    const filtered = feedCategories.filter((feedCategory) => {
      let show = true
      if (searchText !== '') {
        show = feedCategory.path.toLowerCase().includes(searchText.toLowerCase())
      }
      if (show && !showAll && feedCategory.cnt === 0) {
        show = false
      }
      return show
    })
    setFilteredFeedCategories(filtered)
  }

  const changeFilterCatalogCategoriesValue = (event) => {
    setFilterCatalogCategoriesValue(event.target.value)
    filterCatalogCategories(catalogCategories, event.target.value)
  }

  const filterCatalogCategories = (catalogCategories, searchText) => {
    const filtered = catalogCategories.filter((catalogCategory) => {
      return catalogCategory.namePath.toLowerCase().includes(searchText.toLowerCase())
    })
    setFilteredCatalogCategories(filtered)
  }

  const handleShowAllClick = (event) => {
    setShowAll(event.target.checked)
    filterFeedCategories(event.target.checked, feedCategories, filterFeedCategoriesValue)
  }

  const handleSelectFeedCategory = (event) => {
    const selectedFeedCategoriesCopy = [...selectedFeedCategories]
    if (event.target.checked) {
      selectedFeedCategoriesCopy.push(event.target.dataset.id)
    } else {
      const index = selectedFeedCategoriesCopy.indexOf(event.target.dataset.id)
      if (index > -1) {
        selectedFeedCategoriesCopy.splice(index, 1)
      }
    }
    setSelectedFeedCategories(selectedFeedCategoriesCopy)
  }

  const handleSelectCatalogCategory = (event) => {
    if (event.target.checked) {
      setSelectedCatalogCategory(event.target.dataset.id)
    } else {
      setSelectedCatalogCategory('')
    }
  }

  const handleSaveRelations = () => {
    if (selectedCatalogCategory !== '' && selectedFeedCategories.length > 0) {
      selectedFeedCategories.forEach(categoryId => {
        putCategoryRelations(categoryId, [selectedCatalogCategory])
      })

      setSelectedFeedCategories([])
      setSelectedCatalogCategory('')
    } else {
      $('#alertModal').modal('show')
    }
  }

  const handleDeleteRelations = (event) => {
    $('#confirmDeleteModal').modal('show')
    if (event.target.dataset.id === undefined) {
      setDeleteRelationsCategoryId(event.target.parentElement.dataset.id)
    } else {
      setDeleteRelationsCategoryId(event.target.dataset.id)
    }
  }

  const confirmDeleteDelations = (event) => {
    putCategoryRelations(deleteRelationsCategoryId, [])
    setSelectedFeedCategories([])
    setSelectedCatalogCategory('')
    $('#confirmDeleteModal').modal('hide')
    setDeleteRelationsCategoryId('')
  }

  const putCategoryRelations = (feedCategoryId, categoryRelations) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).put(`/feeds/categories/${feedCategoryId}`, { catalogCategories: categoryRelations })
      .then(response => {
        updateCategoryRelations(feedCategoryId, categoryRelations)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const updateCategoryRelations = (feedCategoryId, relatedCategories) => {
    const feedCategoriesCopy = [...feedCategories]
    feedCategoriesCopy.forEach(feedCategory => {
      if (feedCategory._id === feedCategoryId) {
        feedCategory.catalogCategories = relatedCategories
      }
    })
    setFeedCategories(feedCategoriesCopy)
  }

  return (<>
    <Helmet>
      <title>{'Настройка категорий фида "' + feed.name + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/import': 'Импорт' }, { '/import/feeds': 'Источники данных' }, { [`/import/feeds/${feedId}/category-relations`]: 'Настройка категорий фида "' + feed.name + '"' }]}
    >
      <>
        <h1 className="df-title">{'Настройка категорий фида "' + feed.name + '"'}</h1>
        <div className="row row-xs">
          <div className="col-6">
            <h3 className="d-inline mr-3">Категории фида</h3>
            <input type="checkbox" onChange={handleShowAllClick}/> Показать все категории
            <input
              type="search"
              value={filterFeedCategoriesValue}
              onChange={changeFilterFeedCategoriesValue}
              className="input w-100 p-1 mt-3 mb-3"
              placeholder="Поиск категории фида"
            />

            <div>
              {filteredFeedCategories && filteredFeedCategories.length > 0 ? (
                filteredFeedCategories.map((feedCategory) => (
                  <div className="row mx-0 mb-1" key={feedCategory._id}>
                    <div className="col-1">
                      <input
                        type="checkbox"
                        checked={selectedFeedCategories.includes(feedCategory._id)}
                        data-id={feedCategory._id} onChange={handleSelectFeedCategory}/>
                    </div>
                    <div className="col-1">
                      {feedCategory.catalogCategories.length > 0 &&
                      <>
                        <IconCheck color="green"/>
                      </>
                      }
                    </div>
                    <div className="col-8">{feedCategory.path}</div>
                    <div className="col-1">{feedCategory.cnt}</div>
                    <div className="col-1">
                      {feedCategory.catalogCategories.length > 0 &&
                      <IconX
                        role="button" title="Разорвать связь" color="red"
                        data-id={feedCategory._id} onClick={handleDeleteRelations}/>
                      }
                    </div>
                  </div>
                ))
              ) : (
                <h3>Ничего не найдено</h3>
              )}
            </div>
          </div>
          <div className="col-6">
            <h3 className="d-inline mr-3">Категории каталога</h3>
            <button
              type="button" className="btn btn-primary p-0 pl-1 pr-1 mt-n1" onClick={handleSaveRelations}>
              Сохранить связь
            </button>
            <input
              type="search"
              value={filterCatalogCategoriesValue}
              onChange={changeFilterCatalogCategoriesValue}
              className="input w-100 p-1 mt-3 mb-3"
              placeholder="Поиск категории каталога"
            />

            <div>
              {filteredCatalogCategories && filteredCatalogCategories.length > 0 ? (
                filteredCatalogCategories.map((catalogCategory) => (
                  <div className="row mx-0 mb-1" key={catalogCategory._id}>
                    <div className="col-1">
                      <input
                        type="checkbox" data-id={catalogCategory._id}
                        checked={selectedCatalogCategory === catalogCategory._id}
                        onChange={handleSelectCatalogCategory}/>
                    </div>
                    <div className="col-11">{catalogCategory.namePath}</div>
                  </div>
                ))
              ) : (
                <h3>Ничего не найдено</h3>
              )}
            </div>
          </div>
        </div>
        <div className="modal fade" id="alertModal" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content tx-14">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="alert alert-danger" role="alert">Выберите одну или несколько категорий фида и одну
                  категорию каталога
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="confirmDeleteModal" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content tx-14">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="alert alert-danger" role="alert">
                  Вы дествительно хотите разорвать связь?
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary tx-13" onClick={confirmDeleteDelations}>Да</button>
                <button type="button" className="btn btn-secondary tx-13" data-dismiss="modal">Нет</button>
              </div>
            </div>
          </div>
        </div>
      </>
    </SimpleLayout>
  </>)
}
