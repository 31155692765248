import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form } from 'formik'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import {
  getSolution,
  getSolutionOAuthToken,
  getSolutionVariable,
  teaserLinksOptions,
  saveSolutionVariable
} from '../ClothifyApi'
import Select from 'react-select'
import { useCookies } from 'react-cookie'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function TeaserSettingsEdit () {
  const navigate = useNavigate()

  const { solutionId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [solutionName, setSolutionName] = useState('')
  const [teaserSettings, setTeaserSettings] = useState({
    available_common: 'pLinkCurrent',
    available_picture: 'pLinkCurrent',
    not_available_common: 'pLinkBlank',
    not_available_picture: 'pLinkBlank'
  })
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['message'])
  let initMessage
  try {
    if (typeof cookies.message === 'undefined') {
      initMessage = { text: '', type: 'success' }
    } else {
      initMessage = cookies.message
      removeCookie('message')
    }
  } catch (err) {
    initMessage = { text: '', type: 'success' }
  }
  const [message, setMessage] = useState(initMessage)

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    getSolutionVariable(solutionId, 'teaser-settings')
      .then(variable => {
        const teaserSettings = JSON.parse(variable.value)
        setTeaserSettings(teaserSettings)
      })
      .catch(e => {
        console.log('getSettings error:', e)
        setTeaserSettings({
          available_common: 'pLinkCurrent',
          available_picture: 'pLinkCurrent',
          not_available_common: 'pLinkBlank',
          not_available_picture: 'pLinkBlank'
        })
      })
  }, [])

  const handleFormSubmit = (values, { setSubmitting }) => {
    setMessage({ text: '', type: 'success' })
    setSubmitting(true)
    setTimeout(() => {
      const result = JSON.stringify(values)
      saveSolutionVariable(solutionId, { name: 'teaser-settings', value: result })
        .then(result => {
          setMessage({ text: 'Настройки успешно сохранены', type: 'success' })
          setSubmitting(false)
        })
        .catch(() => {
          setMessage({ text: 'Ошибка при сохранении настроек', type: 'error' })
        })
    }, 500)
  }

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }

  return (<>
    <Helmet>
      <title>{'Настройка тизеров "' + solutionName + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/teaser-settings`]: 'Настройка тизеров' }]}>
      <>
        <h1 className="df-title">Настройка тизеров &quot;{solutionName}&quot;</h1>
        {message.text.length > 0 && <div
          className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
          <AlertCircle className="mg-r-10 "/> {message.text}</div>}
        <Formik
          enableReinitialize={true}
          initialValues={teaserSettings}
          onSubmit={handleFormSubmit}>
          {({ isSubmitting }) => (<Form>
            <div className="row row-xs">
              <div className="col-12">
                <label className="d-block">
                  Тип ссылки для товаров в наличии
                </label>
                <Select
                  className="basic-single w-100 px-2 mb-3"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Тип ссылки для товаров в наличии"
                  value={teaserLinksOptions.filter(option => option.value === teaserSettings.available_common)[0]}
                  options={teaserLinksOptions} isClearable={false} isDisabled={false}
                  onChange={option => {
                    const teaserSettingsClone = JSON.parse(JSON.stringify(teaserSettings))
                    teaserSettingsClone.available_common = option.value
                    setTeaserSettings(teaserSettingsClone)
                  }}/>
              </div>
            </div>
            <div className="row row-xs">
              <div className="col-12">
                <label className="d-block">
                  Тип ссылки для картинок товаров в наличии
                </label>
                <Select
                  className="basic-single w-100 px-2 mb-3"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Тип ссылки для картинок товаров в наличии"
                  value={teaserLinksOptions.filter(option => option.value === teaserSettings.available_picture)[0]}
                  options={teaserLinksOptions} isClearable={false} isDisabled={false}
                  onChange={option => {
                    const teaserSettingsClone = JSON.parse(JSON.stringify(teaserSettings))
                    teaserSettingsClone.available_picture = option.value
                    setTeaserSettings(teaserSettingsClone)
                  }}/>
              </div>
            </div>
            <div className="row row-xs">
              <div className="col-12">
                <label className="d-block">
                  Тип ссылки для товаров НЕ в наличии
                </label>
                <Select
                  className="basic-single w-100 px-2 mb-3"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Тип ссылки для товаров НЕ в наличии"
                  value={teaserLinksOptions.filter(option => option.value === teaserSettings.not_available_common)[0]}
                  options={teaserLinksOptions} isClearable={false} isDisabled={false}
                  onChange={option => {
                    const teaserSettingsClone = JSON.parse(JSON.stringify(teaserSettings))
                    teaserSettingsClone.not_available_common = option.value
                    setTeaserSettings(teaserSettingsClone)
                  }}/>
              </div>
            </div>
            <div className="row row-xs">
              <div className="col-12">
                <label className="d-block">
                  Тип ссылки для картинок товаров НЕ в наличии
                </label>
                <Select
                  className="basic-single w-100 px-2 mb-3"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Тип ссылки для картинок товаров НЕ в наличии"
                  value={teaserLinksOptions.filter(option => option.value === teaserSettings.not_available_picture)[0]}
                  options={teaserLinksOptions} isClearable={false} isDisabled={false}
                  onChange={option => {
                    const teaserSettingsClone = JSON.parse(JSON.stringify(teaserSettings))
                    teaserSettingsClone.not_available_picture = option.value
                    setTeaserSettings(teaserSettingsClone)
                  }}/>
              </div>
            </div>
            <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
              Сохранить
            </button>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
