import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import { authService } from '../services/auth.service'

const PrivateRoute = () => {
  return authService.isAuthenticated ? <Outlet/> : (
    <Navigate to="/login"/>)
}

PrivateRoute.propTypes = {
  location: PropTypes.object
}

export default PrivateRoute
