import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { AlertCircle } from 'react-feather'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import * as yup from 'yup'
import $ from 'jquery'

export function EditProductModal (modalParams) {
  const initialValues = {
    id: typeof modalParams.data.id === 'undefined' ? '' : modalParams.data.id,
    productId: modalParams.data.productId || ''
  }

  useEffect(() => {
    setFormValues(initialValues)
  }, [modalParams.data])

  const [formValues, setFormValues] = useState(initialValues)
  const handleOnChangeProductId = (event) => {
    const m = event.target.value.match('.*/go/([a-f0-9]{24})?')
    if (m !== null) {
      const formValuesCopy = JSON.parse(JSON.stringify(formValues))
      formValuesCopy.productId = m[1]
      setFormValues(formValuesCopy)
    }
  }

  const onSaveModal = modalParams.onSaveModal
  const handleSave = (params) => {
    $('#editProductModal').modal('hide')
    onSaveModal(params)
  }

  const validateFormSchema = yup.object().shape({
    productId: yup.string().required('Это поле обязательно для заполнения.')
  })

  return (
    <>
      <div
        className="modal fade" id="editProductModal" tabIndex="-1" role="dialog"
        aria-labelledby="removeConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={validateFormSchema}
                onSubmit={handleSave}>
                {({ status, isSubmitting }) => (<Form>
                  {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
                    <AlertCircle className="col-sm mg-r-10"/> {status}</div>}
                  <div className="row row-xs">
                    <div className="col-lg-12">
                      <div className="row row-xs">
                        <div className="col-12">
                          <Field name="id" type="hidden"/>
                          <div className="form-group">
                            <label className="d-block" htmlFor="productId">ID товара</label>
                            <Field
                              name="productId" type="text" className="form-control" placeholder="ID товара"
                              onKeyUp={handleOnChangeProductId}/>
                            <ErrorMessage name="productId" component={FormFieldError}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                      Сохранить
                    </button>
                  </div>
                </Form>)}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

EditProductModal.propTypes = {
  data: PropTypes.any
}
