import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import '@nosferatu500/react-sortable-tree/style.css'
import DataTable from 'react-data-table-component'
import { useCookies } from 'react-cookie'
import { AlertCircle } from 'react-feather'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function CatalogBonuses () {
  const navigate = useNavigate()
  const [catalogBonuses, setBonuses] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['message'])
  const [message, setMessage] = useState({ text: '', type: 'success' })

  useEffect(() => {
    if (!token) {
      return
    }
    if (cookies.message) {
      setMessage(cookies.message)
      removeCookie('message')
    }
    fetchBonuses(1)
  }, [])

  function handleEditClick (event) {
    navigate('/catalog/bonus/' + event.target.dataset.id + '?destination=/catalog/bonuses')
  }

  function handleCreateClick () {
    navigate('/catalog/bonus/create?destination=/catalog/bonuses')
  }

  const fetchBonuses = async page => {
    setLoading(true)

    const headers = { Authorization: 'Bearer ' + token }
    const getParams = {
      page: page,
      perPage: perPage
    }
    const response = await axios.create({ headers: headers }).get('/catalog-bonuses', { params: getParams })

    setBonuses(response.data.bonuses)
    setTotalRows(response.data.total)
    setLoading(false)
  }

  const handlePageChange = page => {
    fetchBonuses(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true)

    const headers = { Authorization: 'Bearer ' + token }
    const getParams = {
      page: page,
      perPage: perPage
    }
    const response = await axios.create({ headers: headers }).get('/catalog-bonuses', { params: getParams })

    setBonuses(response.data.bonuses)
    setPerPage(newPerPage)
    setLoading(false)
  }

  return (
    <>
      <Helmet>
        <title>Управление бонусными товарами | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог товаров' }, { '/catalog/bonuses': 'Бонусные товары' }]}
      >
        <>
          <h1 className="df-title">Управление бонусными товарами</h1>
          {message.text.length > 0 && <div
            className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
            <AlertCircle className="mg-r-10 "/> {message.text}</div>}
          <button className="btn btn-primary tx-13 mg-b-10" onClick={handleCreateClick}>Создать бонусный товар</button>
          <DataTable
            keyField="_id"
            defaultSortField="_id"
            defaultSortAsc={true}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            columns={[
              {
                name: 'Название',
                selector: (row, i) => row.name,
                sortable: false
              },
              {
                name: 'Пол',
                selector: (row, i) => row.gender,
                sortable: false,
                cell: function getGenderCell (row) {
                  return (
                    <>
                      {row.gender.join(' / ')}
                    </>
                  )
                }
              },
              {
                name: 'Остаток',
                selector: (row, i) => row.qty,
                sortable: false
              },
              {
                name: '',
                sortable: false,
                cell: function getActionCell (row) {
                  return (
                    <>
                      <button
                        type="button" className="btn btn-link" data-id={row._id} onClick={handleEditClick}>
                        Редактировать
                      </button>
                    </>
                  )
                },
                right: true
              }
            ]}
            data={catalogBonuses}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </>
      </SimpleLayout>
    </>
  )
}
