import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import { DndBootstrapTable } from '../DndBootstrapTable'
import { EditEffectModal } from '../modals/EditEffectModal'
import slugify from 'slugify'
import $ from 'jquery'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function ImageStyleEdit () {
  const navigate = useNavigate()
  const { imageStyleId } = useParams()
  const [imageStyle, setImageStyle] = useState({ name: '', machineName: '', actions: [] })
  const [effectData, setEffectData] = useState({})
  const [updateActionsTable, setUpdateActionsTable] = useState(0)

  useEffect(() => {
    if (!token) {
      return
    }
    if (imageStyleId !== undefined && imageStyleId !== 'create') {
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/images/styles/' + imageStyleId)
        .then(response => {
          delete response.data._id
          setImageStyle(response.data)
          setEffectData(response.data.actions)
          forceUpdateActionsTable()
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  }, [imageStyleId])

  const validateFormSchema = yup.object().shape({
    name: yup.string()
      .min(2, 'Слишком короткое.')
      .max(128, 'Слишком длинное.')
      .required('Это поле обязательно к заполнению.'),
    machineName: yup.string()
      .min(2, 'Слишком короткое.')
      .max(64, 'Слишком длинное.')
      .required('Это поле обязательно к заполнению.'),
    actions: yup.array()
  })

  const handleAddEffect = () => {
    const effectType = $('#addEffectTypeSelect').val()
    const effectData = { type: effectType }
    if (effectType === 'scale') {
      effectData.width = ''
      effectData.height = ''
      effectData.upscale = false
    } else if (effectType === 'scale-and-crop') {
      effectData.width = ''
      effectData.height = ''
    } else if (effectType === 'define-canvas') {
      effectData.color = ''
      effectData.under = false
      effectData.exact = {
        width: '',
        height: '',
        xpos: '',
        ypos: ''
      }
      effectData.relative = {
        leftdiff: '',
        rightdiff: '',
        topdiff: '',
        bottomdiff: ''
      }
    }
    setEffectData(effectData)
    $('#editEffectModal').modal('show')
  }

  const onSaveEffect = (params) => {
    const updateImageStyle = Object.assign({}, imageStyle)
    if (params.id > 0) {
      if (imageStyle.actions !== undefined && imageStyle.actions.length > 0) {
        imageStyle.actions.forEach((action, index) => {
          if (params.id === action.id) {
            updateImageStyle.actions[index] = params
          }
        })
      }
    } else {
      params.id = 1
      if (imageStyle.actions !== undefined && imageStyle.actions.length > 0) {
        imageStyle.actions.forEach(action => {
          if (params.id <= action.id) {
            params.id = action.id + 1
          }
        })
      }
      updateImageStyle.actions.push(params)
    }
    setImageStyle(updateImageStyle)
    forceUpdateActionsTable()
  }

  const onDeleteEffect = (actions) => {
    const updateImageStyle = Object.assign({}, imageStyle)
    updateImageStyle.actions = actions
    setImageStyle(updateImageStyle)
    forceUpdateActionsTable()
  }

  const onMoveEffect = (actions) => {
    const updateImageStyle = Object.assign({}, imageStyle)
    if (actions !== undefined && actions.length > 0) {
      let newId = 1
      actions.forEach((action, index) => {
        actions[index].id = newId
        newId++
      })
    }
    updateImageStyle.actions = actions
    setImageStyle(updateImageStyle)
    forceUpdateActionsTable()
  }

  const onEditEffect = (action) => {
    setEffectData(action)
    $('#editEffectModal').modal('show')
  }

  const forceUpdateActionsTable = () => {
    const newUpdateTable = updateActionsTable + 1
    setUpdateActionsTable(newUpdateTable)
  }

  const handleStyleChange = (event) => {
    const updateImageStyle = Object.assign({}, imageStyle)
    updateImageStyle.name = event.target.value
    updateImageStyle.machineName = slugify(event.target.value)
    setImageStyle(updateImageStyle)
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      if (imageStyleId === undefined) {
        console.log('Image style id is undefined')
      } else if (imageStyleId === 'create') {
        axios.create({
          headers: { Authorization: 'Bearer ' + token }
        }).post('/images/styles', Object.assign({}, imageStyle, values))
          .then(response => {
            navigate(`/multimedia/image-styles/${response.data._id}`)
          })
          .catch(error => {
            const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
            setStatus(errMsg)
            setSubmitting(false)
          })
      } else {
        axios.create({
          headers: { Authorization: 'Bearer ' + token }
        }).put(`/images/styles/${imageStyleId}`, Object.assign({}, imageStyle, values))
          .then(response => {
            setImageStyle(response.data)
          })
          .catch(error => {
            const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
            setStatus(errMsg)
            setSubmitting(false)
          })
      }
    }, 500)
  }

  const dndColumns = React.useMemo(
    () => [
      {
        Header: 'Эффект',
        accessor: 'type'
      }
    ],
    []
  )

  return (<>
    <Helmet>
      <title>{'Настройка стиля изображений "' + imageStyle.name + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/import': 'Мультимедиа' }, { '/multimedia/image-styles': 'Стили изображений' }, { ['/multimedia/image-styles/' + imageStyle._id]: imageStyle.name }]}>
      <>
        <h1 className="df-title">{'Настройка стиля изображений "' + imageStyle.name + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={imageStyle}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}>
          {({ status, isSubmitting }) => (<Form>
            {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
              <AlertCircle className="col-sm mg-r-10"/> {status}</div>}
            <div className="row row-xs">
              <div className="col-lg-12">
                <div className="row row-xs">
                  <div className="col-12">
                    <fieldset className="form-fieldset">
                      <legend>Настройки стиля изображения</legend>
                      <div className="form-group">
                        <label className="d-block" htmlFor="url">Название</label>
                        <Field
                          name="name" type="text" className="form-control"
                          placeholder="Укажите название стиля" onChange={handleStyleChange}/>
                        <ErrorMessage name="name" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="machineName">
                          Алиас стиля
                        </label>
                        <Field
                          name="machineName" type="text" className="form-control"
                          placeholder="Укажите алиас стиля"/>
                        <ErrorMessage name="machineName" component={FormFieldError}/>
                      </div>
                    </fieldset>
                    <select className="form-control select2 w-25 mt-3 mr-3 d-inline" id="addEffectTypeSelect">
                      <option value="scale">Масштабирование</option>
                      <option value="scale-and-crop">Масштабирование и обрезка</option>
                      <option value="define-canvas">Фон</option>
                    </select>
                    <button type="button" className="btn btn-primary mt-3 mb-3" onClick={handleAddEffect}> Добавить
                      эффект
                    </button>
                    <DndBootstrapTable
                      key={updateActionsTable} columns={dndColumns} data={imageStyle.actions}
                      onDeleteItem={onDeleteEffect} onEditItem={onEditEffect} onMoveItem={onMoveEffect}/>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
          </Form>)}
        </Formik>
        <EditEffectModal data={effectData} onSaveEffect={onSaveEffect}/>
      </>
    </SimpleLayout>
  </>)
}
