import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/auth.scss'
import loginVector from '../../images/3053447.jpg'
import { AlertCircle } from 'react-feather'
import { signup } from '../../services/auth.service'
import * as Yup from 'yup'

const Login = () => {
  const navigate = useNavigate()

  const initialValues = {
    email: '',
    password: '',
    remember: false
  }

  const validateFormSchema = Yup.object().shape({
    password: Yup.string()
      .required('Это поле обязательно к заполнению.'),
    email: Yup.string()
      .email('Это поле должно быть адресом электропочты.')
      .required('Это поле обязательно к заполнению.')
  })

  const handleSubmit = ({ email, password, remember }, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      signup(email, password, remember)
        .then(token => {
          setSubmitting(false)
          navigate('/')
        })
        .catch(error => {
          const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
          setStatus(errMsg)
          setSubmitting(false)
        })
    }, 500)
  }

  return (
    <>
      <Helmet>
        <title>Вход в админку | Clothify Manager</title>
      </Helmet>
      <SimpleLayout contentClassName="content content-fixed content-auth">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="media-body align-items-center d-none d-lg-flex">
            <div className="mx-wd-600">
              <img src={loginVector} className="img-fluid" alt="Login Vector"/>
            </div>
          </div>
          <div className="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div className="wd-100p">
              <h3 className="tx-color-01 mg-b-5">Вход в админку</h3>
              <p className="tx-color-03 tx-16 mg-b-40">Приветствую. Войдите, чтобы продолжить.</p>
              <Formik initialValues={initialValues} validationSchema={validateFormSchema} onSubmit={handleSubmit}>
                {({ isSubmitting, status }) => (
                  <Form>
                    {status && <div className="alert alert-danger d-flex align-items-center" role="alert">
                      <AlertCircle className="mg-r-10"/> {status}</div>}
                    <div className="form-group">
                      <label>Электропочта</label>
                      <Field type="email" name="email" className="form-control" placeholder="yourname@yourmail.com" tabIndex="1"/>
                      <ErrorMessage name="email" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-between mg-b-5">
                        <label className="mg-b-0-f">Пароль</label>
                        <Link to="/forgot" className="tx-13">Забыли пароль?</Link>
                      </div>
                      <Field type="password" name="password" className="form-control" placeholder="Введите ваш пароль" tabIndex="2"/>
                      <ErrorMessage name="password" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <Field type="checkbox" name="remember" className="form-control custom-control-input" id="rememberMe"/>
                        <label className="custom-control-label" htmlFor="rememberMe">Запомнить меня</label>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-brand-02 btn-block" disabled={isSubmitting} tabIndex="3">Войти</button>
                  </Form>
                )}
              </Formik>
              <div className="divider-text">или</div>
              <button className="btn btn-outline-facebook btn-block">Войти через Facebook</button>
              <button className="btn btn-outline-twitter btn-block">Войти через Twitter</button>
              <div className="tx-13 mg-t-20 tx-center">Нет аккаунта? <Link to="/signup">Станьте нашим партнером</Link>.
              </div>
            </div>
          </div>
        </div>
      </SimpleLayout>
    </>
  )
}

export default Login
