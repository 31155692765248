import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Layers as IconLayers } from 'react-feather'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'

function Multimedia () {
  return (
    <>
      <Helmet>
        <title>Мультимедиа | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/multimedia': 'Мультимедиа' }]}>
        <>
          <h1 className="df-title">Мультимедиа</h1>
          <p className="df-lead">На этой странице собраны все компоненты для управления мультимедиа.</p>
          <div className="row tx-14">
            <div className="col-sm-6">
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Стили изображений</h5>
                <p className="mg-b-20">Управление стилями изображений.</p>
                <Link to="/multimedia/image-styles" className="tx-medium">
                  Перейти к управлению стилями изображений
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
            </div>
          </div>
        </>
      </SimpleLayout>
    </>
  )
}

export default Multimedia
