import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useLocalStorage from '../../hooks/useLocalStorage'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import SortableTree, { walk as SortableTreeWalk } from '@nosferatu500/react-sortable-tree'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import '@nosferatu500/react-sortable-tree/style.css'
import { AlertCircle, Edit as IconEdit, Plus as IconPlus, X as IconX } from 'react-feather'
import md5 from 'md5'
import {
  menuGenderOptions,
  getMenuTree,
  saveMenuTree,
  getSolution,
  getSolutionOAuthToken, styleImage
} from '../ClothifyApi'
import Select from 'react-select'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import * as yup from 'yup'
import ReactSelect from '../ReactSelect'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function SolutionMenuMain () {
  const navigate = useNavigate()
  const { solutionId } = useParams()
  const [solutionName, setSolutionName] = useState('')

  const initialFormValues = {
    _id: '',
    type: 'topLink',
    title: '',
    name: '',
    link: '',
    image: '',
    enabled: true,
    showEditForm: false
  }

  const typeOptions = [
    { value: 'topLink', label: 'Ссылка верхнего уровня' },
    { value: 'subtitle', label: 'Подзаголовок' },
    { value: 'link', label: 'Ссылка' },
    { value: 'moreLink', label: 'Ссылка "cмотреть больше"' }
  ]

  const [gotMenuTree, setGotMenuTree] = useState(false)
  const [showNameField, setShowNameField] = useState(true)
  const [menuTree, setMenuTree] = useState({ women: [], men: [] })
  const [loaded, setLoaded] = useState(false)
  const [treeChanged, setTreeChanged] = useState(false)
  const formRef = useRef()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [gender, setGender] = useState('women')
  const [message, setMessage] = useState({ text: '', type: 'success' })
  const [isItemClicked, setIsItemClicked] = useState(false)
  const [expandedTreeItems, setExpandedTreeItems] = useLocalStorage('expandedTreeItems', [])
  const wrapperRef = useRef(null)
  const [maxId, setMaxId] = useState(0)

  const imgHost = 'https://cdn1.justbutik.ru/'
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const imageSize = { width: 111, height: 111 }
  const aspect = 1
  const scale = 1
  const rotate = 0
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(undefined)
  const [imgSrc, setImgSrc] = useState('')
  const [file, setFile] = useState(undefined)

  const FormObserver = () => {
    const { values } = useFormikContext()

    useEffect(() => {
      const newVal = values.type === 'topLink'
      if (showNameField !== newVal) {
        setShowNameField(newVal)
      }
    }, [values.type])

    return null
  }

  useEffect(() => {
    setUploadDisabled(crop === undefined)
  }, [crop])

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    if (!token) {
      return
    }

    localStorage.setItem('categoriesPageSettings', JSON.stringify({
      gender: gender
    }))
    if (gotMenuTree) {
      const menuTreeCopy = JSON.parse(JSON.stringify(menuTree))
      // const existingIds = []
      // const removeIndexes = []
      // menuTreeCopy[gender].forEach((topLink, i) => {
      //   if (existingIds.includes(topLink._id)) {
      //     // TODO: update duplicate id
      //     removeIndexes.unshift(i)
      //   } else {
      //     existingIds.push(topLink._id)
      //   }
      // })
      // if (removeIndexes.length > 0) {
      //   removeIndexes.forEach(removeIndex => {
      //     menuTreeCopy[gender].splice(removeIndex, 1)
      //   })
      // }
      let maxNodeId = 0
      SortableTreeWalk({
        treeData: menuTreeCopy[gender],
        callback: ({ node }) => {
          if (node._id > maxNodeId) {
            maxNodeId = node._id
          }
          node.expanded = expandedTreeItems.includes(node._id)
        },
        getNodeKey: ({ node }) => node._id,
        ignoreCollapsed: false
      })
      setMaxId(maxNodeId)
      setMenuTree(menuTreeCopy)
    } else {
      getMenuTree(solutionId, 'main')
        .then(menuTreeData => {
          setGotMenuTree(true)
          let maxNodeId = 0
          SortableTreeWalk({
            treeData: menuTreeData[gender],
            callback: ({ node }) => {
              if (node._id > maxNodeId) {
                maxNodeId = node._id
              }
              node.expanded = expandedTreeItems.includes(node._id)
            },
            getNodeKey: ({ node }) => node._id,
            ignoreCollapsed: false
          })
          setMaxId(maxNodeId)
          setMenuTree(menuTreeData)
        })
        .catch(e => {
          console.log(e)
        })
    }

    function handleClickOutside (event) {
      if (wrapperRef.current && !wrapperRef.current.handlerConnector.dragPreviewNode.contains(event.target)) {
        setIsItemClicked(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside, { capture: true })
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true })
    }
  }, [expandedTreeItems, wrapperRef, gender])

  const handleOnChange = (treeData) => {
    const copyMenuTree = JSON.parse(JSON.stringify(menuTree))
    copyMenuTree[gender] = treeData
    setMenuTree(copyMenuTree)
    setTreeChanged(true)
    SortableTreeWalk({
      treeData: treeData,
      callback: ({ node }) => {
        // save expanded tree nodes
        if (node.expanded && !expandedTreeItems.includes(node._id)) {
          expandedTreeItems.push(node._id)
        }
        // remove collapsed tree nodes
        if (!node.expanded && expandedTreeItems.includes(node._id)) {
          const index = expandedTreeItems.indexOf(node._id)
          if (index > -1) {
            expandedTreeItems.splice(index, 1)
          }
        }
        setExpandedTreeItems(expandedTreeItems)
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true
    })
  }

  const handleOnMoveNode = ({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex }) => {
    let updateNode = false
    if (node.path !== nextPath.join('#')) {
      node.path = nextPath.join('#')
      updateNode = true
    }
    if (nextParentNode === null) {
      delete node.parent
      updateNode = true
    } else if (nextParentNode && node.parent !== nextParentNode._id) {
      node.parent = nextParentNode._id
      updateNode = true
    }
    if (updateNode) {
      setTreeChanged(true)
    }
  }

  const canDrag = (node) => {
    return true
  }

  const handleEditMenuLink = (node) => {
    const values = {}
    values._id = node._id
    values.type = node.type
    values.title = node.title
    values.name = node.name
    values.link = node.link
    values.image = node.image ?? ''
    values.enabled = node.enabled
    values.showEditForm = true
    setFormValues(values)
  }

  const handleLinkEditSubmit = (values) => {
    const copyMenuTree = JSON.parse(JSON.stringify(menuTree))
    if (
      typeof values.parentNodeId !== 'undefined' &&
      values.parentNodeId === null
    ) {
      const newId = maxId + 1
      copyMenuTree[gender].push({
        _id: newId,
        path: newId.toString(),
        type: values.type,
        title: values.title,
        name: values.name,
        link: values.link,
        image: values.image,
        enabled: values.enabled
      })
      setMaxId(newId)
    }
    SortableTreeWalk({
      treeData: copyMenuTree[gender],
      callback: ({ node }) => {
        if (
          typeof values.parentNodeId !== 'undefined' &&
          typeof values._id === 'undefined' &&
          values.parentNodeId === node._id
        ) {
          const newId = maxId + 1
          node.children.push({
            _id: newId,
            path: node.path + '#' + newId.toString(),
            type: values.type,
            title: values.title,
            name: values.name,
            link: values.link,
            image: values.image,
            enabled: values.enabled
          })
          setMaxId(newId)
        } else if (node._id === values._id) {
          node.type = values.type
          node.title = values.title
          node.name = values.name
          node.link = values.link
          node.image = values.image
          node.enabled = values.enabled
        }
      },
      getNodeKey: ({ node }) => node._id,
      ignoreCollapsed: false
    })
    setMenuTree(copyMenuTree)
    setTreeChanged(true)
    setFormValues(initialFormValues)
  }

  const handleCloseEditForm = () => {
    setFormValues(initialFormValues)
  }

  const handleCreateChildMenuLink = (parentNode) => {
    const values = {}
    if (parentNode === null) {
      values.parentNodeId = null
    } else {
      values.parentNodeId = parentNode._id
    }
    values.type = 'link'
    values.title = ''
    values.name = ''
    values.link = ''
    values.image = ''
    values.enabled = true
    values.showEditForm = true
    setFormValues(values)
  }

  const handleCreateClick = () => {
    handleCreateChildMenuLink(null)
  }

  const validateLinkEdit = yup.object().shape({
    title: yup.string().required('Это поле обязательно к заполнению.')
  })

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setFile(e.target.files[0])
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(makeAspectCrop(
        {
          unit: '%',
          width: 100
        },
        width / height,
        width,
        height
      ))
    }
  }

  const uploadImages = () => {
    setUploadDisabled(true)
    const baseFileName = md5(imgSrc)
    const imageData = {
      path: 'menu',
      baseFileName: baseFileName,
      actions: [
        {
          type: 'crop',
          params: crop
        },
        {
          type: 'scale',
          params: imageSize
        }
      ],
      file: file
    }
    Promise.all([
      styleImage(imageData)
    ])
      .then(results => {
        const formData = JSON.parse(JSON.stringify(formRef.current.values))
        formData.image = results[0].data[0]
        setFormValues(formData)
        setMessage({ type: 'success', text: 'Изображения успешно загружены' })
        window.scrollTo(0, 0)
      })
      .catch(err => {
        console.log(err)
        setMessage({ type: 'alert', text: 'При стилизации изображений произошла ошибка' })
        window.scrollTo(0, 0)
      })
  }

  const handleSaveClick = () => {
    saveMenuTree(solutionId, 'main', menuTree)
      .then(() => {
        setMessage({ type: 'success', text: 'Сохранено' })
      })
      .catch(() => {
        setMessage({ type: 'alert', text: 'Ошибка при сохранении меню' })
      })
  }

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }
  return (
    <>
      <Helmet>
        <title>Настройка главного меню</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/menu-main`]: 'Главное меню' }]}
      >
        <>
          <h1 className="df-title">Настройка главного меню {solutionName}</h1>
          {message.text.length > 0 && <div
            className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
            <AlertCircle className="mg-r-10 "/> {message.text}</div>}
          <div className="d-flex flex-row">
            <Select
              className="basic-single w-25 px-2"
              classNamePrefix="select"
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              placeholder="Любой пол"
              value={menuGenderOptions.filter(option => option.value === gender)[0]}
              options={menuGenderOptions} isClearable={false} isDisabled={false}
              onChange={option => setGender(option.value)}/>
            <button className="btn btn-primary tx-13 mg-b-10 px-2" onClick={handleCreateClick}>
              Создать пункт меню
            </button>
            <button
              className="btn btn-primary tx-13 mg-b-10 px-2 ml-2" onClick={handleSaveClick} disabled={!treeChanged}>
              Сохранить
            </button>
          </div>
          <div style={{ height: '400px' }}>
            <SortableTree
              canDrag={canDrag}
              isVirtualized={false}
              treeData={menuTree[gender]}
              onChange={handleOnChange}
              getNodeKey={({ node }) => node._id}
              onMoveNode={handleOnMoveNode}
              generateNodeProps={({ node, path }) => ({
                ref: isItemClicked && isItemClicked.node && isItemClicked.node._id === node._id ? wrapperRef : null,
                style: !node.enabled && (!isItemClicked || !isItemClicked.node || isItemClicked.node._id !== node._id) ? { opacity: 0.3 } : {},
                onDoubleClick: () => {
                  setIsItemClicked({ node, path })
                },
                title:
                  <span
                    className={node.type === 'topLink' ? 'text-danger' : node.type === 'subtitle' ? 'text-warning' : node.type === 'link' ? 'text-primary' : node.type === 'moreLink' ? 'text-secondary' : ''}>
                    {node.title}
                  </span>,
                buttons: isItemClicked && isItemClicked.node && isItemClicked.node._id === node._id
                  ? [
                    <button
                      type="button" className="btn btn-xs btn-success btn-icon"
                      title="Редактировать категорию"
                      key={node._id + '_edit'}
                      role="button"
                      onClick={() => {
                        handleEditMenuLink(node)
                      }}
                    >
                      <IconEdit role="button"/>
                    </button>,
                    <button
                      type="button" className="btn btn-xs btn-primary btn-icon mg-l-3"
                      title="Добавить дочерний пункт меню"
                      key={node._id + '_add'}
                      onClick={() => {
                        handleCreateChildMenuLink(node)
                      }}
                    >
                      <IconPlus/>
                    </button>
                  ] : []
              })}
            />
          </div>
          {formValues.showEditForm
            ? <>
              <div className="fixed-top w-100 h-100 bg-white">
                <button
                  className="btn btn-xs btn-icon m-2 position-absolute" style={{ right: 0, zIndex: 1 }}
                  onClick={handleCloseEditForm}>
                  <IconX/>
                </button>
                <Formik
                  enableReinitialize={true}
                  initialValues={formValues}
                  validationSchema={validateLinkEdit}
                  onSubmit={handleLinkEditSubmit}
                  innerRef={formRef}
                >
                  {(values) => (<Form>
                    <div className="row row-xs">
                      <div className="col-12">
                        <fieldset className="form-fieldset">
                          <div className="row">
                            <div className="form-group col-4">
                              <label className="d-block" htmlFor="type">
                                Тип
                              </label>
                              <ReactSelect
                                name="type" placeholder="Тип"
                                value={typeOptions.filter(option => option.value === formValues.type)[0]}
                                options={typeOptions} isClearable={false} isDisabled={false}/>
                              <ErrorMessage name="type" component={FormFieldError}/>
                              <label className="d-block mt-3" htmlFor="title">
                                Текст ссылки
                              </label>
                              <Field name="title" type="text" className="form-control" placeholder="Заголовок"/>
                              <ErrorMessage name="title" component={FormFieldError}/>
                              {showNameField
                                ? <>
                                  <label className="d-block mt-3" htmlFor="name">
                                    Название меню
                                  </label>
                                  <Field name="name" type="text" className="form-control" placeholder="Название меню"/>
                                  <ErrorMessage name="name" component={FormFieldError}/>
                                </>
                                : ''
                              }
                              <label className="d-block mt-3" htmlFor="link">
                                Ссылка
                              </label>
                              <Field name="link" type="text" className="form-control" placeholder="Ссылка"/>
                              <ErrorMessage name="link" component={FormFieldError}/>
                              <div className="custom-control custom-switch mt-3 mg-b-20">
                                <Field name="enabled" type="checkbox" className="custom-control-input" id="enabled"/>
                                <label className="d-block custom-control-label" htmlFor="enabled">
                                  Включена
                                </label>
                                <ErrorMessage name="enabled" component={FormFieldError}/>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 p-2">
                              <img
                                alt="Изображение для меню" src={imgHost + formValues.image}
                                style={{ maxHeight: 100 }}/>
                            </div>
                            <div className="form-group col-9">
                              <label className="d-block" htmlFor="logo">
                                Изображение для меню
                              </label>
                              <Field
                                name="image" type="text" className="form-control"
                                placeholder="Изображение для меню" disabled={true}/>
                              <ErrorMessage name="image" component={FormFieldError}/>
                            </div>
                          </div>
                          <div className="form-group row pt-3 pb-3">
                            <div className="col-6">
                              {!!imgSrc && (
                                <ReactCrop
                                  crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} aspect={aspect}>
                                  <img
                                    ref={imgRef} alt="Изображение для меню" src={imgSrc}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: '200px' }}
                                    onLoad={onImageLoad}/>
                                </ReactCrop>
                              )}
                            </div>
                            <div className="col-6">
                              <input type="file" accept="image/*" onChange={onSelectFile}/>
                              <button
                                type="button" className="btn btn-primary" onClick={uploadImages}
                                disabled={uploadDisabled}>Загрузить
                              </button>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <button type="submit" className="btn btn-brand-02 btn-block mg-t-20">
                        Сохранить
                      </button>
                    </div>
                    <FormObserver/>
                  </Form>)}
                </Formik>
              </div>
            </>
            : ''
          }
        </>
      </SimpleLayout>
    </>
  )
}
