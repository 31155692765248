import React from 'react'

export default class Footer extends React.Component {
  render () {
    return (
      <footer className="footer">
        <div>
          <span>&copy; 2021 Clothify v1.0.0. </span>
          <span>Created by <a href="http://justbutik.ru">justbutik.ru</a></span>
        </div>
        <div>
          <nav className="nav">
            <a href="https://justbutik.ru/" className="nav-link">Justbutik 2023</a>
            <a href="https://api.clothify.irk-it.ru/documentation/static/" className="nav-link">API Documentation</a>
          </nav>
        </div>
      </footer>
    )
  }
}
