import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { authService } from '../services/auth.service'

const PublicRoute = () => {
  return !authService.isAuthenticated ? <Outlet/> : (
    <Navigate to="/"/>)
}

PublicRoute.propTypes = {
}

export default PublicRoute
