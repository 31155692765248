import React, { useState } from 'react'
import { useTable } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PropTypes } from 'prop-types'

export function DndBootstrapTable (params) {
  const [records, setRecords] = useState(params.data)
  const columns = params.columns
  const onDeleteItem = params.onDeleteItem
  const onEditItem = params.onEditItem
  const onMoveItem = params.onMoveItem

  const onMoveRow = (dragIndex, hoverIndex) => {
    const r = [...records]
    moveInArray(r, dragIndex, hoverIndex)
    setRecords(r)
    onMoveItem(r)
  }

  const moveInArray = (arr, from, to) => {
    const item = arr.splice(from, 1)
    if (!item.length) {
      throw new Error('There is no item in the array at index ' + from)
    }
    arr.splice(to, 0, item[0])
  }

  const deleteFromArray = (arr, index) => {
    arr.splice(index, 1)
  }

  function DndBootstrapRow (params) {
    const row = params.row
    const index = params.index
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)

    const [, drop] = useDrop({
      accept: 'row',
      drop (item, monitor) {
        if (!dropRef.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        if (dragIndex === hoverIndex) {
          return
        }
        const hoverBoundingRect = dropRef.current.getBoundingClientRect()
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        params.onMoveRow(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    })

    const handleEditItem = (event) => {
      onEditItem(records[event.target.dataset.index])
    }

    const handleDeleteItem = (event) => {
      const r = [...records]
      deleteFromArray(r, event.target.dataset.index)
      setRecords(r)
      onDeleteItem(r)
    }

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'row',
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    return (
      <div ref={dropRef} style={{ opacity }}>
        <div className="row w-100 m-0" ref={dragRef}>
          {row.cells.map((cell, index) => {
            return <div key={index} className="cell p-1 d-flex align-items-center w-25" {...cell.getCellProps()}>
              <div>{cell.render('Cell')}</div>
            </div>
          })}
          <div className="cell p-1 w-25 ml-auto d-flex">
            {onEditItem !== undefined && <button type="button" className="btn btn-link" data-index={index} onClick={handleEditItem}>Изменить</button>}
            <button type="button" className="btn btn-link" data-index={index} onClick={handleDeleteItem}>Удалить</button>
          </div>
        </div>
      </div>
    )
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: records })

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div {...getTableProps()}>
          <div>
            {headerGroups.map((headerGroup, index) => (
              <div key={index} className="row w-100 m-0" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <div key={index} className="cell w-50" {...column.getHeaderProps()}>{column.render('Header')}</div>
                ))}
                <div className="cell w-50"></div>
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()}>
            {rows.map(
              (row, index) =>
                prepareRow(row) || (
                  <DndBootstrapRow
                    key={index}
                    index={index}
                    row={row}
                    onMoveRow={onMoveRow}
                    {...row.getRowProps()}
                  />
                )
            )}
          </div>
        </div>
      </DndProvider>
    </>
  )
}

DndBootstrapTable.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any
}
