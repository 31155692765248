import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import '../../scss/pages/default.scss'
import { useCookies } from 'react-cookie'
import { PopularCategories } from '../forms/PopularCategories'
import { FrontBanner } from '../forms/FrontBanner'
import { SaleBanner } from '../forms/SaleBanner'
import { ProductCarousel } from '../forms/ProductCarousel'
import { NewLooks } from '../forms/NewLooks'
import { AjaxWatchedProducts } from '../forms/AjaxWatchedProducts'
import { TopBrandsBattle } from '../forms/TopBrandsBattle'
import { NewSelections } from '../forms/NewSelections'
import { NewArticles } from '../forms/NewArticles'
import { TopBrands } from '../forms/TopBrands'
import SimpleLayout from '../../layouts/simple'
import {
  getBlock,
  postBlock,
  getSolution,
  getSolutionOAuthToken
} from '../ClothifyApi'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'

export default function SolutionBlocksEdit () {
  const defaultBlockNames = {
    popularCategories: 'Популярные категории',
    frontBanner: 'Баннер на главной',
    saleBanner: 'Баннер \'Распродажа\'',
    productCarousel: 'Карусель товаров',
    newLooks: 'Новые образы',
    ajaxWatchedProducts: 'Вы смотрели/Рекомендуем',
    topBrandsBattle: 'Битва брендов',
    newSelections: 'Новые подборки',
    newArticles: 'Новые статьи',
    topBrands: 'Топ брендов'
  }
  const defaultBlockData = {
    type: '',
    name: '',
    gender: [],
    params: {},
    status: true
  }

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { solutionId, blockId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [solutionName, setSolutionName] = useState('')
  const [block, setBlock] = useState(defaultBlockData)
  const [blockName, setBlockName] = useState(defaultBlockData.name)
  const [cookies, setCookie, removeCookie] = useCookies(['message'])
  let initMessage
  try {
    initMessage = JSON.parse(cookies.message)
    removeCookie('message')
  } catch (err) {
    initMessage = { text: '', type: 'success' }
  }
  const [message, setMessage] = useState(initMessage)

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    if (blockId === 'create') {
      const blockData = JSON.parse(JSON.stringify(defaultBlockData))
      blockData.type = searchParams.get('type')
      blockData.name = defaultBlockNames[searchParams.get('type')]
      setBlockName(blockData.name)
      setBlock(blockData)
    } else {
      getBlock(solutionId, blockId)
        .then(data => {
          setBlockName(data.name)
          setBlock(data)
          if (searchParams.get('create') === 'true') {
            setMessage({ text: 'Блок успешно сохранен', type: 'success' })
          }
        })
    }
  }, [blockId])

  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    setTimeout(() => {
      const blockData = JSON.parse(JSON.stringify(values))
      if (blockId === undefined) {
        console.log('Block Id is undefined')
      } else {
        postBlock(solutionId, blockId, blockData)
          .then((res) => {
            if (blockId === 'create') {
              setCookie('message', JSON.stringify({ text: 'Блок успешно создан', type: 'success' }))
              navigate(`/solution/${solutionId}/blocks/${res._id}?create=true`)
            } else {
              const destination = searchParams.get('destination')
              if (destination === null || destination[0] !== '/') {
                setMessage({ type: 'success', text: 'Блок успешно сохранен' })
                window.scrollTo(0, 0)
                setSubmitting(false)
              } else {
                setCookie('message', JSON.stringify({ text: 'Блок успешно сохранен', type: 'success' }))
                navigate(destination)
              }
            }
          })
          .catch(err => {
            console.log(err)
            setMessage({ type: 'alert', text: 'При сохранении блока произошла ошибка' })
            window.scrollTo(0, 0)
            setSubmitting(false)
          })
      }
    }, 500)
  }

  const BlockForm = (params) => {
    switch (params.block.type) {
      case 'popularCategories':
        return <PopularCategories
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'frontBanner':
        return <FrontBanner
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'saleBanner':
        return <SaleBanner
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'productCarousel':
        return <ProductCarousel
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'newLooks':
        return <NewLooks
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'ajaxWatchedProducts':
        return <AjaxWatchedProducts
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'topBrandsBattle':
        return <TopBrandsBattle
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'newSelections':
        return <NewSelections
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'newArticles':
        return <NewArticles
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      case 'topBrands':
        return <TopBrands
          block={params.block} setBlock={params.setBlock}
          setBlockName={params.setBlockName} handleFormSubmit={params.handleFormSubmit}/>
      default:
        return <></>
    }
  }

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }
  return (
    <>
      <Helmet>
        <title>{'Редактирование блока "' + blockName + '" | Clothify Manager'}</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/blocks`]: 'Блоки' }, { [`/solution/${solutionId}/blocks/${blockId}`]: 'Блок ' + block.name }]}>
        <>
          <h1 className="df-title">{'Редактирование блока "' + blockName + '"'}</h1>
          {message.text.length > 0 && <div
            className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
            <AlertCircle className="mg-r-10 "/> {message.text}</div>}
          {BlockForm({
            block: block,
            setBlock: setBlock,
            setBlockName: setBlockName,
            handleFormSubmit: handleFormSubmit
          })}
        </>
      </SimpleLayout>
    </>)
}
