import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import {
  emptyTag,
  emptyCategoryOption,
  emptyTagOption,
  tagGenderOptions,
  cnsOptions,
  getCatalogCategoryOptionByValue,
  filterParentTagOptions,
  getParentTagOptionByValue,
  filterBrandOptions,
  getBrandOptionByValue,
  filterCatalogCategoryOptions,
  getCatalogTagById,
  putCatalogTagUpdates, getTagUkey
} from '../ClothifyApi'
import AsyncSelect from 'react-select/async'
import ReactSelect from '../ReactSelect'

export default function CatalogTagEdit () {
  const navigate = useNavigate()
  const getParams = new URLSearchParams(location.search)
  const destination = getParams.get('destination')
  const initialMessage = getParams.get('created') === '1' ? 'Тег успешно создан!' : ''
  const { tagId } = useParams()
  const [tag, setTag] = useState(emptyTag)
  const [message, setMessage] = useState(initialMessage)

  useEffect(() => {
    if (!token) {
      return
    }
    getCatalogTagById(tagId)
      .then(response => {
        const promises = [
          getCatalogCategoryOptionByValue(response.data.category),
          getParentTagOptionByValue(response.data.parentTag),
          getBrandOptionByValue(response.data.brand)
        ]
        Promise.all(promises).then(responses => {
          response.data.categorySelect = responses[0]
          response.data.parentTagSelect = responses[1]
          response.data.brandSelect = responses[2]
          if (responses[0].value !== '') {
            response.data.category = responses[0].label + ' (' + responses[0].value + ')'
          }
          if (responses[1].value !== '') {
            response.data.parentTag = responses[1].label + ' (' + responses[1].value + ')'
          }
          setTag(response.data)
        })
      })
      .catch(error => {
        console.log(error.response)
      })
  }, [tagId])

  const validateFormSchema = yup.object().shape({
    // tag: yup.string()
    //   .required('Это поле обязательно к заполнению.'),
    // machineName: yup.string()
    //   .required('Это поле обязательно к заполнению.')
  })

  const initialFormValues = {
    lockFields: true,
    tag: tag.tag || '',
    category: tag.category || '',
    categorySelect: tag.categorySelect || emptyCategoryOption,
    croppedTag: tag.croppedTag || '',
    tagPageTitle: tag.tagPageTitle || '',
    tagPageTitleGenitive: tag.tagPageTitleGenitive || '',
    tagPageTitleAccusative: tag.tagPageTitleAccusative || '',
    gender: tag.gender || '',
    machineName: tag.machineName || '',
    tagUrl: tag.tagUrl || '',
    weight: tag.weight || 0,
    qty: tag.qty || 0,
    qry: tag.qry || '',
    status: tag.status || 0,
    linksData: tag.linksData || [],
    cns: tag.cns || '',
    brand: tag.brand || '',
    brandSelect: { value: '', label: '' },
    tagUkey: tag.tagUkey || '',
    plural: tag.plural || 0,
    contentEntranceQty: tag.contentEntranceQty || 0,
    redirectUrl: tag.redirectUrl || '',
    parentTag: tag.parentTag || '',
    parentTagSelect: tag.parentTagSelect || emptyTagOption,
    pseudoCategory: tag.pseudoCategory || false
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      const data = {
        gender: values.gender,
        category: values.categorySelect.value,
        croppedTag: values.croppedTag,
        cns: values.cns
      }
      getTagUkey(data).then(tagUkeyData => {
        if (typeof values.weight === 'string' && values.weight.trim() !== '') {
          values.weight = parseInt(values.weight.trim())
        }
        if (typeof values.status === 'string' && values.status.trim() !== '') {
          values.status = parseInt(values.status.trim())
        }
        const tagCopy = JSON.parse(JSON.stringify(tag))
        const params = Object.assign({}, tagCopy, values)
        params.category = values.categorySelect.value
        params.parentTag = values.parentTagSelect.value
        params.brand = values.brandSelect.value
        params.genderGroupUkey = tagUkeyData.genderGroupUkey
        delete params.categorySelect
        delete params.parentTagSelect
        delete params.brandSelect
        delete params.lockFields
        putCatalogTagUpdates(tagId, params)
          .then(response => {
            const promises = [
              getCatalogCategoryOptionByValue(response.data.category),
              getParentTagOptionByValue(response.data.parentTag),
              getBrandOptionByValue(response.data.brand)
            ]
            Promise.all(promises).then(responses => {
              response.data.categorySelect = responses[0]
              response.data.parentTagSelect = responses[1]
              response.data.brandSelect = responses[2]
              if (responses[0].value !== '') {
                response.data.category = responses[0].label + ' (' + responses[0].value + ')'
              }
              if (responses[1].value !== '') {
                response.data.parentTag = responses[1].label + ' (' + responses[1].value + ')'
              }
              setTag(response.data)
              setMessage('Тег успешно сохранен!')
              window.scrollTo(0, 0)
              setSubmitting(false)
              if (destination !== null) {
                setTimeout(navigate(destination), 1000)
              }
            })
          })
          .catch(error => {
            const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
            setStatus(errMsg)
            setSubmitting(false)
          })
      })
    }, 500)
  }

  const onBrandChange = (option, setFieldValue) => {
    if (option.value.trim() === '') {
      setFieldValue('brand', '')
    } else {
      setFieldValue('brand', option.label + ' (' + option.value + ')')
    }
    setFieldValue('brandSelect', option)
  }

  const onCategoryChange = (option, setFieldValue) => {
    if (option.value.trim() === '') {
      setFieldValue('category', '')
    } else {
      setFieldValue('category', option.label + ' (' + option.value + ')')
    }
    setFieldValue('categorySelect', option)
  }

  const onParentTagChange = (option, setFieldValue) => {
    if (option.value.trim() === '') {
      setFieldValue('parentTag', '')
    } else {
      setFieldValue('parentTag', option.label + ' (' + option.value + ')')
    }
    setFieldValue('parentTagSelect', option)
  }

  return (<>
    <Helmet>
      <title>{'Настройка тега "' + tag.tag + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог' }, { '/catalog/tags': 'Теги каталога' }, { ['/catalog/tags/' + tag._id]: tag.tag }]}>
      <>
        <h1 className="df-title">{'Настройка тега "' + tag.tag + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}>
          {({ status, isSubmitting, setFieldValue, values }) => (<Form>
            {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
              <AlertCircle className="col-sm mg-r-10"/>{status}</div>}
            {message && <div className="row alert alert-success d-flex align-items-center" role="alert">{message}</div>}
            <div className="row row-xs">
              <div className="col-12">
                <fieldset className="form-fieldset">
                  <div className="form-group">
                    <div className="custom-control custom-switch mg-b-20">
                      <Field
                        name="lockFields" type="checkbox"
                        className="custom-control-input" id="lockFields"
                        onChange={event => setFieldValue('lockFields', event.target.checked)}
                      />
                      <label
                        className="d-block custom-control-label"
                        htmlFor="lockFields">
                        Заблокировать редактирование полей
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-switch mg-b-20">
                      <Field
                        name="pseudoCategory" type="checkbox"
                        className="custom-control-input" id="pseudoCategory"/>
                      <label
                        className="d-block custom-control-label"
                        htmlFor="pseudoCategory">
                        Псевдо категория
                      </label>
                      <ErrorMessage name="pseudoCategory" component={FormFieldError}/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="tag">
                      Тег
                    </label>
                    <Field
                      name="tag" type="text" className="form-control"
                      placeholder="Укажите название тега" disabled={values.lockFields}/>
                    <ErrorMessage name="tag" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="croppedTag">
                      Cropped tag
                    </label>
                    <Field
                      name="croppedTag" type="text" className="form-control"
                      placeholder="Cropped tag" disabled={values.lockFields}/>
                    <ErrorMessage name="croppedTag" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="category">
                      Категория
                    </label>
                    <Field
                      name="category" type="text" className="form-control"
                      placeholder="Категория каталога" disabled={true}/>
                    <ErrorMessage name="category" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <AsyncSelect
                      defaultOptions={tag.categorySelect.value === '' ? [emptyCategoryOption] : [emptyCategoryOption, tag.categorySelect]}
                      loadOptions={filterCatalogCategoryOptions}
                      onChange={value => onCategoryChange(value, setFieldValue)}
                      defaultValue={tag.categorySelect}
                      isDisabled={values.lockFields}
                    />
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="tagPageTitle">
                      Заголовок страницы тега (именительный падеж)
                    </label>
                    <Field
                      name="tagPageTitle" type="text" className="form-control"
                      placeholder="Заголовок страницы тега(ИП)"/>
                    <ErrorMessage name="tagPageTitle" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="tagPageTitleGenitive">
                      Заголовок страницы тега (родительный падеж)
                    </label>
                    <Field
                      name="tagPageTitleGenitive" type="text" className="form-control"
                      placeholder="Заголовок страницы тега(РП)"/>
                    <ErrorMessage name="tagPageTitleGenitive" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="tagPageTitleAccusative">
                      Заголовок страницы тега (винительный падеж)
                    </label>
                    <Field
                      name="tagPageTitleAccusative" type="text" className="form-control"
                      placeholder="Заголовок страницы тега(ВП)"/>
                    <ErrorMessage name="tagPageTitleAccusative" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="gender">
                      Пол
                    </label>
                    <ReactSelect
                      name="gender" placeholder="Выберите пол тега"
                      value={tagGenderOptions.filter(option => option.value === values.gender)[0]}
                      options={tagGenderOptions} isClearable={false} isDisabled={values.lockFields}/>
                    <ErrorMessage name="gender" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="machineName">
                      Machine name
                    </label>
                    <Field
                      name="machineName" type="text" className="form-control"
                      placeholder="Укажите machineName тега" disabled={values.lockFields}/>
                    <ErrorMessage name="machineName" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="tagUrl">
                      URL тега
                    </label>
                    <Field
                      name="tagUrl" type="text" className="form-control"
                      placeholder="Укажите URL тега" disabled={values.lockFields}/>
                    <ErrorMessage name="tagUrl" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="weight">
                      Вес тега
                    </label>
                    <Field
                      name="weight" type="text" className="form-control"
                      placeholder="Укажите вес тега"/>
                    <ErrorMessage name="weight" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="qty">
                      Количество товаров каталога тега
                    </label>
                    <Field
                      name="qty" type="text" className="form-control"
                      placeholder="Количество товаров каталога тега" disabled={values.lockFields}/>
                    <ErrorMessage name="qty" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="qry">
                      Поисковая строка тега
                    </label>
                    <Field
                      name="qry" type="text" className="form-control"
                      placeholder="Поисковая строка тега"/>
                    <ErrorMessage name="qry" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="status">
                      Status
                    </label>
                    <Field
                      name="status" type="text" className="form-control"
                      placeholder="Tag status"/>
                    <ErrorMessage name="status" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="cns">
                      Cheap/new/sale
                    </label>
                    <ReactSelect
                      name="cns" options={cnsOptions}
                      value={cnsOptions.filter(option => option.value === values.cns)[0]}
                      isClearable={true} isDisabled={values.lockFields}/>
                    <ErrorMessage name="cns" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="parentTag">
                      Бренд
                    </label>
                    <Field
                      name="brand" type="text" className="form-control"
                      placeholder="Бренд" disabled={true}/>
                    <ErrorMessage name="brand" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <AsyncSelect
                      defaultOptions={[{ value: '', label: '' }, tag.brandSelect]}
                      loadOptions={filterBrandOptions}
                      onChange={value => onBrandChange(value, setFieldValue)}
                      defaultValue={tag.brandSelect}
                      isDisabled={values.lockFields}
                    />
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="tagUkey">
                      Tag UKEY
                    </label>
                    <Field
                      name="tagUkey" type="text" className="form-control"
                      placeholder="Tag UKEY" disabled={values.lockFields}/>
                    <ErrorMessage name="tagUkey" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="redirectUrl">
                      Redirect URL
                    </label>
                    <Field
                      name="redirectUrl" type="text" className="form-control"
                      placeholder="Redirect URL"/>
                    <ErrorMessage name="redirectUrl" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="parentTag">
                      Родительский тег
                    </label>
                    <Field
                      name="parentTag" type="text" className="form-control"
                      placeholder="Родительский тег" disabled={true}/>
                    <ErrorMessage name="parentTag" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <AsyncSelect
                      defaultOptions={tag.parentTagSelect.value === '' ? [emptyTagOption] : [emptyTagOption, tag.parentTagSelect]}
                      loadOptions={filterParentTagOptions}
                      onChange={value => onParentTagChange(value, setFieldValue)}
                      defaultValue={tag.parentTagSelect}
                      isDisabled={values.lockFields}
                    />
                  </div>
                </fieldset>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
