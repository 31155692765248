import axios from 'axios'
import { token } from '../services/auth.service'
import config from '../config'
import slugify from 'slugify'
import decode from 'unescape'

const MD5 = require('crypto-js/md5')

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

let items = null
const labels = {}
const processedWordsStatic = {}

export const prepositions = ['о', 'в', 'из', 'на', 'для', 'к', 'ко']

export const teaserLinksOptions = [
  { value: 'pLinkCurrent', label: 'Открыть страницу товара в текущем окне' },
  { value: 'pLinkBlank', label: 'Открыть страницу товара в новом окне' },
  { value: 'goLink', label: 'Открыть партнерскую ссылку в новом окне' }
]

export const translateGender = {
  any: 'всем',
  men: 'мужчинам',
  women: 'женщинам'
}

export const emptyCategoryOption = {
  value: '',
  label: 'Выберите категорию'
}

export const emptyBrandOption = {
  value: '',
  label: 'Выберите бренд'
}

export const emptyTagOption = {
  value: '',
  label: 'Выберите тег'
}

export const menuGenderOptions = [
  { value: 'men', label: 'Мужской' },
  { value: 'women', label: 'Женский' }
]

export const categoryGenderOptions = [
  { value: 'any', label: 'Без пола' },
  { value: 'men', label: 'Мужской' },
  { value: 'women', label: 'Женский' }
]

export const tagGenderOptions = [
  { value: '', label: 'Без пола' },
  { value: 'men', label: 'Мужской' },
  { value: 'women', label: 'Женский' }
]

export const bonusGenderOptions = [
  { value: 'men', label: 'Мужской' },
  { value: 'women', label: 'Женский' }
]

export const cnsOptions = [
  { value: 'cheap', label: 'Cheap' },
  { value: 'new', label: 'New' },
  { value: 'sale', label: 'Sale' }
]

export const emptyBonus = {
  _id: '',
  name: '',
  gender: ['men', 'women'],
  category: '',
  brand: '',
  images: [],
  imagesData: [],
  price: 0,
  qty: 0,
  params: '',
  description: '',
  status: 0
}
export const emptyTag = {
  _id: '',
  id: 0,
  category: '',
  categorySelect: emptyCategoryOption,
  tag: '',
  croppedTag: '',
  tagPageTitle: '',
  tagPageTitleGenitive: '',
  tagPageTitleAccusative: '',
  gender: '',
  machineName: '',
  tagUrl: '',
  weight: 0,
  qty: 0,
  qry: '',
  status: 0,
  linksData: [],
  cns: '',
  brand: '',
  brandSelect: { value: '', label: '' },
  tagUkey: '',
  plural: 0,
  contentEntranceQty: 0,
  redirectUrl: '',
  parentTag: '',
  parentTagSelect: emptyTagOption,
  pseudoCategory: false
}

export const emptyBrand = {
  _id: '',
  name: '',
  slug: '',
  status: 1,
  logo: ''
}

export const emptyCategory = {
  _id: '',
  enabled: true,
  gender: ['any', 'men', 'women'],
  path: '',
  additional_parents: [],
  name: { any: '', women: '', men: '' },
  facet_name: { any: '', women: '', men: '' },
  slug: '',
  category_corrector_patterns: '',
  facet_weight: 0,
  sort_weight: 0,
  gender_weight: { any: 0, men: 0, women: 0 },
  word_cases: { any: {}, women: {}, men: {} },
  word_single_form: '',
  word_forms: [],
  drupal_category_id: 0,
  redirectDisabled: { any: '', women: '', men: '' },
  redirectDisabledSelect: { any: emptyTagOption, women: emptyTagOption, men: emptyTagOption },
  run_corrector: false,
  straight_import: false
}

export function getJobsByTag (tag) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/jobs', { params: { tag: tag.trim().toLowerCase() } })
      .then(response => {
        resolve(response.data)
      })
  })
}

export function getTagUkey (data) {
  return new Promise(resolve => {
    getTagWords(data.croppedTag).then(tagWords => {
      const tagUkeyData = {
        category: data.category,
        gender: data.gender,
        cns: data.cns,
        words: tagWords
      }
      const genderGroupUkeyData = {
        category: data.category,
        cns: data.cns,
        words: tagWords
      }
      resolve(
        {
          tagUkey: MD5(JSON.stringify(tagUkeyData)).toString(),
          genderGroupUkey: MD5(JSON.stringify(genderGroupUkeyData)).toString()
        })
    })
  })
}

function getTagWords (croppedTag) {
  let words = []
  return new Promise(resolve => {
    const matches = croppedTag.toLowerCase().match(/(?<=[^a-zа-яё0-9-'&]|^)([a-zа-яё0-9-'&]+)(?=[^a-zа-яё0-9-'&]|$)/giu)
    if (matches !== null && matches.length > 0) {
      words = matches
      const normalizedWords = []
      normalizeWords(normalizedWords, words).then(processedWords => {
        words = processedWords.sort()
        resolve(words)
      })
    }
  })
}

export function normalizeWords (normalizedWords, words) {
  return new Promise(resolve => {
    const currentWord = words.shift()
    if (currentWord.match(/[a-z0-9]+/iu) !== null) {
      normalizedWords.push(currentWord)
      if (words.length > 0) {
        normalizeWords(normalizedWords, words)
          .then(result => {
            resolve(result)
          })
      } else {
        resolve(normalizedWords)
      }
    } else if (typeof processedWordsStatic[currentWord] === 'undefined') {
      getMorpherDeclensions(currentWord).then(wordDeclensions => {
        if (typeof wordDeclensions['множественное'] !== 'undefined') {
          normalizedWords.push(wordDeclensions['множественное']['И'])
          processedWordsStatic[currentWord] = [wordDeclensions['множественное']['И'], 0]
        } else {
          normalizedWords.push(currentWord)
          processedWordsStatic[currentWord] = [currentWord, 1]
        }
        if (words.length > 0) {
          normalizeWords(normalizedWords, words)
            .then(result => {
              resolve(result)
            })
        } else {
          resolve(normalizedWords)
        }
      })
    } else {
      normalizedWords.push(processedWordsStatic[currentWord][0])
      if (words.length > 0) {
        normalizeWords(normalizedWords, words)
          .then(result => {
            resolve(result)
          })
      } else {
        resolve(normalizedWords)
      }
    }
  })
}

export function getMorpherDeclensions (text) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/morpher/declensions', { params: { text: text.trim().toLowerCase() } })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log('getMorpherDeclensions error: ' + err.message)
        console.log(err)
        reject(err)
      })
  })
}

export function clothifySlugify (text) {
  slugify.extend({ '’': '-' })
  slugify.extend({ '´': '-' })
  slugify.extend({ ',': '-' })
  slugify.extend({ '™': '-tm' })
  slugify.extend({ '№': 'no' })
  slugify.extend({ я: 'ya' })
  slugify.extend({ й: 'y' })
  slugify.extend({ ц: 'c' })
  slugify.extend({ х: 'h' })
  slugify.extend({ ю: 'yu' })
  slugify.extend({ ей: 'ey' })
  slugify.extend({ ҫ: 's-' })
  slugify.extend({ ё: 'yo' })
  let alias = decode(slugify(text.toLowerCase()))
  alias = alias.replace(/[^a-z0-9-]+/, '-')
  alias = alias.replace(/-{2,}/, '-')
  alias = alias.replace(/^[-]+/, '')
  alias = alias.replace(/[-]+$/, '')

  return alias
}

export function getCatalogCategoryById (categoryId) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/categories/' + categoryId)
      .then(response => {
        resolve(response.data)
      })
  })
}

export function putCatalogCategoryRelations (categoryId, feedId, relatedPaths) {
  return new Promise(resolve => {
    const body = {
      feedId: feedId,
      paths: relatedPaths,
      catalogCategory: categoryId
    }
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).put('/feeds/categories-relations', body)
      .then(response => {
        resolve(response.data)
      })
  })
}

export function getCatalogCategoryOptionByValue (categoryId) {
  return new Promise(resolve => {
    if (typeof categoryId === 'undefined' || categoryId === '' || categoryId === null) {
      resolve({ value: '', label: 'Выберите категорию' })
    } else {
      if (items === null) {
        filterCatalogCategoryOptions('').then(filteredOptions => {
          resolve({ value: categoryId, label: labels[categoryId] })
        })
      } else {
        resolve({ value: categoryId, label: labels[categoryId] })
      }
    }
  })
}

export function filterCatalogCategoryOptions (text) {
  return new Promise(resolve => {
    if (items === null) {
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/categories')
        .then(response => {
          const names = {}
          items = response.data
          items.forEach(item => {
            names[item._id] = item.name.any
          })
          items.forEach(item => {
            const pathItems = item.path.split('#')
            let label = ''
            pathItems.forEach(pathItem => {
              label += '/' + names[pathItem]
            })
            labels[item._id] = label
          })
          resolve(filterOptions(items, labels, text))
        })
    } else {
      resolve(filterOptions(items, labels, text))
    }
  })
}

function filterOptions (items, labels, text) {
  const options = []
  items.forEach(item => {
    if (text.trim() === '' || item.name.any.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
      options.push({ value: item._id, label: labels[item._id] })
    }
  })
  return options
}

export function filterParentTagOptions (text) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/catalog-tags', { params: { page: 1, perPage: 10, filter: text.trim().toLowerCase() } })
      .then(response => {
        const tagLabels = {}
        const tagItems = response.data.tags
        tagItems.forEach(item => {
          tagLabels[item._id] = item.tag
        })
        resolve(filterTagOptions(tagItems, tagLabels, text))
      })
  })
}

export function filterPseudoCategoryOptions (text) {
  return new Promise(resolve => {
    const params = {
      page: 1,
      perPage: 10,
      pseudoCategory: 1,
      filter: text.trim().toLowerCase()
    }
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/catalog-tags', { params: params })
      .then(response => {
        const tagLabels = {}
        const tagItems = response.data.tags
        tagItems.forEach(item => {
          tagLabels[item._id] = item.tag
        })
        resolve(filterTagOptions(tagItems, tagLabels, text))
      })
  })
}

export function getPseudoCategories (gender) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/catalog-tags/pseudo-categories', { params: { gender: gender } })
      .then(response => {
        const indexed = {}
        response.data.forEach(pseudoCategory => {
          if (typeof indexed[pseudoCategory.category] === 'undefined') {
            indexed[pseudoCategory.category] = []
          }
          indexed[pseudoCategory.category].push(pseudoCategory)
        })
        resolve(indexed)
      })
  })
}

export function getCategoriesTree (gender) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/categories/tree', { params: { gender: gender, showDisabled: true } })
      .then(response => {
        resolve(response.data)
      })
  })
}

export function getMenuTree (solutionId, menuName) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-menu/${solutionId}/${menuName}`, {})
      .then(response => {
        resolve(response.data)
      })
  })
}

export function saveMenuTree (solutionId, menuName, menuTree) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).post(`/solution-menu/${solutionId}/${menuName}`, menuTree)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}

export function getSolutionVariable (solutionId, name) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-variables/${solutionId}/${name}`, {})
      .then(response => {
        resolve(response.data)
      })
  })
}

export function saveSolutionVariable (solutionId, item) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).post(`/solution-variables/${solutionId}`, item)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}

export function getArticles (solutionId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-articles/${solutionId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getArticle (solutionId, articleId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-articles/${solutionId}/${articleId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function postArticle (solutionId, articleId, articleData) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).post(`/solution-articles/${solutionId}/${articleId}`, articleData)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getLooks (solutionId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-looks/${solutionId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getLook (solutionId, lookId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-looks/${solutionId}/${lookId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function postLook (solutionId, lookId, lookData) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).post(`/solution-looks/${solutionId}/${lookId}`, lookData)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getBlocks (solutionId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-blocks/${solutionId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getBlock (solutionId, blockId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-blocks/${solutionId}/${blockId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function postBlock (solutionId, blockId, blockData) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).post(`/solution-blocks/${solutionId}/${blockId}`, blockData)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getSelections (solutionId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-selections/${solutionId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getSelection (solutionId, selectionId) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get(`/solution-selections/${solutionId}/${selectionId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function postSelection (solutionId, selectionId, selectionData) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).post(`/solution-selections/${solutionId}/${selectionId}`, selectionData)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function filterBrandOptions (text) {
  return new Promise((resolve, reject) => {
    const params = {
      filter: text.trim().toLowerCase(),
      page: 1,
      perPage: 50
    }
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/brands', { params: params })
      .then(response => {
        const options = []
        const items = response.data
        items.forEach(item => {
          options.push({ value: item.slug, label: item.name })
        })
        resolve(options)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getParentTagOptionByValue (tagId) {
  return new Promise((resolve, reject) => {
    if (typeof tagId === 'undefined' || tagId === '') {
      resolve({ value: '', label: '' })
    } else {
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/catalog-tags/' + tagId)
        .then(response => {
          resolve({ value: tagId, label: response.data.tag })
        })
        .catch(err => {
          reject(err)
        })
    }
  })
}

export function getRedirectTagOptions (redirects) {
  const promises = []
  Object.keys(redirects).forEach(gender => {
    promises.push(new Promise(resolve => {
      const tagId = redirects[gender]
      if (typeof tagId === 'undefined' || tagId === '') {
        resolve({ gender: gender, option: { value: '', label: '' } })
      } else {
        axios.create({
          headers: { Authorization: 'Bearer ' + token }
        }).get('/catalog-tags/' + tagId)
          .then(response => {
            resolve({ gender: gender, option: { value: tagId, label: response.data.tag } })
          })
      }
    }))
  })
  return new Promise(resolve => {
    Promise.all(promises).then(results => {
      const options = {}
      results.forEach(res => {
        options[res.gender] = res.option
      })
      resolve(options)
    })
  })
}

export function getAdditionalParentCategories (additionalParents) {
  return new Promise(resolve => {
    filterCatalogCategoryOptions('').then(filteredOptions => {
      const additionalParentsArray = []
      additionalParents.forEach(additionalParentId => {
        additionalParentsArray.push(labels[additionalParentId] + ' (' + additionalParentId + ')')
      })
      resolve(additionalParentsArray)
    })
  })
}

function filterTagOptions (items, labels, text) {
  const options = [{ value: '', label: '' }]
  items.forEach(item => {
    if (text.trim() === '' || item.tag.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
      options.push({ value: item._id, label: labels[item._id] })
    }
  })
  return options
}

export function getBrandOptionByValue (brandSlug) {
  return new Promise(resolve => {
    if (typeof brandSlug === 'undefined' || brandSlug === '') {
      resolve({ value: '', label: '' })
    } else {
      axios.create({
        headers: { Authorization: 'Bearer ' + token }
      }).get('/brands', { params: { filter: JSON.stringify({ slug: brandSlug }) } })
        .then(response => {
          if (response.data.length > 0) {
            resolve({ value: brandSlug, label: response.data[0].name })
          } else {
            resolve({ value: '', label: '' })
          }
        })
    }
  })
}

export function tagUkeyExists (tagUkey) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/catalog-tags', { params: { page: 1, perPage: 10, tagUkey: tagUkey } })
      .then(response => {
        resolve(response.data.tags.length > 0 ? response.data.tags[0] : null)
      })
  })
}

export function getCatalogPage (tagData) {
  const body = {
    skip_facets: 1
  }
  if (tagData.gender !== '') {
    body.gender = tagData.gender
  }
  if (tagData.category !== '') {
    body.category = tagData.category
  }
  if (tagData.qry !== '') {
    body.text = tagData.qry
  }
  if (tagData.brand !== '') {
    body.brand = [tagData.brand]
  }
  if (['cheap', 'sale'].includes(tagData.cns)) {
    switch (tagData.cns) {
      case 'cheap':
        body.price = '[0 TO 3000]'
        break
      case 'sale':
        body.discounts = '1'
        break
    }
  }
  return new Promise(resolve => {
    axios.create({ headers: { Authorization: 'Bearer ' + token } })
      .post('/catalog', body)
      .then(response => {
        resolve(response)
      })
  })
}

export function updateCategoryWeights (body) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).post('/categories/update-weights', body)
}

export function updateTagWeights (body) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).post('/catalog-tags/update-weights', body)
}

export function putCategoryUpdates (categoryId, body) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).put(`/categories/${categoryId}`, body)
}

export function getCategoryById (categoryId) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).get('/categories/' + categoryId)
}

export function createCategory (body) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).post('/categories', body)
}

export function createCatalogTags (catalogTags) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).post('/catalog-tags', catalogTags)
}

export function getCatalogTagById (tagId) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).get('/catalog-tags/' + tagId)
}

export function putCatalogTagUpdates (tagId, body) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).put(`/catalog-tags/${tagId}`, body)
}

export function getCatalogBonusById (bonusId) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).get('/catalog-bonuses/' + bonusId)
}

export function putCatalogBonusUpdates (bonusId, body) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).put(`/catalog-bonuses/${bonusId}`, body)
}

export function saveSolutionOAuthToken (solutionId, credentials) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).post(`/solution-oauth/token/${solutionId}`, credentials)
}

export function getSolutionOAuth (solutionId) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).get(`/solution-oauth/${solutionId}`)
}

export function getSolutionOAuthToken (solutionId) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).get(`/solution-oauth/token/${solutionId}`)
}

export function getSolution (solutionId) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).get(`/solutions/${solutionId}`)
}

export function getSolutions () {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).get('/solutions')
}

export function uploadImage (data) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).post('/selectel/upload', data)
}

export function styleImage (data) {
  const formData = new FormData()
  formData.append('path', data.path)
  formData.append('baseFileName', data.baseFileName)
  if (typeof data.actions !== 'undefined') {
    formData.append('actions', JSON.stringify(data.actions))
  }
  formData.append('file', data.file, data.fileName)
  return axios.create({
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data'
    }
  }).post('/service-style-image/style-and-upload', formData)
}

export function getOffersImages (ids) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).post('/offers/get-images', ids)
}

export function getCatalogBrandById (brandId) {
  return new Promise(resolve => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/brands/' + brandId)
      .then(response => {
        resolve(response.data)
      })
  })
}

export function putCatalogBrandUpdates (brandId, body) {
  return axios.create({
    headers: { Authorization: 'Bearer ' + token }
  }).put(`/brands/${brandId}`, body)
}
