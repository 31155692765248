import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import $ from 'jquery'
import 'bootstrap'
import '@fortawesome/fontawesome-free'
import 'ionicons/dist/scss/ionicons.scss'
import 'typicons.font'
import './scss/dashforge.scss'

import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'
import NotFound from './components/pages/NotFound'
import Login from './components/pages/Login'
import Logout from './components/pages/Logout'
import Forgot from './components/pages/Forgot'
import Jobs from './components/pages/Jobs'
import JobImportDaemon from './components/pages/JobImportDaemon'
import Import from './components/boards/Import'
import ImportFeeds from './components/pages/ImportFeeds'
import ImportFeedSettings from './components/pages/ImportFeedSettings'
import Catalog from './components/boards/Catalog'
import CatalogCategories from './components/pages/CatalogCategories'
import CatalogTags from './components/pages/CatalogTags'
import CatalogBonuses from './components/pages/CatalogBonuses'
import CatalogBrands from './components/pages/CatalogBrands'
import Multimedia from './components/boards/Multimedia'
import Solution from './components/boards/Solution'
import SolutionMenuMain from './components/pages/SolutionMenuMain'
import ImageStyles from './components/pages/ImageStyles'
import ImageStyleEdit from './components/pages/ImageStyleEdit'
import CategoryRelationsSettings from './components/pages/CategoryRelationsSettings'
import CatalogCategoryEdit from './components/pages/CatalogCategoryEdit'
import CatalogCategoryCreate from './components/pages/CatalogCategoryCreate'
import CatalogTagEdit from './components/pages/CatalogTagEdit'
import CatalogBonusEdit from './components/pages/CatalogBonusEdit'
import CatalogBrandEdit from './components/pages/CatalogBrandEdit'
import CatalogTagCreate from './components/pages/CatalogTagCreate'
import InitLamodaCategories from './components/pages/InitLamodaCategories'
import SolutionArticles from './components/pages/SolutionArticles'
import SolutionArticlesEdit from './components/pages/SolutionArticlesEdit'
import SolutionLooks from './components/pages/SolutionLooks'
import SolutionLooksEdit from './components/pages/SolutionLooksEdit'
import SolutionBlocks from './components/pages/SolutionBlocks'
import SolutionBlocksEdit from './components/pages/SolutionBlocksEdit'
import SolutionSelections from './components/pages/SolutionSelections'
import SolutionSelectionsEdit from './components/pages/SolutionSelectionsEdit'
import SolutionFrontPage from './components/pages/SolutionFrontPage'
import SolutionTeaserSettings from './components/pages/SolutionTeaserSettings'
import UtilAliasGenerator from './components/pages/UtilAliasGenerator'

function App () {
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="dropdown"]').dropdown()
  })

  return (
    <Routes>
      <Route exact path="/" element={<PrivateRoute/>}>
        <Route exact path="/" element={<Import/>}/>
        <Route exact path="/jobs" element={<Jobs/>}/>
        <Route exact path="/jobs/import-daemon" element={<JobImportDaemon/>}/>
        <Route exact path="/import" element={<Import/>}/>
        <Route exact path="/import/feeds" element={<ImportFeeds/>}/>
        <Route exact path="/utils/alias-generator" element={<UtilAliasGenerator/>}/>
        <Route path="/import/feeds/:feedId/category-relations" element={<CategoryRelationsSettings/>}/>
        <Route path="/import/feeds/:feedId" element={<ImportFeedSettings/>}/>
        <Route exact path="/catalog" element={<Catalog/>}/>
        <Route exact path="/catalog/categories" element={<CatalogCategories/>}/>
        <Route exact path="/catalog/categories/create" element={<CatalogCategoryCreate/>}/>
        <Route path="/catalog/categories/:categoryId" element={<CatalogCategoryEdit/>}/>
        <Route exact path="/catalog/tags" element={<CatalogTags/>}/>
        <Route exact path="/catalog/tags/create" element={<CatalogTagCreate/>}/>
        <Route path="/catalog/tags/:tagId" element={<CatalogTagEdit/>}/>
        <Route exact path="/catalog/bonuses" element={<CatalogBonuses/>}/>
        <Route path="/catalog/bonus/:bonusId" element={<CatalogBonusEdit/>}/>
        <Route exact path="/catalog/brands" element={<CatalogBrands/>}/>
        <Route path="/catalog/brands/:brandId" element={<CatalogBrandEdit/>}/>
        <Route exact path="/multimedia" element={<Multimedia/>}/>
        <Route exact path="/multimedia/image-styles" element={<ImageStyles/>}/>
        <Route exact path="/multimedia/image-styles/:imageStyleId" element={<ImageStyleEdit/>}/>
        <Route exact path="/solution/:solutionId" element={<Solution/>}/>
        <Route exact path="/solution/:solutionId/menu-main" element={<SolutionMenuMain/>}/>
        <Route exact path="/solution/:solutionId/looks" element={<SolutionLooks/>}/>
        <Route exact path="/solution/:solutionId/looks/:lookId" element={<SolutionLooksEdit/>}/>
        <Route exact path="/solution/:solutionId/blocks" element={<SolutionBlocks/>}/>
        <Route exact path="/solution/:solutionId/blocks/:blockId" element={<SolutionBlocksEdit/>}/>
        <Route exact path="/solution/:solutionId/selections" element={<SolutionSelections/>}/>
        <Route exact path="/solution/:solutionId/selections/:selectionId" element={<SolutionSelectionsEdit/>}/>
        <Route exact path="/solution/:solutionId/articles" element={<SolutionArticles/>}/>
        <Route exact path="/solution/:solutionId/articles/:articleId" element={<SolutionArticlesEdit/>}/>
        <Route exact path="/solution/:solutionId/front-page" element={<SolutionFrontPage/>}/>
        <Route exact path="/solution/:solutionId/teaser-settings" element={<SolutionTeaserSettings/>}/>
        <Route exact path="/init-lamoda-categories" element={<InitLamodaCategories/>}/>
        <Route path="/logout" element={<Logout/>}/>
      </Route>
      <Route path="/" element={<PublicRoute/>}>
        <Route path="/login" element={<Login/>}/>
        <Route path="/forgot" element={<Forgot/>}/>
      </Route>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  )
}

export default App
