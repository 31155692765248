import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet-async'
import { getSelections, getSolution, getSolutionOAuthToken } from '../ClothifyApi'

export default function SolutionSelections () {
  const navigate = useNavigate()
  const { solutionId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [solutionName, setSolutionName] = useState('')
  const [selections, setSelections] = useState([])

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    getSelections(solutionId)
      .then(data => {
        setSelections(data)
      })
  }, [])

  function handleRowClick (data) {
    //   navigate('/multimedia/image-styles/' + String(data._id))
  }

  function handleCreateSelection (data) {
    navigate(`/solution/${solutionId}/selections/create`)
  }

  function handleEditSelection (data) {
    navigate(`/solution/${solutionId}/selections/${data.target.id}`)
  }

  const conditionalRowStyles = [
    {
      when: row => !row.status,
      style: {
        color: 'silver'
      }
    }
  ]

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }
  return (
    <>
      <Helmet>
        <title>Подборки | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/selections`]: 'Подборки' }]}>
        <>
          <h1 className="df-title">Подборки {solutionName}</h1>
          <button type="button" className="btn btn-primary" onClick={handleCreateSelection}>
            Создать подборку
          </button>
          <DataTable
            keyField="_id"
            defaultSortField="name"
            defaultSortAsc={true}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            conditionalRowStyles={conditionalRowStyles}
            columns={[
              {
                name: 'Заголовок',
                selector: (row, i) => row.title,
                sortable: true
              },
              {
                name: 'Пол',
                selector: (row, i) => row.gender,
                sortable: true
              },
              {
                name: 'Создан',
                selector: (row, i) => row.meta.created,
                sortable: true
              },
              {
                name: '',
                cell: function getCell (row) {
                  return <button
                    className="btn btn-link" onClick={handleEditSelection} id={row._id}>Редактировать</button>
                },
                right: true
              }
            ]}
            data={selections}
            onRowClicked={handleRowClick}
          />
        </>
      </SimpleLayout>
    </>
  )
}
