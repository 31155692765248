import { ErrorMessage, Field, Form, Formik } from 'formik'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import { menuGenderOptions } from '../ClothifyApi'
import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import ReactSelect from '../ReactSelect'

import 'react-image-crop/dist/ReactCrop.css'
import { DndBootstrapTable } from '../DndBootstrapTable'
import $ from 'jquery'
import { EditCategoryModal } from '../modals/EditCategoryModal'

export function PopularCategories (params) {
  const block = params.block
  const setBlock = params.setBlock
  const setBlockName = params.setBlockName
  const handleFormSubmit = params.handleFormSubmit

  if (typeof block.params.title === 'undefined') {
    block.params.title = 'Популярные категории'
  }
  if (typeof block.params.categories === 'undefined') {
    block.params.categories = []
  }

  const imgHost = 'https://cdn1.justbutik.ru/'
  const formRef = useRef()
  const [updateCategoriesTable, setUpdateCategoriesTable] = useState(0)
  const [modalData, setModalData] = useState({})

  const validateFormSchema = yup.object().shape({
    name: yup.string().required('Это поле обязательно для заполнения.')
  })

  const onBlockNameChange = (e) => {
    setBlockName(formRef.current.values.name)
  }

  const imgCell = (tableProps) => {
    if (typeof tableProps.cell.value !== 'undefined' && tableProps.cell.value !== '') {
      return <img src={imgHost + tableProps.cell.value} alt="Preview" width={100}/>
    } else {
      return ''
    }
  }

  const dndColumns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'id'
      },
      {
        Header: '',
        accessor: 'image',
        Cell: imgCell
      },
      {
        Header: 'Название',
        accessor: 'name'
      }
    ],
    []
  )

  useEffect(() => {
    forceUpdateCategoriesTable()
  }, [block.params.categories])

  const forceUpdateCategoriesTable = () => {
    const newUpdateTable = updateCategoriesTable + 1
    setUpdateCategoriesTable(newUpdateTable)
  }

  const onDeleteCategory = (categoriesData) => {
    const blockData = JSON.parse(JSON.stringify(formRef.current.values))
    blockData.params.categories = categoriesData
    setBlock(blockData)
  }

  const onMoveCategory = (categoriesData) => {
    const blockData = JSON.parse(JSON.stringify(formRef.current.values))
    if (typeof categoriesData !== 'undefined' && categoriesData.length > 0) {
      let newId = 0
      categoriesData.forEach(item => {
        newId++
        item.id = newId
      })
    }
    blockData.params.categories = categoriesData
    setBlock(blockData)
  }

  const onEditCategory = (categoryData) => {
    setModalData(categoryData)
    $('#editCategoryModal').modal('show')
  }

  const onSaveCategory = (params) => {
    const blockData = JSON.parse(JSON.stringify(formRef.current.values))
    if (params.id !== '') {
      if (typeof block.params.categories !== 'undefined' && block.params.categories.length > 0) {
        block.params.categories.forEach((item, i) => {
          if (params.id === item.id) {
            blockData.params.categories[i] = params
          }
        })
      }
    } else {
      params.id = 1
      if (typeof block.params.categories !== 'undefined' && block.params.categories.length > 0) {
        block.params.categories.forEach(item => {
          if (params.id <= item.id) {
            params.id = item.id + 1
          }
        })
      }
      blockData.params.categories.push(params)
    }
    setBlock(blockData)
    setModalData({})
  }

  const handleAddCategory = () => {
    $('#editCategoryModal').modal('show')
  }

  return (<>
    <Formik
      enableReinitialize={true}
      initialValues={block}
      validationSchema={validateFormSchema}
      onSubmit={handleFormSubmit}
      innerRef={formRef}
    >
      {({ isSubmitting, values }) => (<Form>
        <div className="row row-xs">
          <div className="col-lg-12">
            <div className="row row-xs">
              <div className="col-12">
                <fieldset className="form-fieldset">
                  <legend>Редактирование блока</legend>
                  <Field name="type" type="hidden"/>
                  <div className="custom-control custom-switch mg-b-20">
                    <Field
                      name="status" type="checkbox"
                      className="custom-control-input" id="status"/>
                    <label
                      className="d-block custom-control-label"
                      htmlFor="status">
                      Блок опубликован
                    </label>
                    <ErrorMessage name="status" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="gender">
                      Пол
                    </label>
                    <ReactSelect
                      name="gender" placeholder="Пол"
                      value={menuGenderOptions.filter(option => option.value === values.gender)[0]}
                      options={menuGenderOptions} isClearable={true} isDisabled={false}/>
                    <ErrorMessage name="gender" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="name">Название блока</label>
                    <Field
                      name="name" type="text" className="form-control"
                      placeholder="Название блока" onKeyUp={onBlockNameChange}/>
                    <ErrorMessage name="name" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="params.title">Заголовок</label>
                    <Field name="params.title" type="text" className="form-control" placeholder="Заголовок"/>
                    <ErrorMessage name="params.title" component={FormFieldError}/>
                  </div>
                  <button type="button" className="btn btn-primary mt-3 mb-3" onClick={handleAddCategory}>
                    Добавить категорию
                  </button>
                  <DndBootstrapTable
                    key={updateCategoriesTable} columns={dndColumns} data={block.params.categories}
                    onDeleteItem={onDeleteCategory} onEditItem={onEditCategory} onMoveItem={onMoveCategory}/>
                </fieldset>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>Сохранить</button>
        </div>
      </Form>)}
    </Formik>
    <EditCategoryModal data={modalData} onSaveModal={onSaveCategory}/>
  </>)
}
