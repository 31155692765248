import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext } from 'formik'
import * as yup from 'yup'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { AlertCircle, X as XIcon } from 'react-feather'
import {
  categoryGenderOptions,
  emptyCategory,
  createCategory,
  emptyTagOption,
  filterPseudoCategoryOptions,
  clothifySlugify, getMorpherDeclensions, prepositions, emptyCategoryOption, filterCatalogCategoryOptions
} from '../ClothifyApi'
import AsyncSelect from 'react-select/async'
import ReactSelect from '../ReactSelect'

export default function CatalogCategoryCreate () {
  const navigate = useNavigate()
  const initialMessage = ''
  const [message, setMessage] = useState(initialMessage)
  const getParams = new URLSearchParams(location.search)
  const destination = getParams.get('destination')
  const parentCategory = getParams.get('parentCategory')
  const [category, setCategory] = useState(emptyCategory)
  let morpherTimeoutSingle = null
  const morpherTimeoutName = { any: null, women: null, men: null }
  const [categoryName, setCategoryName] = useState('')

  useEffect(() => {
    if (!token) {
      return
    }
    if (parentCategory !== null && parentCategory !== '') {
      const categoryCopy = JSON.parse(JSON.stringify(category))
      categoryCopy.parent = parentCategory
      categoryCopy.path = ''
      setCategory(categoryCopy)
    }
  }, [parentCategory])

  const validateFormSchema = yup.object().shape({
    name: yup.object().shape({
      any: yup.string()
        .required('Это поле обязательно к заполнению.')
    }),
    word_cases: yup.object().shape({
      any: yup.string()
        .required('Это поле обязательно к заполнению.')
    }),
    slug: yup.string()
      .required('Это поле обязательно к заполнению.')
  })

  const initialFormValues = {
    gender: category.gender || [],
    name: category.name || { any: '', women: '', men: '' },
    facet_name: category.facet_name || { any: '', women: '', men: '' },
    slug: category.slug || '',
    enabled: category.enabled || false,
    path: category.path || '',
    additional_parents: category.additional_parents || [],
    additionalParentSelect: emptyCategory,
    sort_weight: category.sort_weight || 0,
    facet_weight: category.facet_weight || 0,
    word_single_form: category.word_single_form || '',
    word_forms: category.word_forms ? category.word_forms.join('|') : '',
    word_cases: category.word_cases ? {
      any: JSON.stringify(category.word_cases.any),
      women: JSON.stringify(category.word_cases.women),
      men: JSON.stringify(category.word_cases.men)
    } : {
      any: '{}',
      women: '{}',
      men: '{}'
    },
    category_corrector_patterns: category.category_corrector_patterns || '',
    redirectDisabled: category.redirectDisabled || { any: '', women: '', men: '' },
    redirectDisabledSelect: category.redirectDisabledSelect || {
      any: { name: '', label: '' },
      women: { name: '', label: '' },
      men: { name: '', label: '' }
    },
    run_corrector: category.run_corrector || false,
    straight_import: category.straight_import || false
  }
  if (typeof category.parent !== 'undefined') {
    initialFormValues.parent = category.parent
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      const postData = JSON.parse(JSON.stringify((values)))
      if (postData.word_forms.trim() !== '') {
        postData.word_forms = postData.word_forms.trim().split('|')
      } else {
        postData.word_forms = []
      }
      for (const gender of ['any', 'women', 'men']) {
        if (postData.word_cases[gender].trim() !== '') {
          postData.word_cases[gender] = JSON.parse(postData.word_cases[gender].trim())
        } else {
          postData.word_cases[gender] = {}
        }
      }
      const params = Object.assign({}, category, postData)
      params.redirectDisabled = { any: '', women: '', men: '' }
      for (const gender of ['any', 'women', 'men']) {
        params.redirectDisabled[gender] = postData.redirectDisabledSelect[gender].value
      }
      delete params.redirectDisabledSelect
      delete params.additionalParentSelect

      const additionalParents = []
      params.additional_parents.forEach(additionalParent => {
        const m = additionalParent.match('^.* \\((.*)\\)$')
        additionalParents.push(m[1])
      })
      params.additional_parents = additionalParents
      createCategory(params)
        .then(response => {
          setMessage('Категория успешно сохранена!')
          const redirect = destination !== null ? destination : '/catalog/categories/' + response.data._id.toString() + '?created=1'
          setTimeout(navigate(redirect), 1000)
        })
        .catch(error => {
          const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
          setStatus(errMsg)
          setSubmitting(false)
        })
    }, 500)
  }

  const onRedirectDisabledChange = (option, setFieldValue, gender) => {
    if (option.value.trim() === '') {
      setFieldValue('redirectDisabled[' + gender + ']', '')
    } else {
      setFieldValue('redirectDisabled[' + gender + ']', option.label + ' (' + option.value + ')')
    }
    setFieldValue('redirectDisabledSelect[' + gender + ']', option)
  }

  const generateMachineName = (croppedTag) => {
    return clothifySlugify(croppedTag)
  }

  const getMorpherData = (text) => {
    return getMorpherDeclensions(text)
  }

  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext()
    useEffect(() => {
      setCategoryName(values.name.any)
      if (morpherTimeoutName.any) {
        return () => clearTimeout(morpherTimeoutName.any)
      }
      if (values.name.any !== '') {
        const slug = generateMachineName(values.name.any)
        setFieldValue('slug', slug)
        morpherTimeoutName.any = setTimeout(function () {
          getMorpherData(values.name.any).then(morpherData => {
            setFieldValue('word_cases[any]', JSON.stringify(morpherData))
          })
        }, 2000)
      }
    }, [values.name.any])

    useEffect(() => {
      if (morpherTimeoutName.women) {
        return () => clearTimeout(morpherTimeoutName.women)
      }
      if (values.name.women !== '') {
        const slug = generateMachineName(values.name.women)
        setFieldValue('slug', slug)
        morpherTimeoutName.women = setTimeout(function () {
          getMorpherData(values.name.women).then(morpherData => {
            setFieldValue('word_cases[women]', JSON.stringify(morpherData))
          })
        }, 2000)
      }
    }, [values.name.women])

    useEffect(() => {
      if (morpherTimeoutName.men) {
        return () => clearTimeout(morpherTimeoutName.men)
      }
      if (values.name.men !== '') {
        const slug = generateMachineName(values.name.men)
        setFieldValue('slug', slug)
        morpherTimeoutName.men = setTimeout(function () {
          getMorpherData(values.name.men).then(morpherData => {
            setFieldValue('word_cases[men]', JSON.stringify(morpherData))
          })
        }, 2000)
      }
    }, [values.name.men])

    useEffect(() => {
      clearTimeout(morpherTimeoutSingle)
      if (values.word_single_form !== '') {
        morpherTimeoutSingle = setTimeout(function () {
          const baseWords = values.word_single_form.trim().toLowerCase().split(' ')
          const promises = []
          const words = []
          baseWords.forEach(baseWord => {
            const splittedBaseWords = baseWord.split(',')
            splittedBaseWords.forEach(splittedBaseWord => {
              if (!prepositions.includes(splittedBaseWord) && splittedBaseWord !== '') {
                words.push(splittedBaseWord)
                promises.push(getMorpherData(splittedBaseWord))
              }
            })
          })
          Promise.all(promises)
            .then(results => {
              results.forEach(morpherData => {
                Object.keys(morpherData).forEach(key => {
                  if (Object.prototype.hasOwnProperty.call(morpherData, key)) {
                    if (key === 'множественное') {
                      Object.keys(morpherData['множественное']).forEach(mkey => {
                        const splitWords = morpherData['множественное'][mkey].split(' ')
                        splitWords.forEach(splitWord => {
                          if (!prepositions.includes(splitWord) && !words.includes(splitWord)) {
                            words.push(splitWord)
                          }
                        })
                      })
                    } else if (['Р', 'Д', 'В', 'Т', 'П'].includes(key)) {
                      const splitWords = morpherData[key].split(' ')
                      splitWords.forEach(splitWord => {
                        if (!prepositions.includes(splitWord) && !words.includes(splitWord)) {
                          words.push(splitWord)
                        }
                      })
                    }
                  }
                })
                setFieldValue('word_forms', words.join('|'))
              })
            })
        }, 2000)
      }
    }, [values.word_single_form])

    return null
  }

  const onCategoryChange = (option, setFieldValue, values) => {
    if (option.value.trim() !== '') {
      const additionalParents = JSON.parse(JSON.stringify(values.additional_parents))
      additionalParents.push(option.label + ' (' + option.value + ')')
      setFieldValue('additional_parents', additionalParents)
    }
    setFieldValue('additionalParentSelect', emptyCategory)
  }

  const removeAdditionalParent = (index, setFieldValue, values) => {
    const additionalParents = JSON.parse(JSON.stringify(values.additional_parents))
    additionalParents.splice(index, 1)
    setFieldValue('additional_parents', additionalParents)
  }

  return (<>
    <Helmet>
      <title>{'Настройка категории "' + categoryName + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог' }, { '/catalog/categories': 'Дерево категорий' }]}>
      <>
        <h1 className="df-title">{'Настройка категории "' + categoryName + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}>
          {({ status, isSubmitting, setFieldValue, values }) => (<Form>
            {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
              <AlertCircle className="col-sm mg-r-10"/> {status}</div>}
            {message && <div className="row alert alert-success d-flex align-items-center" role="alert">{message}</div>}
            <div className="row row-xs">
              <div className="col-12">
                <fieldset className="form-fieldset">
                  <div className="custom-control custom-switch mg-b-20">
                    <Field
                      name="enabled" type="checkbox"
                      className="custom-control-input" id="enabled"/>
                    <label
                      className="d-block custom-control-label"
                      htmlFor="enabled">
                      Категория включена
                    </label>
                    <ErrorMessage name="enabled" component={FormFieldError}/>
                  </div>
                  <div className="custom-control custom-switch mg-b-20">
                    <Field
                      name="straight_import" type="checkbox"
                      className="custom-control-input" id="straight_import"/>
                    <label
                      className="d-block custom-control-label"
                      htmlFor="straight_import">
                      Импортировать товары без корректировщика
                    </label>
                    <ErrorMessage name="straight_import" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="gender">
                      Пол
                    </label>
                    <ReactSelect
                      name="gender" placeholder="Пол для которого будет отображаться категория"
                      isMulti
                      value={categoryGenderOptions.filter(option => values.gender.includes(option.value))}
                      options={categoryGenderOptions} isClearable={false} isDisabled={false}/>
                    <ErrorMessage name="gender" component={FormFieldError}/>
                  </div>
                  <div className="row">
                    <div className="form-group col-4">
                      <label className="d-block mt-3 mb-3 font-weight-bold">
                        По умолчанию
                      </label>
                      <label className="d-block" htmlFor="name[any]">
                        Название категории
                      </label>
                      <Field
                        name="name[any]" type="text" className="form-control"
                        placeholder="Укажите название категории"/>
                      <ErrorMessage name="name[any]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="facet_name[any]">
                        Название для фасетов
                      </label>
                      <Field
                        name="facet_name[any]" type="text" className="form-control"
                        placeholder="Укажите название категории для блока фасетов"/>
                      <ErrorMessage name="facet_name[any]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="word_cases[any]">
                        Падежи
                      </label>
                      <Field
                        name="word_cases[any]" type="textarea" className="form-control"
                        placeholder="Падежи"/>
                      <ErrorMessage name="word_cases[any]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="parentTag">
                        Редирект, если категория отключена
                      </label>
                      <Field
                        name="redirectDisabled[any]" type="text" className="form-control"
                        placeholder="Редирект на псевдокатегорию" disabled={true}/>
                      <ErrorMessage name="redirectDisabled[any]" component={FormFieldError}/>
                      <AsyncSelect
                        defaultOptions={category.redirectDisabledSelect.any.value === '' ? [emptyTagOption] : [emptyTagOption, category.redirectDisabledSelect.any]}
                        loadOptions={filterPseudoCategoryOptions}
                        onChange={value => onRedirectDisabledChange(value, setFieldValue, 'any')}
                        defaultValue={category.redirectDisabledSelect.any}
                        isDisabled={false}
                      />
                    </div>

                    <div className="form-group col-4">
                      <label className="d-block mt-3 mb-3 font-weight-bold">
                        Женщинам
                      </label>
                      <label className="d-block" htmlFor="name[women]">
                        Название категории
                      </label>
                      <Field
                        name="name[women]" type="text" className="form-control"
                        placeholder="Укажите название категории"/>
                      <ErrorMessage name="name[women]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="facet_name[women]">
                        Название для фасетов
                      </label>
                      <Field
                        name="facet_name[women]" type="text" className="form-control"
                        placeholder="Укажите название категории для блока фасетов"/>
                      <ErrorMessage name="facet_name[women]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="word_cases[women]">
                        Падежи
                      </label>
                      <Field
                        name="word_cases[women]" type="textarea" className="form-control"
                        placeholder="Падежи"/>
                      <ErrorMessage name="word_cases[women]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="parentTag">
                        Редирект, если категория отключена
                      </label>
                      <Field
                        name="redirectDisabled[women]" type="text" className="form-control"
                        placeholder="Редирект на псевдокатегорию" disabled={true}/>
                      <ErrorMessage name="redirectDisabled[women]" component={FormFieldError}/>
                      <AsyncSelect
                        defaultOptions={category.redirectDisabledSelect.women.value === '' ? [emptyTagOption] : [emptyTagOption, category.redirectDisabledSelect.women]}
                        loadOptions={filterPseudoCategoryOptions}
                        onChange={value => onRedirectDisabledChange(value, setFieldValue, 'women')}
                        defaultValue={category.redirectDisabledSelect.women}
                        isDisabled={false}
                      />
                    </div>

                    <div className="form-group col-4">
                      <label className="d-block mt-3 mb-3 font-weight-bold">
                        Мужчинам
                      </label>
                      <label className="d-block" htmlFor="name[men]">
                        Название категории
                      </label>
                      <Field
                        name="name[men]" type="text" className="form-control"
                        placeholder="Укажите название категории"/>
                      <ErrorMessage name="name[men]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="facet_name[men]">
                        Название для фасетов
                      </label>
                      <Field
                        name="facet_name[men]" type="text" className="form-control"
                        placeholder="Укажите название категории для блока фасетов"/>
                      <ErrorMessage name="facet_name[men]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="word_cases[men]">
                        Падежи
                      </label>
                      <Field
                        name="word_cases[men]" type="textarea" className="form-control"
                        placeholder="Падежи"/>
                      <ErrorMessage name="word_cases[men]" component={FormFieldError}/>
                      <label className="d-block mt-3" htmlFor="parentTag">
                        Редирект, если категория отключена
                      </label>
                      <Field
                        name="redirectDisabled[men]" type="text" className="form-control"
                        placeholder="Редирект на псевдокатегорию" disabled={true}/>
                      <ErrorMessage name="redirectDisabled[men]" component={FormFieldError}/>
                      <AsyncSelect
                        defaultOptions={category.redirectDisabledSelect.men.value === '' ? [emptyTagOption] : [emptyTagOption, category.redirectDisabledSelect.men]}
                        loadOptions={filterPseudoCategoryOptions}
                        onChange={value => onRedirectDisabledChange(value, setFieldValue, 'men')}
                        defaultValue={category.redirectDisabledSelect.men}
                        isDisabled={false}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="slug">
                      Алиас
                    </label>
                    <Field
                      name="slug" type="text" className="form-control"
                      placeholder="Укажите алиас категории"/>
                    <ErrorMessage name="slug" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="category_corrector_patterns">
                      Паттерны корректировщика
                    </label>
                    <Field
                      name="category_corrector_patterns" component="textarea" className="form-control"
                      placeholder="Паттерны корректировщика"/>
                    <ErrorMessage name="category_corrector_patterns" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="word_single_form">
                      Единственное число
                    </label>
                    <Field
                      name="word_single_form" type="text" className="form-control"
                      placeholder="Название категории в единственном числе"/>
                    <ErrorMessage name="word_single_form" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="word_forms">
                      Формы слов
                    </label>
                    <Field
                      name="word_forms" type="text" className="form-control"
                      placeholder="Формы слов"/>
                    <ErrorMessage name="word_forms" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="facet_weight">
                      Вес в фасете
                    </label>
                    <Field
                      name="facet_weight" type="text" className="form-control"
                      placeholder="Вес в фасете"/>
                    <ErrorMessage name="facet_weight" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="additional_parents">
                      Дополнительные родительские категории
                    </label>
                    <FieldArray
                      name="category[additional_parents]"
                      render={() => (
                        <div>
                          {values.additional_parents.map((additionalParent, index) => (
                            <div key={index} className="row mt-1">
                              <div className="col-6">{additionalParent}</div>
                              <div className="col-1">
                                <button
                                  type="button" className="btn btn-xs btn-danger btn-icon ml-3"
                                  title="Удалить"
                                  onClick={() => {
                                    removeAdditionalParent(index, setFieldValue, values)
                                  }}
                                >
                                  <XIcon/>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <AsyncSelect
                      defaultOptions={[emptyCategoryOption]}
                      loadOptions={filterCatalogCategoryOptions}
                      onChange={value => onCategoryChange(value, setFieldValue, values)}
                      isDisabled={false}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-switch mg-b-20">
                      <Field
                        name="run_corrector" type="checkbox"
                        className="custom-control-input" id="run_corrector"/>
                      <label
                        className="d-block custom-control-label"
                        htmlFor="run_corrector">
                        Перереспределить товары по категориям
                      </label>
                      <ErrorMessage name="run_corrector" component={FormFieldError}/>
                    </div>
                  </div>
                </fieldset>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
            <FormObserver/>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
