import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import * as yup from 'yup'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import {
  emptyTag,
  emptyCategoryOption,
  emptyTagOption,
  tagGenderOptions,
  cnsOptions,
  getBrandOptionByValue,
  getCatalogPage,
  tagUkeyExists,
  getTagUkey,
  clothifySlugify,
  getCatalogCategoryOptionByValue,
  getCatalogCategoryById,
  filterParentTagOptions,
  filterBrandOptions,
  getParentTagOptionByValue,
  filterCatalogCategoryOptions,
  createCatalogTags
} from '../ClothifyApi'

import AsyncSelect from 'react-select/async'
import ReactSelect from '../ReactSelect'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

export default function CatalogTagCreate () {
  const navigate = useNavigate()
  const categories = {}
  const getParams = new URLSearchParams(location.search)
  const destination = getParams.get('destination')
  const categoryId = getParams.get('categoryId')
  const pseudoCategory = getParams.get('pseudoCategory')
  const [tag, setTag] = useState(emptyTag)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (!token) {
      return
    }
    getCatalogCategoryOptionByValue(categoryId).then(categoryOption => {
      const tagCopy = JSON.parse(JSON.stringify(tag))
      tagCopy.categorySelect = categoryOption
      if (categoryOption.value !== '') {
        tagCopy.category = categoryOption.label + ' (' + categoryOption.value + ')'
      }
      setTag(tagCopy)
    })
  }, [categoryId])

  const validateFormSchema = yup.object().shape({
    category: yup.string().required('Выберите категорию'),
    tag: yup.string().required('Заполните поле'),
    croppedTag: yup.string().when('brandSelect', {
      is: (brandSelect) => {
        return (typeof brandSelect === 'undefined' || typeof brandSelect.label === 'undefined' || brandSelect.label === '')
      },
      then: yup.string().required('Заполните поле'),
      otherwise: yup.string().required().oneOf([yup.ref('brandSelect.label')], 'Для тегов-брендов поле должно быть равно названию бренда')
    }),
    tagPageTitle: yup.string().when('brand', {
      is: (brand) => (brand === '' || typeof brand === 'undefined'),
      then: yup.string().required('Заполните поле'),
      otherwise: yup.string().max(0, 'Для тегов-брендов поле должно быть пустым')
    }),
    tagPageTitleGenitive: yup.string().when('brand', {
      is: (brand) => (brand === '' || typeof brand === 'undefined'),
      then: yup.string().required('Заполните поле'),
      otherwise: yup.string().max(0, 'Для тегов-брендов поле должно быть пустым')
    }),
    tagPageTitleAccusative: yup.string().when('brand', {
      is: (brand) => (brand === '' || typeof brand === 'undefined'),
      then: yup.string().required('Заполните поле'),
      otherwise: yup.string().max(0, 'Для тегов-брендов поле должно быть пустым')
    }),
    qry: yup.string().when('brand', {
      is: (brand) => (brand === '' || typeof brand === 'undefined'),
      then: yup.string().required('Заполните поле'),
      otherwise: yup.string().max(0, 'Для тегов-брендов поле должно быть пустым')
    })
  })

  const initialFormValues = {
    tag: tag.tag || '',
    category: tag.category || '',
    categorySelect: tag.categorySelect || emptyCategoryOption,
    croppedTag: tag.croppedTag || '',
    tagPageTitle: tag.tagPageTitle || '',
    tagPageTitleGenitive: tag.tagPageTitleGenitive || '',
    tagPageTitleAccusative: tag.tagPageTitleAccusative || '',
    gender: tag.gender || '',
    machineName: tag.machineName || '',
    tagUrl: tag.tagUrl || '',
    weight: tag.weight || 0,
    qty: tag.qty || 0,
    qry: tag.qry || '',
    status: tag.status || 0,
    linksData: tag.linksData || [],
    cns: tag.cns || '',
    brand: tag.brand || '',
    brandSelect: tag.brandSelect || { value: '', label: ' ' },
    tagUkey: tag.tagUkey || '',
    plural: tag.plural || 0,
    contentEntranceQty: tag.contentEntranceQty || 0,
    redirectUrl: tag.redirectUrl || '',
    parentTag: tag.parentTag || '',
    parentTagSelect: tag.parentTagSelect || { name: '', label: '' },
    pseudoCategory: tag.pseudoCategory || pseudoCategory === '1' || false
  }

  const onParentTagChange = (option, setFieldValue) => {
    if (option.value.trim() === '') {
      setFieldValue('parentTag', '')
    } else {
      setFieldValue('parentTag', option.label + ' (' + option.value + ')')
    }
    setFieldValue('parentTagSelect', option)
  }

  const onBrandChange = (option, setFieldValue) => {
    if (option.value.trim() === '') {
      setFieldValue('brand', '')
    } else {
      setFieldValue('brand', option.label + ' (' + option.value + ')')
    }
    setFieldValue('brandSelect', option)
  }

  const onCategoryChange = (option, setFieldValue) => {
    if (option.value.trim() === '') {
      setFieldValue('category', '')
    } else {
      setFieldValue('category', option.label + ' (' + option.value + ')')
    }
    setFieldValue('categorySelect', option)
  }

  const generateMachineName = (croppedTag) => {
    return clothifySlugify(croppedTag)
  }

  const getCategorySlug = (categoryId) => {
    return new Promise(resolve => {
      if (categoryId === '') {
        resolve('')
      } else if (typeof categories[categoryId] !== 'undefined') {
        resolve(categories[categoryId].slug)
      } else {
        getCatalogCategoryById(categoryId).then(category => {
          categories[category._id.toString()] = category
          resolve(category.slug)
        })
      }
    })
  }

  const generateTagUrl = (tagUrlParts) => {
    const parts = []
    if (tagUrlParts.gender !== '') {
      parts.push(tagUrlParts.gender)
    }
    if (tagUrlParts.categorySlug !== '') {
      parts.push(tagUrlParts.categorySlug)
    }
    if (
      (
        typeof tagUrlParts.brand === 'undefined' ||
        tagUrlParts.brand === ''
      ) &&
      tagUrlParts.machineName !== ''
    ) {
      parts.push(tagUrlParts.machineName)
    }
    if (tagUrlParts.cns !== '') {
      parts.push(tagUrlParts.cns)
    }
    let tagUrl = parts.join('-')
    if (typeof tagUrlParts.brand !== 'undefined') {
      tagUrl += '/' + tagUrlParts.brand
    }
    return tagUrl
  }

  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext()
    useEffect(() => {
      const data = {
        gender: values.gender,
        category: values.categorySelect.value,
        machineName: generateMachineName(values.croppedTag),
        croppedTag: values.croppedTag,
        cns: values.cns
      }
      setFieldValue('machineName', data.machineName)
      getCategorySlug(values.categorySelect.value).then(categorySlug => {
        data.categorySlug = categorySlug
        if (typeof values.brandSelect !== 'undefined' && values.brandSelect.value !== '') {
          data.brand = values.brandSelect.value
        }
        setFieldValue('tagUrl', generateTagUrl(data))
      })
      if (
        typeof values.brand !== 'undefined' &&
        values.brand !== '' &&
        typeof values.croppedTag !== 'undefined'
      ) {
        setFieldValue('tag', values.croppedTag)
      }
    }, [values])
    return null
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting, setFieldValue }) => {
    setSubmitting(true)
    Store.addNotification({
      title: '',
      message: 'Проверяем тег',
      type: 'warning',
      insert: 'top',
      container: 'center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
    const data = {
      gender: values.gender,
      category: values.categorySelect.value,
      croppedTag: values.croppedTag,
      cns: values.cns
    }
    getTagUkey(data).then(tagUkeyData => {
      const tagUkey = tagUkeyData.tagUkey
      const genderGroupUkey = tagUkeyData.genderGroupUkey
      tagUkeyExists(tagUkey)
        .then(foundTag => {
          if (foundTag === null) {
            setFieldValue('tagUkey', tagUkey)
            if (typeof values.weight === 'string' && values.weight.trim() !== '') {
              values.weight = parseInt(values.weight.trim())
            }
            if (typeof values.status === 'string' && values.status.trim() !== '') {
              values.status = parseInt(values.status.trim())
            }
            const tagData = JSON.parse(JSON.stringify(tag))
            const postParams = Object.assign({}, tagData, values)
            postParams.category = values.categorySelect.value
            postParams.parentTag = values.parentTagSelect.value
            postParams.brand = values.brandSelect.value
            postParams.tagUkey = tagUkey
            postParams.genderGroupUkey = genderGroupUkey
            delete postParams.categorySelect
            delete postParams.parentTagSelect
            delete postParams.brandSelect
            if (typeof postParams._id !== 'undefined') {
              delete postParams._id
            }
            getCatalogPage(postParams).then(pageData => {
              postParams.status = pageData.data.count > 0 ? 1 : 0
              setFieldValue('status', postParams.status)
              if (postParams.status === 1) {
                postParams.qty = pageData.data.count
                Store.addNotification({
                  title: '',
                  message: 'Найдено ' + pageData.data.count + ' товар(ов)',
                  type: 'success',
                  insert: 'top',
                  container: 'center',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 5000,
                    onScreen: true
                  }
                })
                createCatalogTags([postParams])
                  .then(response => {
                    const promises = [
                      getCatalogCategoryOptionByValue(response.data.category),
                      getParentTagOptionByValue(response.data.parentTag),
                      getBrandOptionByValue(response.data.brand)
                    ]
                    Promise.all(promises).then(responses => {
                      response.data.categorySelect = responses[0]
                      response.data.parentTagSelect = responses[1]
                      response.data.brandSelect = responses[2]
                      if (responses[0].value !== '') {
                        response.data.category = responses[0].label + ' (' + responses[0].value + ')'
                      }
                      if (responses[1].value !== '') {
                        response.data.parentTag = responses[1].label + ' (' + responses[1].value + ')'
                      }
                      setTag(response.data)
                      setMessage('Тег успешно сохранен!')

                      const redirect = destination !== null ? destination : '/catalog/tags/' + response.data[tagUkey]._id.toString() + '?created=1'
                      setTimeout(navigate(redirect), 1000)
                    })
                  })
                  .catch(error => {
                    const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
                    setStatus(errMsg)
                    setSubmitting(false)
                  })
              } else {
                Store.addNotification({
                  title: 'Внимание',
                  message: 'В каталоге нет товаров для тега',
                  type: 'warning',
                  insert: 'top',
                  container: 'center',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut']
                })
                setSubmitting(false)
              }
            })
          } else {
            Store.addNotification({
              title: 'Внимание',
              message: <>Тег с таким
                <a rel="noreferrer" target="_blank" href={'/catalog/tags/' + foundTag._id.toString()}>ukey</a> уже
                существует в БД</>,
              type: 'warning',
              insert: 'top',
              container: 'center',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            })
            setSubmitting(false)
          }
        })
    })
  }

  return (<>
    <Helmet>
      <title>{'Создать тег | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог' }, { '/catalog/tags': 'Теги каталога' }, { ['/catalog/tags/create/' + categoryId]: 'Создать тег' }]}>
      <>
        <ReactNotifications/>
        <h1 className="df-title">{'Создать тег'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}>
          {({ status, isSubmitting, setFieldValue, isValid, handleSubmit, values }) => (
            <Form onSubmit={e => {
              handleSubmit(e)
              if (!isValid) {
                setTimeout(() => {
                  const rect = document.querySelector('.parsley-errors-list').getBoundingClientRect()
                  const currentScrollY = document.documentElement.scrollTop || document.body.scrollTop
                  window.scrollTo(0, rect.top + currentScrollY - 150)
                }, 100)
              }
            }}>
              {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
                <AlertCircle className="col-sm mg-r-10"/>{status}</div>}
              {message &&
              <div className="row alert alert-success d-flex align-items-center" role="alert">{message}</div>}
              <div className="row row-xs">
                <div className="col-12">
                  <fieldset className="form-fieldset">
                    <div className="form-group">
                      <div className="custom-control custom-switch mg-b-20">
                        <Field
                          name="pseudoCategory" type="checkbox"
                          className="custom-control-input" id="pseudoCategory"/>
                        <label
                          className="d-block custom-control-label"
                          htmlFor="pseudoCategory">
                          Псевдо категория
                        </label>
                        <ErrorMessage name="pseudoCategory" component={FormFieldError}/>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="tag">
                        Тег
                      </label>
                      <Field
                        name="tag" type="text" className="form-control"
                        placeholder="Укажите название тега" disabled={false}/>
                      <ErrorMessage name="tag" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="croppedTag">
                        Cropped tag
                      </label>
                      <Field
                        name="croppedTag" type="text" className="form-control"
                        placeholder="Cropped tag" disabled={false}/>
                      <ErrorMessage name="croppedTag" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="category">
                        Категория
                      </label>
                      <Field
                        name="category" type="text" className="form-control"
                        placeholder="Категория каталога" disabled={true}/>
                      <ErrorMessage name="category" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <AsyncSelect
                        defaultOptions={tag.categorySelect.value === '' ? [emptyCategoryOption] : [emptyCategoryOption, tag.categorySelect]}
                        loadOptions={filterCatalogCategoryOptions}
                        onChange={value => onCategoryChange(value, setFieldValue)}
                        defaultValue={tag.categorySelect}
                        isDisabled={false}
                      />
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="tagPageTitle">
                        Заголовок страницы тега (именительный падеж)
                      </label>
                      <Field
                        name="tagPageTitle" type="text" className="form-control"
                        placeholder="Заголовок страницы тега(ИП)"/>
                      <ErrorMessage name="tagPageTitle" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="tagPageTitleGenitive">
                        Заголовок страницы тега (родительный падеж)
                      </label>
                      <Field
                        name="tagPageTitleGenitive" type="text" className="form-control"
                        placeholder="Заголовок страницы тега(РП)"/>
                      <ErrorMessage name="tagPageTitleGenitive" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="tagPageTitleAccusative">
                        Заголовок страницы тега (винительный падеж)
                      </label>
                      <Field
                        name="tagPageTitleAccusative" type="text" className="form-control"
                        placeholder="Заголовок страницы тега(ВП)"/>
                      <ErrorMessage name="tagPageTitleAccusative" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="gender">
                        Пол
                      </label>
                      <ReactSelect
                        name="gender" placeholder="Выберите пол тега"
                        value={tagGenderOptions.filter(option => option.value === values.gender)[0]}
                        options={tagGenderOptions} isClearable={false}/>
                      <ErrorMessage name="gender" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="machineName">
                        Machine name
                      </label>
                      <Field
                        name="machineName" type="text" className="form-control"
                        placeholder="Укажите machineName тега" disabled={true}/>
                      <ErrorMessage name="machineName" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="tagUrl">
                        URL тега
                      </label>
                      <Field
                        name="tagUrl" type="text" className="form-control"
                        placeholder="Укажите URL тега" disabled={true}/>
                      <ErrorMessage name="tagUrl" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="weight">
                        Вес тега
                      </label>
                      <Field
                        name="weight" type="text" className="form-control"
                        placeholder="Укажите вес тега"/>
                      <ErrorMessage name="weight" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="qty">
                        Количество товаров каталога тега
                      </label>
                      <Field
                        name="qty" type="text" className="form-control"
                        placeholder="Количество товаров каталога тега" disabled={true}/>
                      <ErrorMessage name="qty" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="qry">
                        Поисковая строка тега
                      </label>
                      <Field
                        name="qry" type="text" className="form-control"
                        placeholder="Поисковая строка тега"/>
                      <ErrorMessage name="qry" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="status">
                        Status
                      </label>
                      <Field
                        name="status" type="text" className="form-control"
                        placeholder="Tag status" disabled={true}/>
                      <ErrorMessage name="status" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="cns">
                        Cheap/new/sale
                      </label>
                      <ReactSelect name="cns" options={cnsOptions} isClearable={true}/>
                      <ErrorMessage name="cns" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="parentTag">
                        Бренд
                      </label>
                      <Field
                        name="brand" type="text" className="form-control"
                        placeholder="Бренд" disabled={true}/>
                      <ErrorMessage name="brand" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <AsyncSelect
                        defaultOptions={[{ value: '', label: '' }, tag.brandSelect]}
                        loadOptions={filterBrandOptions}
                        onChange={value => onBrandChange(value, setFieldValue)}
                        defaultValue={tag.brandSelect}
                        isDisabled={false}
                      />
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="tagUkey">
                        Tag UKEY
                      </label>
                      <Field
                        name="tagUkey" type="text" className="form-control"
                        placeholder="Tag UKEY" disabled={true}/>
                      <ErrorMessage name="tagUkey" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="redirectUrl">
                        Redirect URL
                      </label>
                      <Field
                        name="redirectUrl" type="text" className="form-control"
                        placeholder="Redirect URL"/>
                      <ErrorMessage name="redirectUrl" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <label className="d-block" htmlFor="parentTag">
                        Родительский тег
                      </label>
                      <Field
                        name="parentTag" type="text" className="form-control"
                        placeholder="Родительский тег" disabled={true}/>
                      <ErrorMessage name="parentTag" component={FormFieldError}/>
                    </div>
                    <div className="form-group">
                      <AsyncSelect
                        defaultOptions={tag.parentTagSelect.value === '' ? [emptyTagOption] : [emptyTagOption, tag.parentTagSelect]}
                        loadOptions={filterParentTagOptions}
                        onChange={value => onParentTagChange(value, setFieldValue)}
                        defaultValue={tag.parentTagSelect}
                        isDisabled={false}
                      />
                    </div>
                  </fieldset>
                </div>
                <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                  Сохранить
                </button>
              </div>
              <FormObserver/>
            </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
