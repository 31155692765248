import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { AlertCircle } from 'react-feather'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import * as yup from 'yup'
import $ from 'jquery'
import ReactCrop from 'react-image-crop'
import {
  emptyCategoryOption,
  filterCatalogCategoryOptions,
  getCatalogCategoryOptionByValue,
  styleImage
} from '../ClothifyApi'
import MD5 from 'crypto-js/md5'
import AsyncSelect from 'react-select/async'

export function EditCategoryModal (modalParams) {
  const imgHost = 'https://cdn1.justbutik.ru/'
  const initialValues = {
    id: typeof modalParams.data.id === 'undefined' ? '' : modalParams.data.id,
    name: modalParams.data.name || '',
    category: modalParams.data.category || '',
    image: modalParams.data.image || '',
    categorySelect: modalParams.data.categorySelect || emptyCategoryOption
  }

  const [message, setMessage] = useState({ text: '', type: 'success' })
  const scale = 1
  const rotate = 0
  const imageSize = { width: 111, height: 111 }
  const formRef = useRef()
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(undefined)
  const [imgSrc, setImgSrc] = useState('')
  const [file, setFile] = useState(undefined)
  const [uploadDisabled, setUploadDisabled] = useState(true)

  useEffect(() => {
    setFormValues(initialValues)
  }, [modalParams.data])

  const [formValues, setFormValues] = useState(initialValues)
  const onSaveModal = modalParams.onSaveModal
  const handleSave = (params) => {
    const formData = JSON.parse(JSON.stringify(params))
    formData.category = formData.categorySelect.value
    delete formData.categorySelect
    $('#editCategoryModal').modal('hide')
    onSaveModal(formData)
  }

  const validateFormSchema = yup.object().shape({
    category: yup.string().required('Это поле обязательно для заполнения.'),
    image: yup.string().required('Это поле обязательно для заполнения.')
  })

  useEffect(() => {
    getCatalogCategoryOptionByValue(formValues.category)
      .then(response => {
        const formData = JSON.parse(JSON.stringify(formRef.current.values))
        formData.category = response.label + ' (' + response.value + ')'
        formData.categorySelect = response
        setFormValues(formData)
      })
  }, [formValues.id])

  useEffect(() => {
    setUploadDisabled(crop === undefined)
  }, [crop])

  const onCategoryChange = (option, setFieldValue) => {
    if (option.value.trim() === '') {
      setFieldValue('category', '')
    } else {
      setFieldValue('category', option.label + ' (' + option.value + ')')
    }
    setFieldValue('categorySelect', option)
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setFile(e.target.files[0])
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad = (e) => {
    setCrop({ unit: '%', x: 0, y: 0, width: 100, height: 100 })
  }

  const uploadImages = () => {
    setUploadDisabled(true)
    const parts = imgSrc.split(';base64,')
    const contentType = parts.shift().split('data:').pop().toLowerCase()
    let ext = '.jpg'
    switch (contentType) {
      case 'image/jpeg':
      case 'image/jpg':
        ext = '.jpg'
        break
      case 'image/png':
        ext = '.png'
        break
      case 'image/gif':
        ext = '.gif'
        break
      case 'image/webp':
        ext = '.webp'
        break
    }

    const baseFileName = MD5(imgSrc).toString()
    const fileName = baseFileName + ext
    const sourceData = {
      path: 'blocks/source',
      baseFileName: fileName,
      file: file
    }
    const imageData = {
      path: 'blocks/image',
      baseFileName: baseFileName + '_' + imageSize.width + 'x' + imageSize.height,
      actions: [
        {
          type: 'crop',
          params: crop
        },
        {
          type: 'scale',
          params: imageSize
        }
      ],
      file: file
    }
    Promise.all([
      styleImage(sourceData),
      styleImage(imageData)
    ])
      .then(results => {
        const formData = JSON.parse(JSON.stringify(formRef.current.values))
        formData.image = results[1].data[0]
        setFormValues(formData)
        setMessage({ type: 'success', text: 'Изображения успешно загружены' })
        window.scrollTo(0, 0)
      })
      .catch(err => {
        console.log(err)
        setMessage({ type: 'alert', text: 'При стилизации изображений произошла ошибка' })
        window.scrollTo(0, 0)
      })
  }

  return (
    <>
      <div
        className="modal fade" id="editCategoryModal" tabIndex="-1" role="dialog"
        aria-labelledby="removeConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {message.text.length > 0 && <div
                className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
                <AlertCircle className="mg-r-10 "/> {message.text}</div>}
              <Formik
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={validateFormSchema}
                onSubmit={handleSave}
                innerRef={formRef}>
                {({ isSubmitting, setFieldValue }) => (<Form>
                  <div className="row row-xs">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="d-block" htmlFor="name">Название</label>
                        <Field name="name" type="text" className="form-control" placeholder="Название"/>
                        <ErrorMessage name="name" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="category">
                          Категория
                        </label>
                        <Field
                          name="category" type="text" className="form-control"
                          placeholder="Категория каталога" disabled={true}/>
                        <ErrorMessage name="category" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <AsyncSelect
                          defaultOptions={formValues.categorySelect.value === '' ? [emptyCategoryOption] : [emptyCategoryOption, formValues.categorySelect]}
                          loadOptions={filterCatalogCategoryOptions}
                          onChange={value => onCategoryChange(value, setFieldValue)}
                          defaultValue={formValues.categorySelect}
                        />
                      </div>
                      <div className="row">
                        <div className="col-3 p-2">
                          <img alt="Картинка" src={imgHost + formValues.image} style={{ maxHeight: 100 }}/>
                        </div>
                        <div className="form-group col-9">
                          <label className="d-block" htmlFor="image">
                            Картинка
                          </label>
                          <Field
                            name="image" type="text" className="form-control"
                            placeholder="Картинка" disabled={true}/>
                          <ErrorMessage name="image" component={FormFieldError}/>
                        </div>
                      </div>
                      <div className="form-group row pt-3 pb-3">
                        <div className="col-6">
                          {!!imgSrc && (
                            <ReactCrop
                              crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)}>
                              <img
                                ref={imgRef} alt="Image" src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad}/>
                            </ReactCrop>
                          )}
                        </div>
                        <div className="col-6">
                          <input type="file" accept="image/*" onChange={onSelectFile}/>
                          <button
                            type="button" className="btn btn-primary" onClick={uploadImages}
                            disabled={uploadDisabled}>Загрузить
                          </button>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                      Сохранить
                    </button>
                  </div>
                </Form>)}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

EditCategoryModal.propTypes = {
  data: PropTypes.any
}
