import React from 'react'
import { PropTypes } from 'prop-types'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { AlertCircle } from 'react-feather'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import * as yup from 'yup'
import $ from 'jquery'

export function EditEffectModal (params) {
  const onSaveEffect = params.onSaveEffect
  const handleSaveEffect = (params) => {
    switch (params.type) {
      case 'scale':
        params.width = parseInt(params.width)
        params.height = parseInt(params.height)
        break
      case 'scale-and-crop':
        params.width = parseInt(params.width)
        params.height = parseInt(params.height)
        break
      case 'define-canvas':
        params.exact.width = params.exact.width !== '' ? parseInt(params.exact.width) : ''
        params.exact.height = params.exact.height !== '' ? parseInt(params.exact.height) : ''
        params.relative.leftdiff = params.relative.leftdiff !== '' ? parseInt(params.relative.leftdiff) : ''
        params.relative.rightdiff = params.relative.rightdiff !== '' ? parseInt(params.relative.rightdiff) : ''
        params.relative.topdiff = params.relative.topdiff !== '' ? parseInt(params.relative.topdiff) : ''
        params.relative.bottomdiff = params.relative.bottomdiff !== '' ? parseInt(params.relative.bottomdiff) : ''
        break
    }
    $('#editEffectModal').modal('hide')
    onSaveEffect(params)
  }

  function EffectForm (params) {
    if (params.data.type === 'scale') {
      const validateEffectFormSchema = yup.object().shape({
        width: yup.string()
          .required('Это поле обязательно для заполнения.'),
        height: yup.string()
          .required('Это поле обязательно для заполнения.')
      })
      return (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: params.data.id || '',
              type: params.data.type || '',
              width: params.data.width || '',
              height: params.data.height || '',
              upscale: params.data.upscale || false
            }}
            validationSchema={validateEffectFormSchema}
            onSubmit={handleSaveEffect}>
            {({ status, isSubmitting }) => (<Form>
              {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
                <AlertCircle className="col-sm mg-r-10"/> {status}</div>}
              <div className="row row-xs">
                <div className="col-lg-12">
                  <div className="row row-xs">
                    <div className="col-12">
                      <Field name="id" type="hidden"/>
                      <Field name="type" type="hidden"/>
                      <div className="form-group">
                        <label className="d-block" htmlFor="width">Ширина</label>
                        <Field
                          name="width" type="text" className="form-control" placeholder="Ширина"/>
                        <ErrorMessage name="width" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="height">Высота</label>
                        <Field
                          name="height" type="text" className="form-control" placeholder="Высота"/>
                        <ErrorMessage name="height" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <Field name="upscale" type="checkbox"/> Upscale
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                  Сохранить
                </button>
              </div>
            </Form>)}
          </Formik>
        </>
      )
    } else if (params.data.type === 'scale-and-crop') {
      const validateEffectFormSchema = yup.object().shape({
        width: yup.string()
          .required('Это поле обязательно для заполнения.'),
        height: yup.string()
          .required('Это поле обязательно для заполнения.')
      })
      return (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: params.data.id || '',
              type: params.data.type || '',
              width: params.data.width || '',
              height: params.data.height || ''
            }}
            validationSchema={validateEffectFormSchema}
            onSubmit={handleSaveEffect}>
            {({ status, isSubmitting }) => (<Form>
              {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
                <AlertCircle className="col-sm mg-r-10"/> {status}</div>}
              <div className="row row-xs">
                <div className="col-lg-12">
                  <div className="row row-xs">
                    <div className="col-12">
                      <Field name="id" type="hidden"/>
                      <Field name="type" type="hidden"/>
                      <div className="form-group">
                        <label className="d-block" htmlFor="width">Ширина</label>
                        <Field
                          name="width" type="text" className="form-control" placeholder="Ширина"/>
                        <ErrorMessage name="width" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="height">Высота</label>
                        <Field
                          name="height" type="text" className="form-control" placeholder="Высота"/>
                        <ErrorMessage name="height" component={FormFieldError}/>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                  Сохранить
                </button>
              </div>
            </Form>)}
          </Formik>
        </>
      )
    } else if (params.data.type === 'define-canvas') {
      const validateEffectFormSchema = yup.object().shape({})
      return (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: params.data.id || '',
              type: params.data.type || '',
              exact: {
                width: params.data.exact.width || '',
                height: params.data.exact.height || '',
                xpos: params.data.exact.xpos || '',
                ypos: params.data.exact.ypos || ''
              },
              relative: {
                leftdiff: params.data.relative.leftdiff || '',
                rightdiff: params.data.relative.rightdiff || '',
                topdiff: params.data.relative.topdiff || '',
                bottomdiff: params.data.relative.bottomdiff || ''
              },
              color: params.data.color || ''
            }}
            validationSchema={validateEffectFormSchema}
            onSubmit={handleSaveEffect}>
            {({ status, isSubmitting }) => (<Form>
              {status && <div className="row alert alert-danger d-flex align-items-center" role="alert">
                <AlertCircle className="col-sm mg-r-10"/> {status}</div>}
              <div className="row row-xs">
                <div className="col-lg-12">
                  <div className="row row-xs">
                    <div className="col-12">
                      <Field name="id" type="hidden"/>
                      <Field name="type" type="hidden"/>
                      <div className="form-group">
                        <label className="d-block" htmlFor="color">Цвет</label>
                        <Field
                          name="color" type="text" className="form-control" placeholder="Цвет"/>
                      </div>
                      <div className="form-group">
                        <Field name="under" type="checkbox"/> Under
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <fieldset className="form-fieldset">
                            <legend>Exact size</legend>
                            <div className="form-group">
                              <label className="d-block" htmlFor="exact.width">Ширина</label>
                              <Field
                                name="exact.width" type="text" className="form-control" placeholder="Ширина"/>
                            </div>
                            <div className="form-group">
                              <label className="d-block" htmlFor="exact.height">Высота</label>
                              <Field
                                name="exact.height" type="text" className="form-control" placeholder="Высота"/>
                            </div>
                            <div className="form-group">
                              <label className="d-block" htmlFor="exact.xpos">xpos</label>
                              <Field
                                name="exact.xpos" type="text" className="form-control" placeholder="xpos"/>
                            </div>
                            <div className="form-group">
                              <label className="d-block" htmlFor="exact.ypos">ypos</label>
                              <Field
                                name="exact.ypos" type="text" className="form-control" placeholder="ypos"/>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-6">
                          <fieldset className="form-fieldset">
                            <legend>Relative size</legend>
                            <div className="form-group">
                              <label className="d-block" htmlFor="relative.leftdiff">Left difference</label>
                              <Field
                                name="relative.leftdiff" type="text" className="form-control"
                                placeholder="Left difference"/>
                            </div>
                            <div className="form-group">
                              <label className="d-block" htmlFor="relative.rightdiff">Right difference</label>
                              <Field
                                name="relative.rightdiff" type="text" className="form-control"
                                placeholder="Right difference"/>
                            </div>
                            <div className="form-group">
                              <label className="d-block" htmlFor="relative.topdiff">Top difference</label>
                              <Field
                                name="relative.topdiff" type="text" className="form-control"
                                placeholder="Top difference"/>
                            </div>
                            <div className="form-group">
                              <label className="d-block" htmlFor="relative.bottomdiff">Bottom difference</label>
                              <Field
                                name="relative.bottomdiff" type="text" className="form-control"
                                placeholder="Bottom difference"/>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                  Сохранить
                </button>
              </div>
            </Form>)}
          </Formik>
        </>
      )
    } else {
      return (<></>)
    }
  }

  return (
    <>
      <div
        className="modal fade" id="editEffectModal" tabIndex="-1" role="dialog"
        aria-labelledby="removeConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <EffectForm data={params.data}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

EditEffectModal.propTypes = {
  data: PropTypes.any
}
