import React from 'react'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/auth.scss'

import errorVector from '../../images/2107.jpg'

export default function NotFound () {
  return (
    <SimpleLayout contentClassName="content content-fixed content-auth-alt" containerClassName="container ht-100p tx-center">
      <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
        <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15">
          <img src={errorVector} className="img-fluid" alt="Page not found" />
        </div>
        <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">404 Страница не найдена</h1>
        <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Упс! Похоже что вы попали на отсутствующую страницу.</h5>
      </div>
    </SimpleLayout>
  )
}
