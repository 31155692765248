import React from 'react'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/auth.scss'
import forgotVector from '../../images/1386.jpg'

// TODO: add form processing.

export default function Forgot () {
  return (
    <SimpleLayout contentClassName="content content-fixed content-auth-alt" containerClassName="container d-flex justify-content-center ht-100p">
      <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
        <div className="wd-80p wd-sm-300 mg-b-15">
          <img src={forgotVector} className="img-fluid" alt="Забыли пароль?"/>
        </div>
        <h4 className="tx-20 tx-sm-24">Восстановление забытого пароля</h4>
        <p className="tx-color-03 mg-b-30 tx-center">Укажите имя пользователя или адрес электронной почты и мы отправим вам ссылку для восстановления пароля.</p>
        <div className="wd-100p d-flex flex-column flex-sm-row mg-b-40">
          <input type="text" className="form-control wd-sm-250 flex-fill" placeholder="Имя пользователя или e-mail"/>
          <button className="btn btn-brand-02 mg-sm-l-10 mg-t-10 mg-sm-t-0">Отправить ссылку</button>
        </div>
      </div>
    </SimpleLayout>
  )
}
