import React from 'react'
import PropTypes from 'prop-types'

const Content = (props) => {
  return (
    <div className={props.contentClassName}>
      <div className={props.containerClassName}>
        {props.children}
      </div>
    </div>
  )
}

Content.propTypes = {
  children: PropTypes.element.isRequired,
  contentClassName: PropTypes.string,
  containerClassName: PropTypes.string
}

Content.defaultProps = {
  contentClassName: 'content',
  containerClassName: 'container'
}

export default Content
