import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import '@nosferatu500/react-sortable-tree/style.css'
import DataTable from 'react-data-table-component'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function CatalogTags () {
  const defaultPageSettings = {
    filter: '',
    genders: ['any', 'men', 'women'],
    strictSearch: false
  }
  const navigate = useNavigate()
  const [catalogTags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)

  let tagsPageSettings = localStorage.getItem('tagsPageSettings')
  try {
    if (tagsPageSettings === null) {
      tagsPageSettings = defaultPageSettings
    } else {
      tagsPageSettings = JSON.parse(tagsPageSettings)
    }
  } catch (e) {
    tagsPageSettings = defaultPageSettings
  }
  const [filter, setFilter] = useState(tagsPageSettings.filter)
  const [genders, setGenders] = useState(tagsPageSettings.genders)
  const [strictSearch, setStrictSearch] = useState(tagsPageSettings.strictSearch)

  useEffect(() => {
    if (!token) {
      return
    }
    fetchTags(1)
  }, [])

  useEffect(() => {
    if (!token) {
      return
    }
    localStorage.setItem('tagsPageSettings', JSON.stringify({
      filter: filter,
      genders: genders,
      strictSearch: strictSearch
    }))
    fetchTags(1)
  }, [filter, genders, strictSearch])

  function handleEditClick (event) {
    navigate('/catalog/tags/' + event.target.dataset.id + '?destination=/catalog/tags')
  }

  function handleCreateClick () {
    navigate('/catalog/tags/create?destination=/catalog/categories')
  }

  function handleCheckboxChange (event) {
    const gendersCopy = JSON.parse(JSON.stringify(genders))
    const i = gendersCopy.indexOf(event.target.value)
    if (i >= 0) {
      gendersCopy.splice(i, 1)
    } else {
      gendersCopy.push(event.target.value)
    }
    setGenders(gendersCopy)
  }

  function handleStrictSearchChange (event) {
    if (event.target.value === 'strict_search') {
      setStrictSearch(!strictSearch)
    }
  }

  const fetchTags = async page => {
    setLoading(true)

    const headers = { Authorization: 'Bearer ' + token }
    const getParams = {
      page: page,
      perPage: perPage,
      filter: filter,
      genders: JSON.stringify(genders),
      strictSearch: strictSearch
    }
    const response = await axios.create({ headers: headers }).get('/catalog-tags', { params: getParams })

    setTags(response.data.tags)
    setTotalRows(response.data.total)
    setLoading(false)
  }

  const handleTextSearch = event => {
    setFilter(event.target.value)
  }

  const handlePageChange = page => {
    fetchTags(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true)

    const headers = { Authorization: 'Bearer ' + token }
    const getParams = {
      page: page,
      perPage: perPage
    }
    const response = await axios.create({ headers: headers }).get('/catalog-tags', { params: getParams })

    setTags(response.data.tags)
    setPerPage(newPerPage)
    setLoading(false)
  }

  return (
    <>
      <Helmet>
        <title>Управление тегами каталога | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог товаров' }, { '/catalog/tags': 'Теги' }]}
      >
        <>
          <h1 className="df-title">Управление тегами каталога</h1>
          <button className="btn btn-primary tx-13 mg-b-10" onClick={handleCreateClick}>Создать тег</button>
          <div className="d-flex flex-row">
            <input
              type="text" className="form-control w-25 px-1" placeholder="Поиск..." value={filter}
              onChange={handleTextSearch}/>
            <label className="px-1 pl-3">
              <input
                type="checkbox" className="form-control" value="any" checked={genders.includes('any')}
                onChange={handleCheckboxChange}/>
              Без пола
            </label>
            <label className="px-1">
              <input
                type="checkbox" className="form-control" value="men" checked={genders.includes('men')}
                onChange={handleCheckboxChange}/>
              Мужской
            </label>
            <label className="px-1 pr-3">
              <input
                type="checkbox" className="form-control" value="women" checked={genders.includes('women')}
                onChange={handleCheckboxChange}/>
              Женский
            </label>
            <label className="px-1">
              <input
                type="checkbox" className="form-control" value="strict_search" checked={strictSearch}
                onChange={handleStrictSearchChange}/>
              Строгий поиск
            </label>
          </div>
          <DataTable
            keyField="_id"
            defaultSortField="_id"
            defaultSortAsc={true}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            columns={[
              {
                name: 'Тег',
                selector: (row, i) => row.tag,
                sortable: false
              },
              {
                name: 'URL',
                selector: (row, i) => row.tagUrl,
                sortable: false
              },
              {
                name: 'Пол',
                selector: (row, i) => row.gender,
                sortable: false
              },
              {
                name: 'Cheap/New/Sale',
                selector: (row, i) => row.cns,
                sortable: false
              },
              {
                name: 'Статус',
                selector: (row, i) => row.status,
                sortable: false
              },
              {
                name: '',
                sortable: false,
                cell: function getRow (row) {
                  return (
                    <>
                      <button
                        type="button" className="btn btn-link" data-id={row._id} onClick={handleEditClick}>
                        Редактировать
                      </button>
                    </>
                  )
                },
                right: true
              }
            ]}
            data={catalogTags}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </>
      </SimpleLayout>
    </>
  )
}
