import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { DownloadCloud as IconDownloadCloud } from 'react-feather'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'

function Import () {
  return (
    <>
      <Helmet>
        <title>Управление импортом данных | Clothify Manager</title>
      </Helmet>
      <SimpleLayout contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/import': 'Импорт данных' }]}
      >
        <>
          <h1 className="df-title">Управление импортом данных</h1>
          <p className="df-lead">На этой странице собраны все компоненты для управления импортом данных в каталог.</p>
          <div className="row tx-14">
            <div className="col-sm-6">
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconDownloadCloud width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Источники</h5>
                <p className="mg-b-20">Управление источниками данных для импорта.</p>
                <Link to="/import/feeds" className="tx-medium">Перейти к управлению источниками <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
            </div>
          </div>
        </>
      </SimpleLayout>
    </>
  )
}

export default Import
