import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import DataTable from 'react-data-table-component'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function CatalogBrands () {
  const defaultPageSettings = {
    filter: '',
    strictSearch: false
  }
  const navigate = useNavigate()
  const [catalogBrands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)

  let brandsPageSettings = localStorage.getItem('brandsPageSettings')
  try {
    if (brandsPageSettings === null) {
      brandsPageSettings = defaultPageSettings
    } else {
      brandsPageSettings = JSON.parse(brandsPageSettings)
    }
  } catch (e) {
    brandsPageSettings = defaultPageSettings
  }
  const [filter, setFilter] = useState(brandsPageSettings.filter)
  const [strictSearch, setStrictSearch] = useState(brandsPageSettings.strictSearch)

  useEffect(() => {
    if (!token) {
      return
    }
    fetchBrands(1)
  }, [])

  useEffect(() => {
    if (!token) {
      return
    }
    localStorage.setItem('brandsPageSettings', JSON.stringify({
      filter: filter,
      strictSearch: strictSearch
    }))
    fetchBrands(1)
  }, [filter, strictSearch])

  function handleEditClick (event) {
    navigate('/catalog/brands/' + event.target.dataset.id + '?destination=/catalog/brands')
  }

  function handleStrictSearchChange (event) {
    if (event.target.value === 'strict_search') {
      setStrictSearch(!strictSearch)
    }
  }

  const fetchBrands = async page => {
    setLoading(true)

    const headers = { Authorization: 'Bearer ' + token }
    const getParams = {
      page: page,
      perPage: perPage,
      filter: filter,
      strictSearch: strictSearch,
      countBrands: true
    }
    const response = await axios.create({ headers: headers }).get('/brands', { params: getParams })

    setBrands(response.data.brands)
    setTotalRows(response.data.total)
    setTotalRows(1000)
    setLoading(false)
  }

  const handleTextSearch = event => {
    setFilter(event.target.value)
  }

  const handlePageChange = page => {
    fetchBrands(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true)

    const headers = { Authorization: 'Bearer ' + token }
    const getParams = {
      page: page,
      perPage: perPage
    }
    const response = await axios.create({ headers: headers }).get('/brands', { params: getParams })

    setBrands(response.data.brands)
    setPerPage(newPerPage)
    setLoading(false)
  }

  return (
    <>
      <Helmet>
        <title>Управление тегами каталога | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог товаров' }, { '/catalog/brands': 'Бренды' }]}
      >
        <>
          <h1 className="df-title">Управление брендами каталога</h1>
          <div className="d-flex flex-row">
            <input
              type="text" className="form-control w-25 px-1" placeholder="Поиск..." value={filter}
              onChange={handleTextSearch}/>
            <label className="px-1">
              <input
                type="checkbox" className="form-control" value="strict_search" checked={strictSearch}
                onChange={handleStrictSearchChange}/>
              Строгий поиск
            </label>
          </div>
          <DataTable
            keyField="_id"
            defaultSortField="_id"
            defaultSortAsc={true}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            columns={[
              {
                name: 'Бренд',
                selector: (row, i) => row.name,
                sortable: false
              },
              {
                name: 'Алиас',
                selector: (row, i) => row.slug,
                sortable: false
              },
              {
                name: 'Статус',
                selector: (row, i) => row.status,
                sortable: false
              },
              {
                name: '',
                sortable: false,
                cell: function getRow (row) {
                  return (
                    <>
                      <button
                        type="button" className="btn btn-link" data-id={row._id} onClick={handleEditClick}>
                        Редактировать
                      </button>
                    </>
                  )
                },
                right: true
              }
            ]}
            data={catalogBrands}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </>
      </SimpleLayout>
    </>
  )
}
