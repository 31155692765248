import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import {
  emptyBrand,
  getCatalogBrandById,
  putCatalogBrandUpdates, styleImage
} from '../ClothifyApi'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'

export default function CatalogBrandEdit () {
  const navigate = useNavigate()
  const getParams = new URLSearchParams(location.search)
  const destination = getParams.get('destination')
  const initialMessage = { type: 'success', text: '' }
  const formRef = useRef()
  const { brandId } = useParams()
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [brand, setBrand] = useState(emptyBrand)
  const [message, setMessage] = useState(initialMessage)

  const logoSize = { width: 128, height: 128 }
  const aspect = 1
  const scale = 1
  const rotate = 0
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(undefined)
  const [imgSrc, setImgSrc] = useState('')
  const [file, setFile] = useState(undefined)

  useEffect(() => {
    if (!token) {
      return
    }
    getCatalogBrandById(brandId)
      .then(brandData => {
        setBrand(brandData)
      })
      .catch(error => {
        console.log(error.response)
      })
  }, [brandId])

  useEffect(() => {
    setUploadDisabled(crop === undefined)
  }, [crop])

  const validateFormSchema = yup.object().shape({
    // brand: yup.string()
    //   .required('Это поле обязательно к заполнению.'),
    // machineName: yup.string()
    //   .required('Это поле обязательно к заполнению.')
  })

  const imgHost = 'https://cdn1.justbutik.ru/'
  const initialFormValues = {
    name: brand.name || '',
    slug: brand.slug || '',
    status: brand.status || 1,
    logo: brand.logo || ''
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(makeAspectCrop(
        {
          unit: '%',
          width: 100
        },
        width / height,
        width,
        height
      ))
    }
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      const brandCopy = JSON.parse(JSON.stringify(brand))
      delete brandCopy._id
      putCatalogBrandUpdates(brandId, brandCopy)
        .then(response => {
          setBrand(response.data)
          setMessage('Бренд успешно сохранен!')
          window.scrollTo(0, 0)
          setSubmitting(false)
          if (destination !== null) {
            setTimeout(navigate(destination), 1000)
          }
        })
        .catch(error => {
          const errMsg = ((error.response && error.response.data) && error.response.data.message) || (error.response && error.response.statusText) || error.message
          setStatus(errMsg)
          setSubmitting(false)
        })
    }, 500)
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setFile(e.target.files[0])
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadImages = () => {
    setUploadDisabled(true)
    const parts = imgSrc.split(';base64,')
    const contentType = parts.shift().split('data:').pop().toLowerCase()
    let ext = '.jpg'
    switch (contentType) {
      case 'image/jpeg':
      case 'image/jpg':
        ext = '.jpg'
        break
      case 'image/png':
        ext = '.png'
        break
      case 'image/gif':
        ext = '.gif'
        break
      case 'image/webp':
        ext = '.webp'
        break
    }

    const baseFileName = Date.now().toString()
    const fileName = baseFileName + ext
    const sourceData = {
      path: 'brands/source',
      baseFileName: fileName,
      file: file
    }
    const logoData = {
      path: 'brands/logo',
      baseFileName: baseFileName,
      actions: [
        {
          type: 'scale',
          params: logoSize
        }
      ],
      file: file
    }
    Promise.all([
      styleImage(sourceData),
      styleImage(logoData)
    ])
      .then(results => {
        const brandData = JSON.parse(JSON.stringify(formRef.current.values))
        brandData.logo = results[1].data[0]
        setBrand(brandData)
        setMessage({ type: 'success', text: 'Изображения успешно загружены' })
        window.scrollTo(0, 0)
      })
      .catch(err => {
        console.log(err)
        setMessage({ type: 'alert', text: 'При стилизации изображений произошла ошибка' })
        window.scrollTo(0, 0)
      })
  }

  return (<>
    <Helmet>
      <title>{'Настройка бренда "' + brand.name + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/catalog': 'Каталог' }, { '/catalog/brands': 'Бренды каталога' }, { ['/catalog/brands/' + brand._id]: brand.name }]}>
      <>
        <h1 className="df-title">{'Настройка бренда "' + brand.name + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting }) => (<Form>
            {message.text.length > 0 && <div
              className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
              <AlertCircle className="mg-r-10 "/> {message.text}</div>}
            <div className="row row-xs">
              <div className="col-12">
                <fieldset className="form-fieldset">
                  <div className="custom-control custom-switch mg-b-20">
                    <Field
                      name="status" type="checkbox"
                      className="custom-control-input" id="status" disabled={true}/>
                    <label
                      className="d-block custom-control-label"
                      htmlFor="status">
                      Бренд включен
                    </label>
                    <ErrorMessage name="status" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="name">
                      Название бренда
                    </label>
                    <Field
                      name="name" type="text" className="form-control"
                      placeholder="Укажите название бренда" disabled={true}/>
                    <ErrorMessage name="name" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="slug">
                      Алиас
                    </label>
                    <Field
                      name="slug" type="text" className="form-control"
                      placeholder="Укажите alias бренда" disabled={true}/>
                    <ErrorMessage name="slug" component={FormFieldError}/>
                  </div>
                  <div className="row">
                    <div className="col-3 p-2">
                      <img
                        alt="Лого бренда" src={imgHost + brand.logo}
                        style={{ maxHeight: 100 }}/>
                    </div>
                    <div className="form-group col-9">
                      <label className="d-block" htmlFor="logo">
                        Лого бренда
                      </label>
                      <Field
                        name="logo" type="text" className="form-control"
                        placeholder="Лого бренда" disabled={true}/>
                      <ErrorMessage name="logo" component={FormFieldError}/>
                    </div>
                  </div>
                  <div className="form-group row pt-3 pb-3">
                    <div className="col-6">
                      {!!imgSrc && (
                        <ReactCrop
                          crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} aspect={aspect}>
                          <img
                            ref={imgRef} alt="Original image" src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad}/>
                        </ReactCrop>
                      )}
                    </div>
                    <div className="col-6">
                      <input type="file" accept="image/*" onChange={onSelectFile}/>
                      <button
                        type="button" className="btn btn-primary" onClick={uploadImages}
                        disabled={uploadDisabled}>Загрузить
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
