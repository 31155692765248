import React from 'react'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form } from 'formik'
import '../../scss/pages/default.scss'
import { Helmet } from 'react-helmet-async'
import {
  clothifySlugify,
  emptyCategory,
  getMorpherDeclensions,
  prepositions,
  createCategory,
  putCatalogCategoryRelations
} from '../ClothifyApi'

export default function InitLamodaCategories () {
  const newCategories = [
    ['Корневая категория/Default category/Женщинам/Красота', 'Красота'],
    ['Корневая категория/Default category/Женщинам/Красота/Антисептики', 'Антисептик'],
    ['Корневая категория/Default category/Женщинам/Красота/Аптечная косметика', 'Аптечная косметика'],
    ['Корневая категория/Default category/Женщинам/Красота/Аптечная косметика/Волосы', 'Волосы'],
    ['Корневая категория/Default category/Женщинам/Красота/Аптечная косметика/Глаза', 'Глаз'],
    ['Корневая категория/Default category/Женщинам/Красота/Аптечная косметика/Лицо', 'Лицо'],
    ['Корневая категория/Default category/Женщинам/Красота/Аптечная косметика/Тело', 'Тело'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы', 'Волосы'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Аксессуары для волос', 'Аксессуар волосы'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Аксессуары для волос/Заколки и гребни', 'Заколка гребень'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Аксессуары для волос/Ободки и диадемы', 'Ободок диадема'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Аксессуары для волос/Повязки', 'Повязка'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Аксессуары для волос/Резинки', 'Резинка'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Аксессуары для волос/Шпильки и невидимки', 'Шпилька невидимка'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Бальзамы и ополаскиватели', 'Бальзам ополаскиватель'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Кремы и сыворотки', 'Крем сыворотка'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Лечебный уход для волос', 'Лечебный уход волосы'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Лосьоны и тоники', 'Лосьон тоник'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Маски', 'Маска'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Масла', 'Масло'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Наборы', 'Набор'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Окрашивание', 'Окрашивание'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Окрашивание/Краски для волос', 'Краска волосы'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Окрашивание/Оттеночные средства', 'средство'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Профессиональный уход для волос', 'уход волосы'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Профессиональный уход для волос/Бальзамы и ополаскиватели', 'Бальзам ополаскиватель'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Профессиональный уход для волос/Интенсивный уход', 'уход'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Профессиональный уход для волос/Окрашивание', 'Окрашивание'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Профессиональный уход для волос/Укладка', 'Укладка'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Профессиональный уход для волос/Шампуни', 'Шампунь'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Расчески и щетки', 'Расческа щетка'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Спреи', 'Спрей'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Сухие шампуни', 'Сухой шампунь'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Укладка и стайлинг', 'Укладка стайлинг'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Укладка и стайлинг/Лаки и спреи', 'Лак спрей'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Укладка и стайлинг/Пасты и воск', 'Паста воск'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Укладка и стайлинг/Пены и муссы', 'Пена мусс'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Укладка и стайлинг/Пудры', 'Пудра'],
    ['Корневая категория/Default category/Женщинам/Красота/Волосы/Шампуни', 'Шампунь'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа', 'ванна душ'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа/Гели для душа', 'Гель душ'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа/Маски', 'Маска'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа/Мочалки', 'Мочалка'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа/Мыло', 'Мыло'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа/Пена для ванн', 'Пена вана'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа/Скрабы', 'Скраб'],
    ['Корневая категория/Default category/Женщинам/Красота/Для ванны и душа/Соли для ванн', 'Соль ванна'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж', 'Макияж'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Аксессуары', 'Аксессуар'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Аксессуары/Зеркала', 'Зеркало'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Аксессуары/Кисти', 'Кисть'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Аксессуары/Спонжи', 'Спонж'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови', 'Бровь'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови/Гели', 'Гель'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови/Инструменты', 'Инструмент'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови/Карандаши и подводки', 'Карандаш подводка'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови/Краски и хна', 'Краска хна'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови/Помады и тинты', 'Помада тинт'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови/Тени и палетки', 'Тень палетка'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Брови/Туши', 'Тушь'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Глаза', 'Глаза'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Глаза/Карандаши', 'Карандаш'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Глаза/Накладные ресницы', 'ресницы'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Глаза/Подводки', 'Подводка'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Глаза/Праймеры', 'Праймер'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Глаза/Тени и палетки', 'Тень палетка'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Глаза/Туши', 'Тушь'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Губы', 'Губы'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Губы/Блески', 'Блеск'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Губы/Карандаши', 'Карандаш'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Губы/Помады и лаки', 'Помада лак'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Губы/Тинты', 'Тинт'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо', 'Лицо'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Бронзаторы и скульпторы', 'Бронзатор скульптор'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Корректоры и консилеры', 'Корректор консилер'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Основы под макияж', 'Основа макияж'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Палетки', 'Палетка'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Пудры', 'Пудра'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Румяна', 'Румяна'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Тональные средства', 'Тональное средство'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Лицо/Хайлайтеры', 'Хайлайтер'],
    ['Корневая категория/Default category/Женщинам/Красота/Макияж/Наборы для макияжа', 'Набор макияж'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр', 'Маникюр педикюр'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр/Аппараты', 'Аппарат'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр/Базовое и топовое покрытие', 'Базовое топовое покрытие'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр/Гель-лаки', 'Гель лак'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр/Дизайн ногтей', 'Дизайн ногти'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр/Инструменты', 'Инструмент'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр/Лаки', 'Лак'],
    ['Корневая категория/Default category/Женщинам/Красота/Маникюр и педикюр/Средства для кутикулы и ногтей', 'Средство кутикула ногти'],
    ['Корневая категория/Default category/Женщинам/Красота/Наборы и подарки', 'Набор подарок'],
    ['Корневая категория/Default category/Женщинам/Красота/Натуральная и органическая косметика', 'Натуральная органическая косметика'],
    ['Корневая категория/Default category/Женщинам/Красота/Натуральная и органическая косметика/Волосы', 'Волосы'],
    ['Корневая категория/Default category/Женщинам/Красота/Натуральная и органическая косметика/Тело', 'Тело'],
    ['Корневая категория/Default category/Женщинам/Красота/Парфюмерия', 'Парфюмерия'],
    ['Корневая категория/Default category/Женщинам/Красота/Парфюмерия/Духи и парфюмерная вода', 'Духи парфюмерная вода'],
    ['Корневая категория/Default category/Женщинам/Красота/Парфюмерия/Наборы ', 'Набор'],
    ['Корневая категория/Default category/Женщинам/Красота/Парфюмерия/Селективная парфюмерия', 'Селективная парфюмерия'],
    ['Корневая категория/Default category/Женщинам/Красота/Парфюмерия/Туалетная вода ', 'Туалетная вода'],
    ['Корневая категория/Default category/Женщинам/Красота/Солнцезащитные средства и автозагары', 'Солнцезащитное средство автозагар'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья', 'Техника красота здоровье'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Аппараты для ухода за кожей', 'Аппарат уход кожа'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Бритвы', 'Бритва'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Бьюти-холодильники', 'Бьюти-холодильник'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Зубные щетки и ирригаторы', 'Зубная щетка ирригатор'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Массажеры', 'Массажер'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Машинки для стрижки', 'Машинка стрижка'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Приборы для укладки и стайлеры', 'Прибор укладка стайлер'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Роллеры и гуаша', 'Роллер гуаш'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Фены', 'Фен'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Щетки для чистки лица', 'Щетка чистка лица'],
    ['Корневая категория/Default category/Женщинам/Красота/Техника для красоты и здоровья/Эпиляторы', 'Эпилятор'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей', 'Уход кожа'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Глаза', 'Глаза'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Глаза/Гели', 'Гель'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Глаза/Кремы', 'Крем'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Глаза/Маски', 'Маска'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Глаза/Патчи', 'Патч'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Глаза/Средства для бровей и ресниц', 'Средство брови ресницы'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Глаза/Сыворотки', 'Сыворотка'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Губы', 'Губы'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Губы/Бальзамы', 'Бальзам'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Губы/Маски', 'Маска'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Губы/Скрабы', 'Скраб'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо', 'Лицо'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Кремы', 'Крем'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Маски', 'Маска'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Масла', 'Масло'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Пилинги и скрабы', 'Пилинг скраб'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Средства для снятия макияжа', 'Средство снятие макияжа'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Средства для умывания', 'Средство умывание'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Сыворотки', 'Сыворотка'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Термальная вода', 'Термальная вода'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Лицо/Тоники и лосьоны', 'Тоник лосьон'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Наборы', 'Набор'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело', 'Тело'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Антицеллюлитные средства', 'Антицеллюлитное средство'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Дезодоранты', 'Дезодорант'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Кремы для тела', 'Крем тело'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Лосьоны', 'Лосьон'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Масла', 'Масло'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Молочко', 'Молочко'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Спреи', 'Спрей'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Средства для депиляции', 'Средство депиляция'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Средства для ног', 'Средство ноги'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Тело/Средства для рук', 'Средство руки'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Уход за полостью рта', 'Уход полость рот'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Уход за полостью рта/Зубные пасты', 'Зубная паста'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход за кожей/Уход за полостью рта/Зубные щетки', 'Зубная щетка'],
    ['Корневая категория/Default category/Женщинам/Красота/Уход и косметика для детей', 'Уход косметика дети'],
    ['Корневая категория/Default category/Мужчинам/Красота', 'Красота'],
    ['Корневая категория/Default category/Мужчинам/Красота/Антисептики', 'Антисептик'],
    ['Корневая категория/Default category/Мужчинам/Красота/Волосы', 'Волосы'],
    ['Корневая категория/Default category/Мужчинам/Красота/Волосы/Бальзамы и маски', 'Бальзам маска'],
    ['Корневая категория/Default category/Мужчинам/Красота/Волосы/Массажеры и аппараты', 'Массажер аппарат'],
    ['Корневая категория/Default category/Мужчинам/Красота/Волосы/Расчески', 'Расческа'],
    ['Корневая категория/Default category/Мужчинам/Красота/Волосы/Средства для укладки', 'Средство укладка'],
    ['Корневая категория/Default category/Мужчинам/Красота/Волосы/Шампуни', 'Шампунь'],
    ['Корневая категория/Default category/Мужчинам/Красота/Лицо', 'Лицо'],
    ['Корневая категория/Default category/Мужчинам/Красота/Лицо/Кремы', 'Крем'],
    ['Корневая категория/Default category/Мужчинам/Красота/Лицо/Массажеры и аппараты', 'Массажер аппарат'],
    ['Корневая категория/Default category/Мужчинам/Красота/Лицо/Пилинги и скрабы', 'Пилинг скраб'],
    ['Корневая категория/Default category/Мужчинам/Красота/Лицо/Средства для умывания', 'Средство умывание'],
    ['Корневая категория/Default category/Мужчинам/Красота/Лицо/Тоники и лосьоны', 'Тоник лосьон'],
    ['Корневая категория/Default category/Мужчинам/Красота/Наборы', 'Набор'],
    ['Корневая категория/Default category/Мужчинам/Красота/Парфюмерия', 'Парфюмерия'],
    ['Корневая категория/Default category/Мужчинам/Красота/Парфюмерия/Духи и парфюмерная вода', 'Духи парфюмерная вода'],
    ['Корневая категория/Default category/Мужчинам/Красота/Парфюмерия/Наборы', 'Набор'],
    ['Корневая категория/Default category/Мужчинам/Красота/Парфюмерия/Селективная парфюмерия', 'Селективная парфюмерия'],
    ['Корневая категория/Default category/Мужчинам/Красота/Парфюмерия/Туалетная вода и одеколон', 'Туалетная вода одеколон'],
    ['Корневая категория/Default category/Мужчинам/Красота/Солнцезащитные средства и автозагары', 'Солнцезащитное средство автозагар'],
    ['Корневая категория/Default category/Мужчинам/Красота/Стрижка и бритье', 'Стрижка бритье'],
    ['Корневая категория/Default category/Мужчинам/Красота/Стрижка и бритье/Бритвы', 'Бритва'],
    ['Корневая категория/Default category/Мужчинам/Красота/Стрижка и бритье/Машинки и триммеры', 'Машинка триммер'],
    ['Корневая категория/Default category/Мужчинам/Красота/Стрижка и бритье/Ножницы', 'Ножницы'],
    ['Корневая категория/Default category/Мужчинам/Красота/Стрижка и бритье/Средства для бороды и усов', 'Средства борода усы'],
    ['Корневая категория/Default category/Мужчинам/Красота/Стрижка и бритье/Средства для бритья', 'Средство бритье'],
    ['Корневая категория/Default category/Мужчинам/Красота/Стрижка и бритье/Средства после бритья', 'Средство после бритье'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело', 'Тело'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Гели для душа', 'Гель душ'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Дезодоранты', 'Дезодорант'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Маски для тела', 'Маска тело'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Масла', 'Масло'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Массажеры и аппараты', 'Массажер аппарат'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Мочалки', 'Мочалка'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Мыло', 'Мыло'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Скрабы', 'Скраб'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Соли для ванн', 'Соль ванна'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Средства для ног', 'Средство ноги'],
    ['Корневая категория/Default category/Мужчинам/Красота/Тело/Средства для рук', 'Средство руки'],
    ['Корневая категория/Default category/Мужчинам/Красота/Уход за полостью рта', 'Уход полость рта'],
    ['Корневая категория/Default category/Мужчинам/Красота/Уход за полостью рта/Зубные пасты', 'Зубная паста'],
    ['Корневая категория/Default category/Мужчинам/Красота/Уход за полостью рта/Зубные щетки', 'Зубная щетка']
  ]

  const processedCategories = {}
  const nameCounters = {}
  const slugCounters = {}
  let categoryPaths = []

  const processCategory = (processedCategories, nameCounters, categoryPath, singleForm) => {
    const m = categoryPath.match('Корневая категория/Default category/(Мужчинам|Женщинам)/(.*)$')

    const parts = m[2].split('/')
    const name = parts.pop()
    const parent = parts.join('/')
    if (parent !== '' && typeof processedCategories[parent] === 'undefined') {
      // TODO: single form
      processCategory(processedCategories, nameCounters, 'Корневая категория/Default category/' + m[1] + '/' + parent, '')
    }

    if (typeof processedCategories[m[2]] === 'undefined') {
      processedCategories[m[2]] = { gender: ['any'], parent: '', relations: [] }
    }
    processedCategories[m[2]].relations.push(categoryPath)
    processedCategories[m[2]].name = name
    processedCategories[m[2]].parent = parent
    switch (m[1]) {
      case 'Мужчинам':
        processedCategories[m[2]].gender.push('men')
        break
      case 'Женщинам':
        processedCategories[m[2]].gender.push('women')
        break
    }
    processedCategories[m[2]].word_single_form = singleForm.toLowerCase()
    if (typeof nameCounters[name] === 'undefined') {
      nameCounters[name] = { enabled: true, parent: parent }
    } else if (nameCounters[name].parent !== parent) {
      nameCounters[name].enabled = false
    }
  }

  newCategories.forEach(newCategory => {
    processCategory(processedCategories, nameCounters, newCategory[0].trim(), newCategory[1].trim())
  })

  const initialFormValues = {
    test: ''
  }

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    categoryPaths = Object.keys(processedCategories)
    processCategories()
  }

  const processCategories = () => {
    return new Promise(resolve => {
      const categoryPath = categoryPaths.shift()
      if (Object.prototype.hasOwnProperty.call(processedCategories, categoryPath)) {
        buildCategory(categoryPath).then(() => {
          if (categoryPaths.length > 0) {
            resolve(processCategories())
          } else {
            resolve(true)
          }
        })
      }
    })
  }

  const generateMachineName = (croppedTag) => {
    return clothifySlugify(croppedTag)
  }

  const getMorpherData = (text) => {
    return getMorpherDeclensions(text)
  }

  const buildCategory = (categoryPath) => {
    return new Promise(resolve => {
      if (categoryPath === '' || typeof processedCategories[categoryPath]._id !== 'undefined') {
        return resolve(true)
      }
      const category = processedCategories[categoryPath]
      const categoryData = JSON.parse(JSON.stringify(emptyCategory))
      categoryData.name = category.name
      categoryData.facet_name = category.name
      categoryData.word_single_form = category.word_single_form
      categoryData.gender = category.gender
      buildCategory(category.parent).then(() => {
        categoryData.enabled = nameCounters[category.name].enabled
        if (categoryData.enabled && category.parent !== '' && !nameCounters[category.parent.split('/').pop()].enabled) {
          categoryData.enabled = false
          nameCounters[category.name].enabled = false
        }
        categoryData.parent = category.parent !== '' ? processedCategories[category.parent]._id : ''
        delete categoryData.redirectDisabledSelect

        let parentSlug = ''
        if (category.parent !== '') {
          parentSlug = processedCategories[category.parent].slug
        }
        if (parentSlug !== '') {
          categoryData.slug = parentSlug + '-' + generateMachineName(category.name)
        } else {
          categoryData.slug = generateMachineName(category.name)
        }
        if (typeof slugCounters[categoryData.slug] === 'undefined') {
          slugCounters[categoryData.slug] = 0
        } else {
          slugCounters[categoryData.slug]++
          categoryData.slug = categoryData.slug + '-' + slugCounters[categoryData.slug]
        }

        if (categoryData.enabled) {
          const baseWords = categoryData.word_single_form.trim().toLowerCase().split(' ')
          const promises = []
          promises.push(getMorpherData(categoryData.name))
          const words = []
          baseWords.forEach(baseWord => {
            const splittedBaseWords = baseWord.split(',')
            splittedBaseWords.forEach(splittedBaseWord => {
              if (!prepositions.includes(splittedBaseWord) && splittedBaseWord !== '') {
                words.push(splittedBaseWord)
                promises.push(getMorpherData(splittedBaseWord))
              }
            })
          })
          Promise.all(promises)
            .then(results => {
              categoryData.word_cases = results.shift()
              results.forEach(morpherData => {
                Object.keys(morpherData).forEach(key => {
                  if (Object.prototype.hasOwnProperty.call(morpherData, key)) {
                    if (key === 'множественное') {
                      Object.keys(morpherData['множественное']).forEach(mkey => {
                        const splitWords = morpherData['множественное'][mkey].split(' ')
                        splitWords.forEach(splitWord => {
                          if (!prepositions.includes(splitWord) && !words.includes(splitWord)) {
                            words.push(splitWord)
                          }
                        })
                      })
                    } else if (['Р', 'Д', 'В', 'Т', 'П'].includes(key)) {
                      const splitWords = morpherData[key].split(' ')
                      splitWords.forEach(splitWord => {
                        if (!prepositions.includes(splitWord) && !words.includes(splitWord)) {
                          words.push(splitWord)
                        }
                      })
                    }
                  }
                })
                categoryData.word_forms = words
              })
              if (categoryData.parent === '') {
                delete categoryData.parent
              }
              createCategory(categoryData).then(cat => {
                processedCategories[categoryPath]._id = cat.data._id
                processedCategories[categoryPath].slug = cat.data.slug
                putCatalogCategoryRelations(cat.data._id, '61b197b3f8af4d068f9b6e48', category.relations)
                  .then(() => {
                    resolve()
                  })
              })
            })
        } else {
          if (categoryData.parent === '') {
            delete categoryData.parent
          }
          createCategory(categoryData).then(cat => {
            processedCategories[categoryPath]._id = cat.data._id
            processedCategories[categoryPath].slug = cat.data.slug
            putCatalogCategoryRelations(cat.data._id, '61b197b3f8af4d068f9b6e48', category.relations)
              .then(() => {
                resolve()
              })
          })
        }
      })
    })
  }

  return (<>
    <Helmet>
      <title>{'Инициализация новых категорий каталога | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }]}>
      <>
        <h1 className="df-title">{'Инициализация новых категорий каталога'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={handleFormSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
