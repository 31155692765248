import React, { useEffect, useState } from 'react'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/ru'
import { token } from '../../services/auth.service'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

moment.locale('ru')

axios.defaults.baseURL = config.longtaskUrl
axios.defaults.headers.common['X-Api-Key'] = config.longtaskKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function Jobs () {
  const [jobs, setJobs] = useState([])

  const getJobs = () => {
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/jobs')
      .then(response => {
        setJobs(response.data)
      })
      .catch(error => {
        console.error(error.response)
      })
  }

  useEffect(() => {
    if (!token) {
      return
    }

    getJobs()

    const interval = setInterval(() => {
      getJobs()
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  function selectState (state) {
    let status = ''
    switch (state) {
      case 'ERROR':
      case 'PENDING':
        status = 'disabled'
        break
      case 'STARTED':
      case 'IN_PROGRESS':
        status = 'active'
        break
      case 'COMPLETED':
        status = 'complete'
        break
      default:
        status = ''
    }
    return status
  }

  function selectBadge (state) {
    let badge = 'badge-light'
    switch (state) {
      case 'ERROR':
        badge = 'badge-danger'
        break
      case 'PENDING':
        badge = 'badge-secondary'
        break
      case 'STARTED':
        badge = 'badge-primary'
        break
      case 'IN_PROGRESS':
        badge = 'badge-info'
        break
      case 'COMPLETED':
        badge = 'badge-success'
        break
      default:
        badge = 'badge-light'
    }
    return badge
  }

  function JobExpandable ({ data }) {
    // console.log(data.steps)
    return (
      <div className="row">
        <div className="col-sm mg-t-10 mg-l-8 mg-r-8 mg-b-20">
          <ul className="steps steps-vertical">
            {data.steps.map((step, index) => {
              return (
                <li key={ index } className={`step-item wd-100p ${selectState(step.state)}`}>
                  <div className="step-link">
                    <span className="step-number">{ index + 1 }</span>
                    <span className="step-title">{ step.title }</span>
                  </div>
                  {step.progress &&
                  <ul>
                    <li>
                      <div className="progress ht-20">
                        <div className={`progress-bar wd-${step.progress.percents}p`} role="progressbar"
                          aria-valuenow={step.progress.percents} aria-valuemin="0" aria-valuemax="100">
                          Обработано {step.progress.items} из {step.progress.total} ({step.progress.percents}%)
                        </div>
                      </div>
                    </li>
                  </ul>
                  }
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  JobExpandable.propTypes = {
    data: PropTypes.object
  }

  return (
    <>
      <Helmet>
        <title>Длительные процессы | Clothify Manager</title>
      </Helmet>
      <SimpleLayout contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/jobs': 'Длительные процессы' }]}
      >
        <>
          <h1 className="df-title">Длительные процессы</h1>
          <DataTable
            keyField="_id"
            defaultSortField="steps.0.started"
            defaultSortAsc={false}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            expandableRows
            expandableRowsComponent={<JobExpandable/>}
            columns={[
              {
                name: 'Очередь',
                selector: (row, i) => row.tag
              },
              {
                name: 'Наименование',
                selector: (row, i) => row.title,
                sortable: true
              },
              {
                name: 'Время создания',
                selector: (row, i) => row.started,
                sortable: true,
                cell: function getRow (row) { return <div data-tag="allowRowEvents" data-toggle="tooltip" data-placement="top" title={moment(row.started).format('LLLL')}>{moment(row.started).calendar()}</div> }
              },
              {
                name: 'Последнее обновление',
                selector: (row, i) => row.updated,
                sortable: true,
                cell: function getRow (row) { return <div data-tag="allowRowEvents" data-toggle="tooltip" data-placement="top" title={moment(row.updated).format('LLLL')}>{moment(row.updated).calendar()}</div> }
              },
              {
                name: 'Статус',
                selector: (row, i) => row.state,
                sortable: true,
                cell: function getRow (row) { return <div data-tag="allowRowEvents"><span className={`badge badge-pill ${selectBadge(row.state)}`}>{row.state}</span></div> },
                right: true
              }
            ]}
            data={jobs}
          />
        </>
      </SimpleLayout>
    </>
  )
}
