import React, { useEffect, useState } from 'react'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import { token } from '../../services/auth.service'
import { Helmet } from 'react-helmet-async'
import { getJobsByTag } from '../ClothifyApi'

export default function JobImportDaemon () {
  const jobTag = 'import-daemon'
  const defaultImportData = {
    state: '',
    updated: '',
    importVersion: '',
    meta: {}
  }
  const labels = {
    clone: 'Клонирование индекса',
    feeds: 'Фиды',
    remove_forbidden_brands: 'Удаление отключенных брендов',
    sync_parsed_search_results: 'Синхронизация с парсером',
    update_weights: 'Обновление весов',
    check_brands_statuses: 'Проверка существования брендов в каталоге',
    move_disabled_categories_products: 'Корректировка категорий товаров',
    hide_yandex_market_offers: 'Скрываем товары Янедекс Маркета',
    remove_duplicates: 'Удаление дублей'
  }
  const [job, setJob] = useState(defaultImportData)

  useEffect(() => {
    if (!token) {
      return
    }
    getJobsByTag('import-daemon')
      .then(response => {
        if (typeof response[0] !== 'undefined') {
          const importDaemonData = response[0]
          delete importDaemonData.history
          if (typeof importDaemonData.meta === 'undefined') {
            importDaemonData.meta = {}
          }
          setJob(importDaemonData)
        } else {
          setJob(defaultImportData)
        }
      })
      .catch(error => {
        console.log(error.response)
      })
  }, [jobTag])

  return (<>
    <Helmet>
      <title>{'Статус импорта | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { '/jobs': 'Jobs' }, { '/jobs/import-daemon': 'Статус импорта' }]}>
      <>
        <h1 className="df-title">{'Статус импорта'}</h1>
        <div className="row row-xs">
          <div className="col-12">
            {job.importVersion}
          </div>
          <div className="col-12">
            {job.state}
          </div>
          <div className="col-12">
            {job.updated}
          </div>
          <div className="accordion" id="accordionMeta">
            {Object.keys(job.meta).map((key, index) => (
              <div className="card" key={index}>
                <div className="card-header" id={'heading-' + key}>
                  <h2 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                      data-target={'#collapse-' + key} aria-expanded="false" aria-controls={'collapse-' + key}>
                      {typeof labels[key] !== 'undefined' ? labels[key] : key}
                    </button>
                  </h2>
                </div>
                <div
                  id={'collapse-' + key} className="collapse" aria-labelledby={'heading-' + key}
                  data-parent="#accordionMeta">
                  <div className="card-body">
                    <pre>
                      {JSON.stringify(job.meta[key], null, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    </SimpleLayout>
  </>)
}
