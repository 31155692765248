import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'perfect-scrollbar'
import * as Icon from 'react-feather'
import { authService } from '../../../../services/auth.service'
import { getSolutions } from '../../../../components/ClothifyApi'

const NavbarMenu = (props) => {
  const [psNavbar, setPsNavBar] = useState()
  const [solutionLinks, setSolutionLinks] = useState([])

  const isAuthenticated = useRef(authService.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated.current === true) {
      getSolutions()
        .then(res => {
          const links = []
          res.data.forEach(solution => {
            links.push({ id: solution._id, name: solution.name })
          })
          setSolutionLinks(links)
        })
    }
  }, [isAuthenticated])

  const handleMainMenuCloseClick = (event) => {
    event.preventDefault()
    document.body.classList.remove('navbar-nav-show')
  }

  const handleMenuNavLinkClick = (event) => {
    event.preventDefault()
    const elements = document.querySelectorAll('.navbar-menu .with-sub')
    for (const elem of elements) {
      elem.classList.remove('show')
    }
    event.currentTarget.parentElement.classList.add('show')
    if (window.matchMedia('(max-width: 991px)').matches && psNavbar) {
      psNavbar.update()
    }
  }

  const handleClickOutside = (event) => {
    event.stopPropagation()

    // closing nav sub menu of header when clicking outside of it
    if (window.matchMedia('(min-width: 992px)').matches) {
      const navTarg = event.target.closest('.navbar-menu .nav-item')
      if (!navTarg) {
        const elements = document.querySelectorAll('.navbar-header .show')
        for (const elem of elements) {
          elem.classList.remove('show')
        }
      }
    }
  }

  const showNavbarActiveSub = () => {
    const elements = document.getElementById('navbarMenu').getElementsByClassName('active')
    if (window.matchMedia('(max-width: 991px)').matches) {
      for (const elem of elements) {
        elem.classList.add('show')
      }
    } else {
      for (const elem of elements) {
        elem.classList.remove('show')
      }
    }
  }

  useEffect(() => {
    // Initialize PerfectScrollbar of navbar menu for mobile only
    if (window.matchMedia('(max-width: 991px)').matches) {
      setPsNavBar(new PerfectScrollbar('#navbarMenu', {
        suppressScrollX: true
      }))
    }
    showNavbarActiveSub()
    window.addEventListener('resize', showNavbarActiveSub)
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
  }, [])

  return (
    <div id="navbarMenu" className="navbar-menu-wrapper ps">
      <div className="navbar-menu-header">
        <Link to="/" className="df-logo">clot<span>hify</span></Link>
        <Link id="mainMenuClose" to="#" onClick={handleMainMenuCloseClick}><Icon.X/></Link>
      </div>
      {authService.isAuthenticated &&
        <ul className="nav navbar-menu">
          <li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Навигация</li>
          <li className="nav-item with-sub">
            <Link to="/import" className="nav-link" onClick={handleMenuNavLinkClick}>
              <Icon.Database/> Импорт данных
            </Link>
            <ul className="navbar-menu-sub">
              <li className="nav-sub-item">
                <Link to="/import/feeds" className="nav-sub-link">
                  <Icon.DownloadCloud/>Источники данных
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item with-sub">
            <Link to="/categories" className="nav-link" onClick={handleMenuNavLinkClick}>
              <Icon.Layers/> Каталог товаров
            </Link>
            <ul className="navbar-menu-sub">
              <li className="nav-sub-item">
                <Link to="/catalog/categories" className="nav-sub-link">
                  <Icon.Layers/>Дерево категорий
                </Link>
              </li>
              <li className="nav-sub-item">
                <Link to="/catalog/tags" className="nav-sub-link">
                  <Icon.Layers/>Теги каталога
                </Link>
              </li>
              <li className="nav-sub-item">
                <Link to="/catalog/brands" className="nav-sub-link">
                  <Icon.Layers/>Бренды каталога
                </Link>
              </li>
              <li className="nav-sub-item">
                <Link to="/catalog/bonuses" className="nav-sub-link">
                  <Icon.Layers/>Бонусные товары
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item with-sub">
            <Link to="/multimedia" className="nav-link" onClick={handleMenuNavLinkClick}>
              <Icon.Database/>Мультимедиа
            </Link>
            <ul className="navbar-menu-sub">
              <li className="nav-sub-item">
                <Link to="/multimedia/image-styles" className="nav-sub-link">
                  <Icon.DownloadCloud/>Стили изображений
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item with-sub">
            <Link to="/jobs" className="nav-link" onClick={handleMenuNavLinkClick}><Icon.Database/> Процессы</Link>
            <ul className="navbar-menu-sub">
              <li className="nav-sub-item">
                <Link to="/jobs/import-daemon" className="nav-sub-link">
                  <Icon.DownloadCloud/>Статус импорта
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item with-sub">
            <Link to="/utils" className="nav-link" onClick={handleMenuNavLinkClick}><Icon.Database/> Утилиты</Link>
            <ul className="navbar-menu-sub">
              <li className="nav-sub-item">
                <Link to="/utils/alias-generator" className="nav-sub-link">
                  <Icon.DownloadCloud/>Генератор алиасов
                </Link>
              </li>
            </ul>
          </li>
          {solutionLinks.map((solution, index) => (
            <li key={index} className="nav-item with-sub">
              <Link to={`/solution/${solution.id}`} className="nav-link" onClick={handleMenuNavLinkClick}>
                <Icon.Database/>{solution.name}
              </Link>
              <ul className="navbar-menu-sub">
                <li className="nav-sub-item">
                  <Link to={`/solution/${solution.id}/front-page`} className="nav-sub-link">
                    <Icon.DownloadCloud/>Главная страница
                  </Link>
                </li>
                <li className="nav-sub-item">
                  <Link to={`/solution/${solution.id}/menu-main`} className="nav-sub-link">
                    <Icon.DownloadCloud/>Главное меню
                  </Link>
                </li>
                <li className="nav-sub-item">
                  <Link to={`/solution/${solution.id}/looks`} className="nav-sub-link">
                    <Icon.DownloadCloud/>Образы
                  </Link>
                </li>
                <li className="nav-sub-item">
                  <Link to={`/solution/${solution.id}/selections`} className="nav-sub-link">
                    <Icon.DownloadCloud/>Подборки
                  </Link>
                </li>
                <li className="nav-sub-item">
                  <Link to={`/solution/${solution.id}/articles`} className="nav-sub-link">
                    <Icon.DownloadCloud/>Статьи
                  </Link>
                </li>
                <li className="nav-sub-item">
                  <Link to={`/solution/${solution.id}/blocks`} className="nav-sub-link">
                    <Icon.DownloadCloud/>Блоки
                  </Link>
                </li>
                <li className="nav-sub-item">
                  <Link to={`/solution/${solution.id}/teaser-settings`} className="nav-sub-link">
                    <Icon.DownloadCloud/>Настройка тизеров
                  </Link>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      }
    </div>
  )
}

export default NavbarMenu
