import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Icon from 'react-feather'
import Gravatar from 'react-gravatar'
import { authService } from '../../../../services/auth.service'

const DropDownProfile = () => {
  const [user, setUser] = useState({
    email: '',
    name: '',
    role: ''
  })

  useEffect(() => {
    setTimeout(() => {
      if (authService.isAuthenticated) {
        setUser(authService.userData)
      }
    }, 300)
  }, [])

  return authService.userData ? (
    <div className="dropdown dropdown-profile">
      <a href="/" className="dropdown-link" data-toggle="dropdown" data-display="static">
        <div className="avatar avatar-sm">
          <Gravatar email={user.email} size={64} rating="pg" default="mp" className="rounded-circle" alt={user.name}/>
        </div>
      </a>
      <div className="dropdown-menu dropdown-menu-right tx-13">
        <div className="avatar avatar-lg mg-b-15">
          <Gravatar email={user.email} size={64} rating="pg" default="mp" className="rounded-circle" alt={user.name}/>
        </div>
        <h6 className="tx-semibold mg-b-5">{user.name}</h6>
        <p className="mg-b-25 tx-12 tx-color-03">{user.role}</p>
        <Link to="/profile/edit" className="dropdown-item"><Icon.Edit3/> Edit Profile</Link>
        <Link to="/profile" className="dropdown-item"><Icon.User/> View Profile</Link>
        <div className="dropdown-divider"/>
        <Link to="/logout" className="dropdown-item"><Icon.LogOut/> Sign Out</Link>
      </div>
    </div>
  ) : ''
}

export default DropDownProfile
