import React from 'react'
import PropTypes from 'prop-types'

const FormFieldError = (props) => {
  return (
    <ul className="parsley-errors-list filled">
      <li className="parsley-type">{props.children}</li>
    </ul>
  )
}

FormFieldError.propTypes = {
  children: PropTypes.string.isRequired
}

export default FormFieldError
