import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, Link } from 'react-router-dom'
import { Layers as IconLayers } from 'react-feather'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'

import { getSolution, getSolutionOAuthToken, getSolutionOAuth, saveSolutionOAuthToken } from '../ClothifyApi'
import { getOauthToken } from '../SolutionApi'

function Solution () {
  const getParams = new URLSearchParams(location.search)
  const { solutionId } = useParams()
  const [solutionName, setSolutionName] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [message, setMessage] = useState('Проверка авторизации...')

  getSolution(solutionId)
    .then(res => {
      setSolutionName(res.data.name)
    })
    .catch(e => {
      // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
      console.log('Error', e)
    })

  getSolutionOAuthToken(solutionId)
    .then(res => {
      setLoaded(true)
    })
    .catch(e => {
      // Токена авторизации нет в БД
      if (typeof e.response !== 'undefined' && e.response.status === 404) {
        console.log('Токена авторизации нет в БД')
        const code = getParams.get('code')
        if (code === null) {
          console.log('Получаем ссылку на oauth авторизацию и редиректим пользователя туда')
          // Получаем ссылку на oauth авторизацию и редиректим пользователя туда
          getSolutionOAuth(solutionId)
            .then(res => {
              window.location.href = `${res.data.oauthUrl}/oauth/authorize?response_type=code&client_id=${res.data.clientId}&redirect_uri=${res.data.redirectUri}`
            })
            .catch(e => {
              setMessage('Не удалось получить данные для авторизации в солюшене')
              console.log('Error', e.response)
            })
        } else {
          // Используем код для получения токена авторизации
          getSolutionOAuth(solutionId)
            .then(res => {
              getOauthToken(res.data.oauthUrl, res.data.clientId, res.data.clientSecret, res.data.redirectUri, code)
                .then(tokenRes => {
                  if (tokenRes.status === 200) {
                    const tokenData = {
                      accessToken: tokenRes.data.access_token,
                      tokenType: tokenRes.data.token_type,
                      expire: Math.floor(Date.now() / 1000) + tokenRes.data.expires_in,
                      refreshToken: tokenRes.data.refresh_token
                    }
                    saveSolutionOAuthToken(solutionId, tokenData)
                    setLoaded(true)
                  } else {
                    console.log('Ошибка авторизации OAuth. status = ' + tokenRes.status)
                  }
                })
            })
            .catch(e => {
              setMessage('Отсутствуют данные для авторизации в солюшене')
              // TODO: Обрабатываем ошибку. Не удалось получить данные для OAuth авторизации солюшена.
              console.log('Error: ', e)
            })
        }
      } else {
        setMessage('Не удалось получить данные для авторизации в солюшене')
        console.log('Error: ', e.response)
      }
    })

  if (!loaded) {
    return (<div>{message}</div>)
  }
  return (
    <>
      <Helmet>
        <title>Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }]}>
        <>
          <h1 className="df-title">{solutionName}</h1>
          <p className="df-lead">Настройки солюшена.</p>
          <div className="row tx-14">
            <div className="col-sm-6">
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Главное меню {solutionName}</h5>
                <p className="mg-b-20">Настройка главного меню.</p>
                <Link to={`/solution/${solutionId}/menu-main`} className="tx-medium">
                  Перейти к настройке главного меню
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Образы {solutionName}</h5>
                <p className="mg-b-20">Управление образами.</p>
                <Link to={`/solution/${solutionId}/looks`} className="tx-medium">
                  Перейти к управлению образами
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Подборки {solutionName}</h5>
                <p className="mg-b-20">Управление подборками.</p>
                <Link to={`/solution/${solutionId}/selections`} className="tx-medium">
                  Перейти к управлению подборками
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Статьи {solutionName}</h5>
                <p className="mg-b-20">Управление статьями.</p>
                <Link to={`/solution/${solutionId}/articles`} className="tx-medium">
                  Перейти к управлению статьями
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Главная страница {solutionName}</h5>
                <p className="mg-b-20">Настройка блоков главной страницы.</p>
                <Link to={`/solution/${solutionId}/teaser-settings`} className="tx-medium">
                  Перейти к настройке главной страницы
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Настройки тизеров {solutionName}</h5>
                <p className="mg-b-20">Настройки тизеров.</p>
                <Link to={`/solution/${solutionId}/front-page`} className="tx-medium">
                  Перейти к настройке тизеров
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
              <div className="bg-white bd pd-20 pd-lg-30 ht-sm-300 d-flex flex-column justify-content-end">
                <div className="mg-b-25">
                  <IconLayers width="24" height="24" className="feather feather-grid wd-50 ht-50 tx-gray-500"/>
                </div>
                <h5 className="tx-inverse mg-b-20">Блоки {solutionName}</h5>
                <p className="mg-b-20">Настройка блоков.</p>
                <Link to={`/solution/${solutionId}/blocks`} className="tx-medium">
                  Перейти к настройке блоков
                  <i className="icon ion-md-arrow-forward mg-l-5"/>
                </Link>
              </div>
            </div>
          </div>
        </>
      </SimpleLayout>
    </>
  )
}

export default Solution
