import { ErrorMessage, Field, Form, Formik } from 'formik'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import { menuGenderOptions } from '../ClothifyApi'
import React, { useRef } from 'react'
import * as yup from 'yup'
import ReactSelect from '../ReactSelect'
import 'react-image-crop/dist/ReactCrop.css'

export function NewSelections (params) {
  const block = params.block
  const setBlockName = params.setBlockName
  const handleFormSubmit = params.handleFormSubmit

  if (typeof block.params.title === 'undefined') {
    block.params.title = 'Интересное сегодня'
  }
  if (typeof block.params.type === 'undefined') {
    block.params.type = 'carousel--selections block-new-selections carousel--border-top'
  }

  const formRef = useRef()

  const validateFormSchema = yup.object().shape({
    name: yup.string().required('Это поле обязательно для заполнения.')
  })

  const onBlockNameChange = (e) => {
    setBlockName(formRef.current.values.name)
  }

  return (<>
    <Formik
      enableReinitialize={true}
      initialValues={block}
      validationSchema={validateFormSchema}
      onSubmit={handleFormSubmit}
      innerRef={formRef}
    >
      {({ isSubmitting, values }) => (<Form>
        <div className="row row-xs">
          <div className="col-lg-12">
            <div className="row row-xs">
              <div className="col-12">
                <fieldset className="form-fieldset">
                  <legend>Редактирование блока</legend>
                  <Field name="type" type="hidden"/>
                  <div className="custom-control custom-switch mg-b-20">
                    <Field
                      name="status" type="checkbox"
                      className="custom-control-input" id="status"/>
                    <label
                      className="d-block custom-control-label"
                      htmlFor="status">
                      Блок опубликован
                    </label>
                    <ErrorMessage name="status" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="gender">
                      Пол
                    </label>
                    <ReactSelect
                      name="gender" placeholder="Пол"
                      value={menuGenderOptions.filter(option => option.value === values.gender)[0]}
                      options={menuGenderOptions} isClearable={false} isDisabled={false}/>
                    <ErrorMessage name="gender" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="name">Название блока</label>
                    <Field
                      name="name" type="text" className="form-control"
                      placeholder="Название блока" onKeyUp={onBlockNameChange}/>
                    <ErrorMessage name="name" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="params.title">Заголовок</label>
                    <Field name="params.title" type="text" className="form-control" placeholder="Заголовок"/>
                    <ErrorMessage name="params.title" component={FormFieldError}/>
                  </div>
                  <div className="form-group">
                    <label className="d-block" htmlFor="params.type">Тип</label>
                    <Field name="params.type" type="text" className="form-control" placeholder="Тип"/>
                    <ErrorMessage name="params.type" component={FormFieldError}/>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>Сохранить
          </button>
        </div>
      </Form>)}
    </Formik>
  </>)
}
