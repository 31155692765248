import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Icon from 'react-feather'
import { authService } from '../../../../services/auth.service'
import $ from 'jquery'
import DropDownProfile from './DropDownProfile'

const NavbarRight = (props) => {
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="dropdown"]').dropdown()
  })

  // handleNavbarSearchClick = (event) => {
  //   event.preventDefault()
  //   document.querySelector('.navbar-search').classList.add('visible')
  //   document.querySelector('.backdrop').classList.add('show')
  // }

  return (
    <div className="navbar-right">
      {/* <Link id="navbarSearch" to="#" className="search-link" onClick={this.handleNavbarSearchClick}><Icon.Search/></Link> */}
      {!authService.isAuthenticated && <Link to="/login" className="btn btn-buy"><Icon.LogIn/> &nbsp; Вход</Link>}
      {authService.isAuthenticated &&
      <div className="dropdown dropdown-message">
        <a href="/" className="dropdown-link new-indicator" data-toggle="dropdown">
          <Icon.MessageSquare/>
          <span>5</span>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="dropdown-header">New Messages</div>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/350" className="rounded-circle" alt=""/>
              </div>
              <div className="media-body mg-l-15">
                <strong>Socrates Itumay</strong>
                <p>nam libero tempore cum so...</p>
                <span>Mar 15 12:32pm</span>
              </div>
            </div>
          </a>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/500" className="rounded-circle" alt=""/>
              </div>
              <div className="media-body mg-l-15">
                <strong>Joyce Chua</strong>
                <p>on the other hand we denounce...</p>
                <span>Mar 13 04:16am</span>
              </div>
            </div>
          </a>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/600" className="rounded-circle" alt=""/>
              </div>
              <div className="media-body mg-l-15">
                <strong>Althea Cabardo</strong>
                <p>is there anyone who loves...</p>
                <span>Mar 13 02:56am</span>
              </div>
            </div>
          </a>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/500" className="rounded-circle" alt=""/></div>
              <div className="media-body mg-l-15">
                <strong>Adrian Monino</strong>
                <p>duis aute irure dolor in repre...</p>
                <span>Mar 12 10:40pm</span>
              </div>
            </div>
          </a>
          <div className="dropdown-footer"><a href="/">View all Messages</a></div>
        </div>
      </div>
      }
      {authService.isAuthenticated &&
      <div className="dropdown dropdown-notification">
        <a href="/" className="dropdown-link new-indicator" data-toggle="dropdown">
          <Icon.Bell/><span>2</span>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="dropdown-header">Notifications</div>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/350" className="rounded-circle" alt=""/>
              </div>
              <div className="media-body mg-l-15">
                <p>Congratulate <strong>Socrates Itumay</strong> for work anniversaries</p>
                <span>Mar 15 12:32pm</span>
              </div>
            </div>
          </a>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/500" className="rounded-circle" alt=""/>
              </div>
              <div className="media-body mg-l-15">
                <p><strong>Joyce Chua</strong> just created a new blog post</p>
                <span>Mar 13 04:16am</span>
              </div>
            </div>
          </a>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/600" className="rounded-circle" alt=""/>
              </div>
              <div className="media-body mg-l-15">
                <p><strong>Althea Cabardo</strong> just created a new blog post</p>
                <span>Mar 13 02:56am</span>
              </div>
            </div>
          </a>
          <a href="/" className="dropdown-item">
            <div className="media">
              <div className="avatar avatar-sm avatar-online">
                <img src="https://via.placeholder.com/500" className="rounded-circle" alt=""/>
              </div>
              <div className="media-body mg-l-15">
                <p><strong>Adrian Monino</strong> added new comment on your photo</p>
                <span>Mar 12 10:40pm</span>
              </div>
            </div>
          </a>
          <div className="dropdown-footer"><a href="/">View all Notifications</a></div>
        </div>
      </div>
      }
      {authService.isAuthenticated && <DropDownProfile/>}
    </div>
  )
}

export default NavbarRight
