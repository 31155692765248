import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { token } from '../../services/auth.service'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet-async'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function ImportFeeds () {
  const navigate = useNavigate()
  const [feeds, setFeeds] = useState([])

  useEffect(() => {
    if (!token) {
      return
    }
    axios.create({
      headers: { Authorization: 'Bearer ' + token }
    }).get('/feeds')
      .then(response => {
        setFeeds(response.data)
      })
      .catch(error => {
        console.log(error.response)
      })
  }, [])

  function handleEditClick (event) {
    // navigate('/import/feeds/' + event.target.dataset.id)
    window.open('/import/feeds/' + event.target.dataset.id, '_blank')
  }

  function handleCategoryRelationsClick (event) {
    navigate('/import/feeds/' + event.target.dataset.id + '/category-relations')
  }

  function handleCreateFeed (data) {
    navigate('/import/feeds/create')
  }

  return (
    <>
      <Helmet>
        <title>Управление источниками данных | Clothify Manager</title>
      </Helmet>
      <SimpleLayout
        contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
        breadcrumbs={[{ '/': 'Главная' }, { '/import': 'Импорт' }, { '/import/feeds': 'Источники данных' }]}
      >
        <>
          <h1 className="df-title">Управление источниками данных (магазинами)</h1>
          <button type="button" className="btn btn-primary" onClick={handleCreateFeed}>
            Добавить магазин
          </button>
          <DataTable
            keyField="_id"
            defaultSortField="name"
            defaultSortAsc={true}
            responsive={false}
            highlightOnHover={true}
            pointerOnHover={true}
            columns={[
              {
                name: 'Наименование',
                selector: (row, i) => row.name,
                sortable: true
              },
              {
                name: 'Псевдоним',
                selector: (row, i) => row.alias,
                sortable: true
              },
              {
                name: 'Вкл./Выкл.',
                selector: (row, i) => row.enabled,
                sortable: true,
                cell: function getRow (row) {
                  return <div data-tag="allowRowEvents"><span
                    className={`badge badge-pill ${row.enabled ? 'badge-success' : 'badge-warning'}`}>{row.enabled ? 'Включен' : 'Отключен'}</span>
                  </div>
                },
                right: true
              },
              {
                name: 'Импорт',
                selector: (row, i) => row.meta.validation_error,
                sortable: true,
                cell: function getRow (row) {
                  return <div data-tag="allowRowEvents"><span
                    className={`badge badge-pill ${row.meta.validation_error ? 'badge-danger' : 'badge-success'}`}>{row.meta.validation_error ? 'Ошибка валидации' : 'Ok'}</span>
                  </div>
                },
                right: true
              },
              {
                name: '',
                sortable: false,
                cell: function getRow (row) {
                  return (
                    <>
                      <button
                        type="button" className="btn btn-link" data-id={row._id} onClick={handleEditClick}>
                        Редактировать
                      </button>
                      <button
                        type="button" className="btn btn-link" data-id={row._id} onClick={handleCategoryRelationsClick}>
                        Категории
                      </button>
                    </>
                  )
                },
                right: true
              }
            ]}
            data={feeds}
          />
        </>
      </SimpleLayout>
    </>
  )
}
