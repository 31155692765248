import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form } from 'formik'
import '../../scss/pages/default.scss'
import config from '../../config'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import { DndBootstrapTable } from '../DndBootstrapTable'
import {
  getSolution,
  getSolutionOAuthToken,
  getSolutionVariable,
  menuGenderOptions,
  saveSolutionVariable,
  getBlocks, translateGender
} from '../ClothifyApi'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { useCookies } from 'react-cookie'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common['X-Api-Key'] = config.apiKey
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default function ImageStyleEdit () {
  const navigate = useNavigate()

  const emptyBlockOption = {
    value: '',
    label: 'Выберите блок'
  }
  let blockItems = null
  const blockLabels = {}
  const { solutionId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [solutionName, setSolutionName] = useState('')
  const [blocks, setBlocks] = useState({ men: [], women: [] })
  const [selectedBlock, setSelectedBlock] = useState({ value: '', label: '' })
  const [updateBlocksTable, setUpdateBlocksTable] = useState(0)
  const [gender, setGender] = useState('women')
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['message'])
  let initMessage
  try {
    if (typeof cookies.message === 'undefined') {
      initMessage = { text: '', type: 'success' }
    } else {
      initMessage = cookies.message
      removeCookie('message')
    }
  } catch (err) {
    initMessage = { text: '', type: 'success' }
  }
  const [message, setMessage] = useState(initMessage)

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    getSolutionVariable(solutionId, 'front_page')
      .then(variable => {
        const frontPageBlocks = JSON.parse(variable.value)
        getBlocks(solutionId)
          .then(data => {
            blockItems = data
            blockItems.forEach(item => {
              blockLabels[item._id] = `${item.name} (${translateGender[item.gender]})`
            })
            const result = {}
            Object.keys(frontPageBlocks).forEach(gender => {
              result[gender] = []
              frontPageBlocks[gender].forEach(blockId => {
                result[gender].push({ _id: blockId, name: blockLabels[blockId] })
              })
            })
            setBlocks(result)
            forceUpdateBlocksTable()
          })
      })
      .catch(e => {
        console.log('getSettings error:', e)
        setBlocks({ men: [], women: [] })
        forceUpdateBlocksTable()
      })
  }, [])

  const filterBlockOptions = (text) => {
    return new Promise(resolve => {
      if (blockItems === null) {
        getBlocks(solutionId)
          .then(data => {
            blockItems = data
            blockItems.forEach(item => {
              blockLabels[item._id] = `${item.name} (${translateGender[item.gender]})`
            })
            resolve(filterOptions(blockItems, blockLabels, text))
          })
      } else {
        resolve(filterOptions(blockItems, blockLabels, text))
      }
    })
  }

  const filterOptions = (items, labels, text) => {
    const options = []
    items.forEach(item => {
      if (text.trim() === '' || item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
        options.push({ value: item._id, label: labels[item._id] })
      }
    })
    return options
  }

  const onSelectBlockChange = (option) => {
    setSelectedBlock({ _id: option.value, name: option.label })
  }

  const handleAddBlock = () => {
    const updateBlocks = JSON.parse(JSON.stringify(blocks))
    updateBlocks[gender].push(selectedBlock)
    setBlocks(updateBlocks)
    forceUpdateBlocksTable()
  }

  const onDeleteBlock = (items) => {
    const updateBlocks = JSON.parse(JSON.stringify(blocks))
    updateBlocks[gender] = items
    setBlocks(updateBlocks)
    forceUpdateBlocksTable()
  }

  const onMoveBlock = (items) => {
    const updateBlocks = JSON.parse(JSON.stringify(blocks))
    updateBlocks[gender] = items
    setBlocks(updateBlocks)
  }

  const onEditBlock = (item) => {
    navigate(`/solution/${solutionId}/blocks/${item._id}?destination=/solution/${solutionId}/front-page`)
  }

  const forceUpdateBlocksTable = () => {
    const newUpdateTable = updateBlocksTable + 1
    setUpdateBlocksTable(newUpdateTable)
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    setMessage({ text: '', type: 'success' })
    setSubmitting(true)
    setTimeout(() => {
      const result = {}
      Object.keys(blocks).forEach(gender => {
        result[gender] = []
        blocks[gender].forEach(item => {
          result[gender].push(item._id)
        })
      })
      saveSolutionVariable(solutionId, { name: 'front_page', value: JSON.stringify(result) })
        .then(result => {
          setMessage({ text: 'Настройки успешно сохранены', type: 'success' })
          setSubmitting(false)
        })
        .catch(() => {
          setMessage({ text: 'Ошибка при сохранении настроек', type: 'error' })
        })
    }, 500)
  }

  const dndColumns = React.useMemo(
    () => [
      {
        Header: 'Блок',
        accessor: 'name'
      }
    ],
    []
  )

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }

  return (<>
    <Helmet>
      <title>{'Настройка главной страницы "' + solutionName + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/front-page`]: 'Главная страница' }]}>
      <>
        <h1 className="df-title">Настройка главной страницы &quot;{solutionName}&quot;</h1>
        {message.text.length > 0 && <div
          className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
          <AlertCircle className="mg-r-10 "/> {message.text}</div>}
        <Formik
          enableReinitialize={true}
          initialValues={blocks[gender]}
          onSubmit={handleFormSubmit}>
          {({ isSubmitting }) => (<Form>
            <div className="row row-xs">
              <div className="col-12">
                <Select
                  className="basic-single w-25 px-2 mb-3"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Выберите пол"
                  value={menuGenderOptions.filter(option => option.value === gender)[0]}
                  options={menuGenderOptions} isClearable={false} isDisabled={false}
                  onChange={option => {
                    setGender(option.value)
                    forceUpdateBlocksTable()
                  }}/>
              </div>
            </div>
            <div className="row row-xs">
              <div className="col-6">
                <div className="form-group">
                  <AsyncSelect
                    defaultOptions={[emptyBlockOption]}
                    loadOptions={filterBlockOptions}
                    onChange={value => onSelectBlockChange(value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <button type="button" className="btn btn-primary" onClick={handleAddBlock}>
                  Добавить блок
                </button>
              </div>
            </div>
            <div className="row row-xs">
              <div className="col-12">
                <DndBootstrapTable
                  key={updateBlocksTable} columns={dndColumns} data={blocks[gender]}
                  onDeleteItem={onDeleteBlock} onEditItem={onEditBlock} onMoveItem={onMoveBlock}/>
              </div>
            </div>
            <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
              Сохранить
            </button>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
