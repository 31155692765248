import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function Breadcrumbs (props) {
  const bmLen = props.path.length

  return (
    <ol className="breadcrumb breadcrumb-style2 mg-b-10">
      {props.path.map((item, index) => {
        if (bmLen > 1 && bmLen === index + 1) {
          return (
            <li key={index} className="breadcrumb-item active" aria-current="page">{item[Object.keys(item)[0]]}</li>
          )
        } else {
          return (
            <li key={index} className="breadcrumb-item"><Link
              to={Object.keys(item)[0]}>{item[Object.keys(item)[0]]}</Link></li>
          )
        }
      })}
    </ol>
  )
}

Breadcrumbs.propTypes = {
  path: PropTypes.array
}

Breadcrumbs.defaultProps = {
  path: [{ '/': 'Главная' }]
}

export default Breadcrumbs
