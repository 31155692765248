import React, { useEffect, useState, useRef } from 'react'
import * as yup from 'yup'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleLayout from '../../layouts/simple'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import FormFieldError from '../../layouts/simple/components/forms/FormFieldError'
import '../../scss/pages/default.scss'
import { Helmet } from 'react-helmet-async'
import { AlertCircle } from 'react-feather'
import { useCookies } from 'react-cookie'
import {
  menuGenderOptions,
  getArticle,
  postArticle,
  getSolution,
  getSolutionOAuthToken,
  styleImage
} from '../ClothifyApi'
import ReactSelect from '../ReactSelect'
import ReactCrop, {
  centerCrop,
  makeAspectCrop
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default function SolutionArticlesEdit () {
  const imgHost = 'https://cdn1.justbutik.ru/'
  const emptyArticle = {
    _id: '',
    status: true,
    gender: [],
    teaserImage: '',
    title: ''
  }
  const teaserSize = { width: 433, height: 281 }
  const aspect = teaserSize.width / teaserSize.height
  const scale = 1
  const rotate = 0
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(undefined)
  const [imgSrc, setImgSrc] = useState('')
  const [file, setFile] = useState(undefined)

  const navigate = useNavigate()
  const formRef = useRef()
  const { solutionId, articleId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [solutionName, setSolutionName] = useState('')
  const [article, setArticle] = useState(emptyArticle)
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['message'])
  let initMessage
  const defaultArticleData = {
    title: article.title || emptyArticle.title,
    gender: article.gender || emptyArticle.gender,
    teaserImage: article.teaserImage || emptyArticle.teaserImage,
    status: article.status || emptyArticle.status
  }
  try {
    initMessage = JSON.parse(cookies.message)
    removeCookie('message')
  } catch (err) {
    initMessage = { text: '', type: 'success' }
  }
  const [message, setMessage] = useState(initMessage)

  useEffect(() => {
    getSolution(solutionId)
      .then(res => {
        setSolutionName(res.data.name)
      })
      .catch(e => {
        // TODO: Обрабатываем ошибку. Не удалось получить данные солюшена.
        console.log('Error', e)
      })

    getSolutionOAuthToken(solutionId)
      .then(res => {
        setLoaded(true)
      })
      .catch(e => {
        // Токена авторизации нет в БД
        navigate(`/solution/${solutionId}`)
      })
  }, [solutionId])

  useEffect(() => {
    getArticle(solutionId, articleId)
      .then(data => {
        if (typeof data.title !== 'undefined') {
          data.title = Buffer.from(data.title, 'base64').toString('utf8')
        }
        if (typeof data.gender === 'undefined') {
          data.gender = defaultArticleData.gender
        }
        if (typeof data.teaserImage === 'undefined') {
          data.teaserImage = defaultArticleData.teaserImage
        }
        if (typeof data.status === 'undefined') {
          data.status = defaultArticleData.status
        }
        setArticle(data)
      })
  }, [articleId])

  useEffect(() => {
    setUploadDisabled(crop === undefined)
  }, [crop])

  const validateFormSchema = yup.object().shape({
    title: yup.string().required('Это поле обязательно к заполнению.')
  })

  const handleFormSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus()
    setSubmitting(true)
    setTimeout(() => {
      const articleData = JSON.parse(JSON.stringify(values))
      delete articleData.title
      if (articleId === undefined) {
        console.log('Article Id is undefined')
      } else {
        postArticle(solutionId, articleId, articleData)
          .then((res) => {
            setMessage({ type: 'success', text: 'Статья успешно сохранена' })
            window.scrollTo(0, 0)
            setSubmitting(false)
          })
          .catch(err => {
            console.log(err)
            setMessage({ type: 'alert', text: 'При сохранении статьи произошла ошибка' })
            window.scrollTo(0, 0)
            setSubmitting(false)
          })
      }
    }, 500)
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setFile(e.target.files[0])
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadImages = () => {
    setUploadDisabled(true)
    const parts = imgSrc.split(';base64,')
    const contentType = parts.shift().split('data:').pop().toLowerCase()
    let ext = '.jpg'
    switch (contentType) {
      case 'image/jpeg':
      case 'image/jpg':
        ext = '.jpg'
        break
      case 'image/png':
        ext = '.png'
        break
      case 'image/gif':
        ext = '.gif'
        break
      case 'image/webp':
        ext = '.webp'
        break
    }

    const baseFileName = Date.now().toString()
    const fileName = baseFileName + ext
    const sourceData = {
      path: 'articles/source',
      baseFileName: fileName,
      file: file
    }
    const teaserData = {
      path: 'articles/teaser',
      baseFileName: baseFileName,
      actions: [
        {
          type: 'crop',
          params: crop
        },
        {
          type: 'scale',
          params: teaserSize
        }
      ],
      file: file
    }
    Promise.all([
      styleImage(sourceData),
      styleImage(teaserData)
    ])
      .then(results => {
        const articleData = JSON.parse(JSON.stringify(formRef.current.values))
        articleData.teaserImage = results[1].data[0]
        setArticle(articleData)
        setMessage({ type: 'success', text: 'Изображения успешно загружены' })
        window.scrollTo(0, 0)
      })
      .catch(err => {
        console.log(err)
        setMessage({ type: 'alert', text: 'При стилизации изображений произошла ошибка' })
        window.scrollTo(0, 0)
      })
  }

  if (!loaded) {
    return (<div>Проверка авторизации...</div>)
  }
  return (<>
    <Helmet>
      <title>{'Редактирование статьи "' + article.title + '" | Clothify Manager'}</title>
    </Helmet>
    <SimpleLayout
      contentClassName="content content-default" containerClassName="container pd-x-0 tx-13"
      breadcrumbs={[{ '/': 'Главная' }, { [`/solution/${solutionId}`]: solutionName }, { [`/solution/${solutionId}/articles`]: 'Статьи журнала' }, { [`/solution/${solutionId}/articles/${articleId}`]: 'Статья ' + article.title }]}>
      <>
        <h1 className="df-title">{'Редактирование статьи "' + article.title + '"'}</h1>
        <Formik
          enableReinitialize={true}
          initialValues={article}
          validationSchema={validateFormSchema}
          onSubmit={handleFormSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting, values }) => (<Form>
            {message.text.length > 0 && <div
              className={'border border-' + message.type + ' text-' + message.type + ' d-flex align-items-center mt-3 mb-3 p-2'}>
              <AlertCircle className="mg-r-10 "/> {message.text}</div>}
            <div className="row row-xs">
              <div className="col-lg-12">
                <div className="row row-xs">
                  <div className="col-12">
                    <fieldset className="form-fieldset">
                      <legend>Редактирование статьи</legend>
                      <div className="custom-control custom-switch mg-b-20">
                        <Field
                          name="status" type="checkbox"
                          className="custom-control-input" id="status"/>
                        <label
                          className="d-block custom-control-label"
                          htmlFor="status">
                          Статья опубликована
                        </label>
                        <ErrorMessage name="status" component={FormFieldError}/>
                      </div>
                      <div className="form-group">
                        <label className="d-block" htmlFor="gender">
                          Пол
                        </label>
                        <ReactSelect
                          name="gender" placeholder="Пол" isMulti
                          value={menuGenderOptions.filter(option => values.gender.includes(option.value))}
                          options={menuGenderOptions} isClearable={false} isDisabled={false}/>
                        <ErrorMessage name="gender" component={FormFieldError}/>
                      </div>
                      <div className="row">
                        <div className="col-3 p-2">
                          <img
                            alt="Картинка для тизера" src={imgHost + article.teaserImage}
                            style={{ maxHeight: 100 }}/>
                        </div>
                        <div className="form-group col-9">
                          <label className="d-block" htmlFor="teaserImage">
                            Тизер
                          </label>
                          <Field
                            name="teaserImage" type="text" className="form-control"
                            placeholder="Обрезанная картинка" disabled={true}/>
                          <ErrorMessage name="teaserImage" component={FormFieldError}/>
                        </div>
                      </div>
                      <div className="form-group row pt-3 pb-3">
                        <div className="col-6">
                          {!!imgSrc && (
                            <ReactCrop
                              crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)}
                              aspect={aspect}>
                              <img
                                ref={imgRef} alt="Original image" src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad}/>
                            </ReactCrop>
                          )}
                        </div>
                        <div className="col-6">
                          <input type="file" accept="image/*" onChange={onSelectFile}/>
                          <button
                            type="button" className="btn btn-primary" onClick={uploadImages}
                            disabled={uploadDisabled}>Загрузить
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-brand-02 btn-block mg-t-20" disabled={isSubmitting}>
                Сохранить
              </button>
            </div>
          </Form>)}
        </Formik>
      </>
    </SimpleLayout>
  </>)
}
